import { sharedProperty } from '../../../../../../app';
import HttpClient from '../../../../../../app/HttpClient';
import { HeadingBar } from '../../../../../../components';
import { BlogArticleModel } from '../../../../../../data/entities';
import { combinePaths } from '../../../../../../app/tools';


interface UpdateArticleMediaProps {
  dataItemState: sharedProperty<BlogArticleModel>;
}

interface ImageProps {
  src: string;
  alt: string;
  visible?: boolean;
}

const Image = ({ src, alt, visible }: ImageProps) => {

  const cardStyle = {
    backgroundColor: '#F7F7F7',
    borderRadius: '4px',
    maxWidth: '100%',
    maxHeight: '300px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
  };

  const imageStyle = {
    maxWidth: '100%',
    height: '300px',
    width: 'auto',
  };

  return (
    
    <div className="card">
      <div style={cardStyle}>
        {visible && (<img src={src} style={imageStyle} alt={alt}/>)}
      </div>
    </div>
  )
}


export function ArticleMedia({ dataItemState }: Readonly<UpdateArticleMediaProps>) {

const httpClient = new HttpClient();
const siteUrl = httpClient.siteUrl;

const thumbnailImageUrl = dataItemState.value?.thumbnailImageUrl ?? '';
const headerImageUrl = dataItemState.value?.headerImageUrl ?? '';
const openGraphImageUrl = dataItemState.value?.openGraphImageUrl ?? '';

const thumbnailImageUrlPrefix = thumbnailImageUrl.startsWith("http") ? "" : siteUrl;
const headerImageUrlPrefix = headerImageUrl.startsWith("http") ? "" : siteUrl;
const openGraphImageUrlPrefix = openGraphImageUrl.startsWith("http") ? "" : siteUrl;
  
  return (
    <div className="row">
      <div className="col-12 col-lg-4 mb-5 flex-column d-flex align-items-center">
        <HeadingBar title="Thumbnail Image" className="py-3"/>
        {!dataItemState.value?.thumbnailImageUrl &&  <p className="fst-italic h-100">No thumbnail image uploaded</p>}
        <Image src={`${combinePaths(thumbnailImageUrlPrefix, dataItemState.value?.thumbnailImageUrl)}`} alt={dataItemState.value?.title} visible={!!dataItemState.value.thumbnailImageUrl}/>
        
      </div>
      
      <div className="col-12 col-lg-4 mb-5 flex-column d-flex align-items-center">
        <HeadingBar title="Header Image" className="py-3"/>
        {!dataItemState.value?.headerImageUrl && <p className="fst-italic h-100">No header image uploaded</p>}
        <Image src={`${combinePaths(headerImageUrlPrefix, dataItemState.value?.headerImageUrl)}`} alt={dataItemState.value?.title} visible={!!dataItemState.value.headerImageUrl} />
        
      </div>
      <div className="col-12 col-lg-4 mb-5 flex-column d-flex align-items-center">
        <HeadingBar title="Open Graph Image" className="py-3"/>
        {!dataItemState.value?.openGraphImageUrl && <p className="fst-italic h-100">No open graph image uploaded</p>}
        <Image src={`${combinePaths(openGraphImageUrlPrefix, dataItemState.value?.openGraphImageUrl)}`} alt={dataItemState.value?.title} visible={!!dataItemState.value.openGraphImageUrl}/>
      </div>
    </div>
  )
}

export default ArticleMedia
