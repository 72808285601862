import { routes } from "../../app/routes";
import { PageBreadcrumbProps } from "../PageContent";

export function getFilesBreadcrumbs(location: string): PageBreadcrumbProps {

  switch (location) {
    case routes.files:
      return {
        showHome: true,
        values: [
          { label: "Files"},
        ],
      };
    default:
      return { showHome: false, values: [] };
  }
}

export default getFilesBreadcrumbs;

