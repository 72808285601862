import * as React from 'react';
import {
  Toolbar,
  Button,
  ButtonGroup,
} from '@progress/kendo-react-buttons';
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Upload } from '@progress/kendo-react-upload';
import { BaseEvent  } from '@progress/kendo-react-common';
import { toggleViewBtnGroup } from '../helpers/helperMethods';
import { GridViewBtnGroup, UploadAddEvent } from '../interfaces';
import { Label } from "@progress/kendo-react-labels";
import { gridIcon, gridLayoutIcon} from "@progress/kendo-svg-icons";
import { UserService } from '../../../../../services';

export const FileManagerToolbar = (props: any) => {
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const [viewBtnGroup, setViewBtnGroup] = React.useState<GridViewBtnGroup>({ gridView: false, listView: true });

  const handleSwitchChange = (event: SwitchChangeEvent) => {
    props.onSwitchChange(event);
  };

  const handleGridViewChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      const newBtnGroupState = toggleViewBtnGroup(viewBtnGroup, 'grid');
      setViewBtnGroup(newBtnGroupState);

      props.onViewChange({
        viewValue: newBtnGroupState,
        event: event
      });
    }
  };

  const handleListViewChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      const newBtnGroupState = toggleViewBtnGroup(viewBtnGroup, 'list');
      setViewBtnGroup(newBtnGroupState);

      props.onViewChange({
        viewValue: newBtnGroupState,
        event: event
      });
    }
  };

  const handleNewFolderClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onNewFolderClick(event);
  }

  const handleDialogVisibility = (event: BaseEvent<Dialog> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDialogVisible(!dialogVisible);
  };

  const handleFileChange = (event: UploadAddEvent) => {
    props.onFileChange({
      files: event.newState,
      event: event
    });
  };

  const handleUploadClearList = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onClearFileList(event);
  };

  const handleUploadDone = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDialogVisible(!dialogVisible);
    props.onUploadComplete(event);
  };

  return (
    <Toolbar className="k-filemanager-toolbar">
      <Button onClick={handleNewFolderClick}>New Folder</Button>
      <Button onClick={handleDialogVisibility}>Upload</Button>
      {dialogVisible &&
        <Dialog
          title={'Upload Files'}
          className={'k-filemanager-upload-dialog'}
          onClose={handleDialogVisibility}
          contentStyle={{ width: '530px' }}
        >
          <Upload
            batch={false}
            multiple={true}
            files={props.files}
            withCredentials={false}
            onAdd={handleFileChange}
            onRemove={handleFileChange}
            onProgress={handleFileChange}
            onStatusChange={handleFileChange}
            saveUrl={`${process.env.REACT_APP_API_URL}file/upload?path=${props.path}`}
            saveHeaders={{
              Authorization: `Bearer ${UserService.getUserToken()}`,
            }}
          />
          <DialogActionsBar layout={'end'}>
            <Button onClick={handleUploadClearList} > Clear List</Button>
            <Button themeColor={'primary'} onClick={handleUploadDone}> Done </Button>
          </DialogActionsBar>
        </Dialog >
      }
      <ButtonGroup>
        <Button
          togglable={true}
          svgIcon={gridLayoutIcon}
          selected={viewBtnGroup.gridView}
          onClick={handleGridViewChange}
        />
        <Button
          togglable={true}
          svgIcon={gridIcon}
          selected={viewBtnGroup.listView}
          onClick={handleListViewChange}
        />
      </ButtonGroup>
      <div className="k-spacer">&nbsp;</div>
      <div className="k-filemanager-details-toggle">
        <Label>View Details </Label>
        <Switch defaultChecked={true} onChange={handleSwitchChange} />
      </div>
    </Toolbar>
  );
}
