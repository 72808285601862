import { useEffect, useState } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { PageBreadcrumbProps, PageContent } from "../../../PageContent";
import { BlogArticleModel } from "../../../../data/entities";
import { BlogService, UrlService } from "../../../../services";
import { BlogArticleSidebar, BlogArticleSubmenu, getBlogArticleBreadcrumbs } from '.';

export function BlogArticlePage() {
  // Get the id from the url
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  // Instantiate page data
  const [pageData, setPageData] = useState<BlogArticleModel | undefined | null>();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();
  const title = pageData?.title ?? "Article";

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    // Instantiate services
    const blogService = new BlogService();
    const fetchData = async () => {
      try {
        const loadedData = await blogService.getBlogArticleByIdAsync(Number(id)!);
        setPageData(loadedData);
      } catch (e) {
        console.error("Error loading article", e);
      }
    };
    fetchData();
  }, [id]);

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getBlogArticleBreadcrumbs(location.pathname, Number(id));
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      title={title}
      breadcrumbs={breadcrumbs}
      submenu={<BlogArticleSubmenu id={id!}/>}
    >
      <div className="row">
        <div className="col-12 col-lg-3">
          <BlogArticleSidebar />
        </div>
        <div className="col-12 col-lg-9 position-relative">
          <Outlet />
        </div>
      </div>
    </PageContent>
  );
}

export default BlogArticlePage;

