import { useRef } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { SlideOutModal, SlideOutModalProps } from "../../../components";
import { ModulePicker, SubdisciplinePicker } from "../../../components/form/dropdown";
import * as yup from "yup";
import { Formik } from "formik";
import { TextArea } from "@progress/kendo-react-inputs";

export interface AddLessonModalProps extends SlideOutModalProps {
  onSubmit: (data: any) => void;
}

export function AddLessonModal(props: Readonly<AddLessonModalProps>) {

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    code: yup.string().required("Code is required"),
    moduleId: yup.number().required("Module is required"),
    subdisciplineId: yup.number().required("Primary Subdiscipline is required"),
    description: yup.string().notRequired()
  })

  return (
    <SlideOutModal {...props}
      title={`Add New Lesson`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Add
            </Button>
          </div>
        </div>
      </div>}>

      <Formik 
        validationSchema={schema}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
        initialValues={{
          name: "",
          code: "",
          moduleId: "",
          subdisciplineId: "",
          description: ""
        }}>
          {({ handleSubmit, handleChange, values, errors, setFieldValue, touched }) => (
            <Form ref={formRef} noValidate onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="name">
                    <FloatingLabel label="Name" className="mb-3">
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="code">
                    <FloatingLabel label="Code" className="mb-3">
                      <Form.Control
                        type="text"
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                        isInvalid={touched.code && !!errors.code}
                      />
                      <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="moduleId">
                    <ModulePicker
                      id="moduleId"
                      label="Module"
                      onSelect={(moduleId) => {
                        setFieldValue('moduleId', moduleId);
                      }}
                      initialValue={values.moduleId.toString()}
                      isInvalid={touched.moduleId && !!errors.moduleId}
                      feedback={errors.moduleId}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="subdisciplineId">
                    <SubdisciplinePicker
                      id="subdisciplineId"
                      label="Primary Subdiscipline"
                      onSelect={(subdisciplineId) => {
                        setFieldValue('subdisciplineId', subdisciplineId);
                      }}
                      initialValue={values.subdisciplineId.toString()}
                      isInvalid={touched.moduleId && !!errors.moduleId}
                      feedback={errors.moduleId}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="description">
                    <FloatingLabel label="Description" className="mb-3">
                      <TextArea
                        id="description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Button ref={formSubmitRef} type="submit" className="d-none" />
            </Form>
          )}
      </Formik>

      
      {props.children}
    </SlideOutModal>
  );
}

export default AddLessonModal;

