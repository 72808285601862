import React, { forwardRef, useImperativeHandle, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";
import { FormControlProps } from "react-bootstrap";
import { propsFilter } from "../../../app/tools";

interface TextWithValidationProps extends FormControlProps {
  id: string;
  label: string;
  required?: boolean;
  initialValue?: any;
  disabled?: boolean;
  integer?: boolean;
  type?: string;
  validationFunction?: (input: string) => boolean;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
  formatFunction?: (input: string) => string;
}

export const TextWithValidation = forwardRef(
  (props: TextWithValidationProps, ref) => {
    const {
      initialValue,
      id,
      label,
      disabled,
      required,
      validationFunction,
      onChangeText,
      integer,
      validationResult,
      type,
      formatFunction,
    } = props;

    //Handle the initial value different scenarios
    const startValue = (): string => {
      if (initialValue === undefined) return "";
      const asString = initialValue?.toString() ?? "";
      if (!formatFunction) return asString;
      return formatFunction(asString);
    };

    const [value, setValue] = useState(startValue());
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const [validationMessage, setValidationMessage] = useState("");

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      let changedValue = event.target.value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    function handleBlur(): boolean {
      let message = `Invalid ${label}`;
      let result = false;
      let valid = required ? value !== "" : true;

      if (integer) {
        const regex = /^\d\d{0,9}$/;
        valid = value != "" || required ? regex.test(value) : true;
        result = valid;
      }

      if (required && value === "")
        message = "Field is required. It cannot be empty";
      else result = valid;

      setValidationMessage(message);

      if (validationFunction) result = validationFunction(value);
      if (validationResult) validationResult(value, result);

      setIsValid(result);

      if (formatFunction) {
        const formattedValue = formatFunction(value);
        setValue(formattedValue);
      }

      return result;
    }

    const filteredProps = propsFilter(props, [
      "id",
      "label",
      "required",
      "initialValue",
      "disabled",
      "type",
      "validationFunction",
      "onChangeText",
      "validationResult",
      "formatFunction",
    ]);

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return handleBlur();
      },
    }));

    return (
      <Form.Group controlId={id}>
        <FloatingLabel label={label} className="mb-3">
          <Form.Control
            type={type}
            placeholder={label}
            required={required}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isInvalid={isValid !== undefined && !isValid}
            isValid={isValid}
            disabled={disabled ?? false}
            {...filteredProps}
          />
          <Form.Control.Feedback type="invalid">
            {validationMessage}.
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    );
  }
);

TextWithValidation.defaultProps = {
  required: false,
  type: "text",
};

export default TextWithValidation;

