import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import TokenData from "../../models/TokenData";
import LoggedUser from "../../models/LoggedUser";

interface AuthState {
  userToken: string | null;
  tokenData: TokenData | null;
  loggedUser: LoggedUser | null;
  isSignedIn: boolean;
}

const initialState: AuthState = {
  userToken: null,
  tokenData: null,
  loggedUser: null,
  isSignedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.userToken = action.payload;
    },
    clearToken: (state) => {
      state.userToken = null;
    },
    setTokenData: (state, action) => {
      state.tokenData = action.payload;
    },
    clearTokenData: (state) => {
      state.tokenData = null;
    },
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
    clearLoggedUser: (state) => {
      state.loggedUser = null;
    },
    setIsLoggedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
  },
});

export const {
  setToken, clearToken,
  setTokenData, clearTokenData,
  setLoggedUser, clearLoggedUser,
  setIsLoggedIn
} = authSlice.actions;
export const getUserToken = () => (state: RootState) => state.auth.userToken;
export default authSlice.reducer;
