import React from 'react';
import { Submenu, SubmenuNavigationItem } from '../../../components';
import { routes } from '../../../app/routes';

interface LessonSubmenuProps {
  id: string
}

export const LessonSubmenu: React.FC<LessonSubmenuProps> = ({ id }) => {
  const items: SubmenuNavigationItem[] = [
    {
      label: "Back",
      url: routes.catalogLessons,
      icon: "back",
    },
    {
      label: "Details",
      url: routes.lessonDetails,
      params: new Map([["id", id]]),
      activeMatch: routes.lessonDetails,
      activeMatchExact: true,
    },
    {
      label: "Activities",
      url: routes.lessonActivities,
      params: new Map([["id", id]]),
      activeMatch: routes.lessonActivities,
      activeMatchExact: true,
    },
    {
      label: "Levels",
      url: routes.lessonLevels,
      params: new Map([["id", id]]),
      activeMatch: routes.lessonLevels,
      activeMatchExact: true,
    },
    {
      label: "Regions",
      url: routes.lessonRegions,
      params: new Map([["id", id]]),
      activeMatch: routes.lessonRegions,
      activeMatchExact: true,
    },
    {
      label: "Media",
      url: routes.lessonMedia,
      params: new Map([["id", id]]),
      activeMatch: routes.lessonMedia,
      activeMatchExact: true,
    },
  ]

  return (
    <Submenu items={items} />
  );
};

export default LessonSubmenu;
