import React from 'react';
import { Submenu, SubmenuNavigationItem } from '../../../components';
import { routes } from '../../../app/routes';

interface ActivitySubmenuProps {
  id: string
}

export const ActivitySubmenu: React.FC<ActivitySubmenuProps> = ({ id }) => {
  const items: SubmenuNavigationItem[] = [
    {
      label: "Back",
      url: routes.catalogActivities,
      icon: "back",
    },
    {
      label: "Details",
      url: routes.activityDetails,
      params: new Map([["id", id]]),
      activeMatch: routes.activityDetails,
      activeMatchExact: true,
    },
    {
      label: "Objectives",
      url: routes.activityAssessments,
      params: new Map([["id", id]]),
      activeMatch: routes.activityAssessments,
      activeMatchExact: true,
    },
  ]

  return (
    <Submenu items={items} />
  );
};

export default ActivitySubmenu;
