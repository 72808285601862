import { Card, CardTitle, CardBody, BlockButton, IconAndText, IconDefinitions } from '../../../../../../components'
import { ManageArticleMediaModal, ArticleMedia } from '.'
import { usePageActions } from '../../../../../PageContent';
import { useSharedState } from '../../../../../../app';
import { BlogArticleModel } from '../../../../../../data/entities';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../../../../../app/tools';
import { UrlService, BlogService } from '../../../../../../services';

export function BlogArticleMediaPage() {
  

  const blogService = new BlogService();
  
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const dataItemState = useSharedState<BlogArticleModel>({} as BlogArticleModel)
  const showEditState = useSharedState(false);

  usePageActions(
    <BlockButton onClick={() => { showEditState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.edit} text="Manage" />
    </BlockButton>
  );

  const editArticle = async (article: BlogArticleModel) => {
    try {
      showEditState.setter(false);
      const response:boolean = await blogService.editBlogArticleAsync(article);
      if (response){
        showSuccessMessage("Article media updated successfully.");
        dataItemState.setter(article);
      }
    } catch (err) {
      throw new Error(`Unable to update article media: ${err}`);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogService = new BlogService();
        const loadedData = await blogService.getBlogArticleByIdAsync(Number(id));
        if (loadedData){
          dataItemState.setter(loadedData);
        }
      }
      catch (error) {
        showErrorMessage("Error loading article media");
        console.error(error);
      }
    }
    fetchData();
  }, [id])

  if (!dataItemState) return (<></>);
  return (
    <>
      <Card>
        <CardTitle>
          <h6 className="text-light m-0">Article Media</h6>
        </CardTitle>
        <CardBody>
          <ArticleMedia 
            dataItemState={dataItemState}
          />
        </CardBody>
      </Card>
      <ManageArticleMediaModal
        showState={showEditState}
        dataItemState={dataItemState}
        onSubmit={editArticle}
      />
    </>
  )
}

export default BlogArticleMediaPage
