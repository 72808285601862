import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SkillModel } from '../../data/entities';
import { SkillService } from '../../services';

interface SkillSelectorProps {
  assignedSkills?: Array<SkillModel>
  clickHandler: (skill: any) => void
}

export function SkillSelector({ assignedSkills, clickHandler }: Readonly<SkillSelectorProps>) {

  const [options, setOptions] = useState<SkillModel[]>([]);

  useEffect(() => {
    fetchSkills();
  }, [assignedSkills]);
  
  const fetchSkills = async () => {
    try {
      const data = await new SkillService().getSkillsAsync();
      let filteredSkills = data.value;
      if (assignedSkills) {
        filteredSkills = filteredSkills.filter(skill =>
          !assignedSkills.some(assignedSkill => assignedSkill.id === skill.id)
        );
      }
      setOptions(filteredSkills);
    } catch (e) {
      console.error("Error loading skills", e);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle>
        Add Skill
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options?.map((skill) => (
          <Dropdown.Item onClick={() => clickHandler(skill)} key={skill.id}>
            {skill.name}
          </Dropdown.Item>
        ))}
        {options?.length === 0 && <Dropdown.Item disabled>No skills available</Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SkillSelector
