import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import Breadcrumbs, { Breadcrumb } from "../components/breadcrumbs/Breadcrumbs";
import Footer from "../components/footer/Footer";
import AlertMessage from "../components/interactions/AlertMessage";

export interface PageContext {
  title?: string;
  setTitle: (title?: string) => void;
}

function PageTitle(props: any) {
  if (props.title && props.title !== "") {
    return <h2>{props.title}</h2>;
  }
  return null;
}

export interface PageBreadcrumbProps {
  showHome?: boolean;
  values: Breadcrumb[];
}

export function PageBreadcrumbs(props?: PageBreadcrumbProps) {
  if (!props?.values?.length) return null;

  let breadcrumbs = [...props.values];
  if (props.showHome || props.showHome === undefined) {
    breadcrumbs.unshift({
      label: "Home",
      url: "/",
    });
  }
  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

interface PageContentProps extends React.PropsWithChildren {
  title?: string;
  breadcrumbs?: PageBreadcrumbProps;
  submenu?: React.ReactElement;
  contentCssClass?: string;
  footerless?: boolean;
}

export const PageActionsContext = createContext({
  actions: <></>,
  setActions: (() => <></>) as React.Dispatch<
    React.SetStateAction<JSX.Element>
  >,
});

export function PageContextActions() {
  const actionsContext = useContext(PageActionsContext);
  return <>{actionsContext.actions}</>;
}

export function usePageActions(component: JSX.Element) {
  let actionsContext = useContext(PageActionsContext);
  return React.useEffect(() => {
    actionsContext.setActions(component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function PageContent(props: PageContentProps) {
  const [actions, setActions] = useState(<></>);
  const location = window.location.pathname;

  // Reset actions when location changes
  React.useEffect(() => {
    return () => setActions(<></>);
  }, [location]);

  // const footer = props.footerless ? null : <Footer></Footer>;
  const footer = <></>;
  return (
    <PageActionsContext.Provider
      value={useMemo(() => ({ actions, setActions }), [actions, setActions])}
    >
      {props.submenu}
      <AlertMessage></AlertMessage>
      <div role="article" className={`container-fluid ${props.contentCssClass ?? ""}`}>
        <div className="row align-items-center pt-3">
          <div className="col">
            <PageTitle title={props.title} />
            {PageBreadcrumbs(props.breadcrumbs)}
          </div>
          <div className="col-auto">
            {actions}
          </div>
        </div>
        <div className="row">
          <div className="col">{props.children}</div>
        </div>
      </div>
      {footer}
    </PageActionsContext.Provider>
  );
}

export default PageContent;



