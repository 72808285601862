import { routes, router } from "../../../../app/routes";
import { useParams } from "react-router-dom";
import SubmenuSideBar from "../../../../components/sideBar/SubmenuSideBar";
import { BlogService, UrlService } from "../../../../services";
import { Button } from "react-bootstrap";
import { useSharedState } from "../../../../app/sharedProperty";
import { SlideOutModal } from "../../../../components/modals/SlideOutModal";
import { showSuccessMessage } from "../../../../app/tools";

export function BlogArticleSidebar() {

  const urlParams = useParams();
  const articleId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  const deleteSelectClick = async () => {
    const ok = await deleteArticle(articleId);
    console.log(ok);
    if(!ok){return}
    else{
      showSuccessMessage(`Article deleted`);
    };
    router.navigate(routes.blogArticles).catch((error) => {});
  };

  async function deleteArticle(id: number) {
    await new BlogService().deleteBlogArticleByIdAsync(id);
    return true;
  }


  const menuItems = [
    {
      text: 'Edit',
      url: router.getUrl(routes.blogArticleEdit, new Map([
        ['Id', articleId.toString()]
      ])),
      activeMatchUrl: routes.blogArticleEdit,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Media',
      url: router.getUrl(routes.blogArticleMedia, new Map([
        ['Id', articleId.toString()]
      ])),
      activeMatchUrl: routes.blogArticleMedia,
      additionalClass: "submenu-sidebar-nav-item",

    },
    // {
    //   text: 'Preview',
    //   url: router.getUrl(routes.blogArticlePreview, new Map([
    //     ['Id', articleId.toString()]
    //   ])),
    //   activeMatchUrl: routes.blogArticlePreview,
    //   additionalClass: "submenu-sidebar-nav-item",
    // },
    {
      text: 'Delete',
      url: "",
      additionalClass: "submenu-sidebar-nav-item",
      clickHandler: () => showSelectModalState.setter(true),
    }
  ];

  return (
    <>
      <SubmenuSideBar menuItems={menuItems} />
      <SlideOutModal
        showState={showSelectModalState}
        title={"Delete Article"}
        footer={<>
          <Button onClick={selectModalCancelClick} variant="primary" className="w-100">
            Close
          </Button>
          <Button onClick={deleteSelectClick} variant="secondary" className="w-100">
            Delete
          </Button>
        </>}
      >
        Are you sure you want to delete this article?
      </SlideOutModal>
    </>
  );
}

export default BlogArticleSidebar;
