import { useCallback, useRef } from 'react'
import { BlockButton, Breakpoints, ConfirmationModal, Icon, IconAndText, IconDefinitions, ODataGrid, ResponsiveGridColumn } from '../../../components';
import { usePageActions } from '../../PageContent';
import { MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { BlogArticleModel, ODataResponse } from '../../../data/entities';
import { Grid, GridCellProps, GridColumn, GridRowClickEvent } from '@progress/kendo-react-grid';
import { useSharedState } from '../../../app/sharedProperty';
import { showSuccessMessage } from '../../../app/tools';
import { BlogService } from '../../../services';
import { AddArticleModal } from '.';
import { router, routes } from "../../../app/routes";

export function BlogArticlesList() {
  
  const gridData = useSharedState<Array<BlogArticleModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showAddState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const deleteItemState = useSharedState<BlogArticleModel>({} as BlogArticleModel)

  const blogService = new BlogService();

  usePageActions(
    <BlockButton onClick={() => { showAddState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.add} text="Add New Article" />
    </BlockButton>
  );

  const getArticles = (oDataQuery: string) => 
     blogService.getBlogArticlesAsync(oDataQuery);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;
    switch (e.item.data.action){
      case "edit":
        router.navigateWithParams(
          routes.blogArticleEdit,
          new Map([["id", dataItem.id]])
        );
        break;
      case "delete":
        showDelete(dataItem);
    }
  }


  const onRowClick = (e: GridRowClickEvent) => {
    if (e.dataItem.isDeleted) return;

    router.navigateWithParams(
      routes.blogArticleEdit,
      new Map([["id", e.dataItem.id]])
    );
  };

  const showDelete = (dataItem: BlogArticleModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }

  const addArticle = async (article: BlogArticleModel) => {
    try {
      showAddState.setter(false);
      await blogService.addBlogArticleAsync(article);
      gridRef.current?.refreshData();
      showSuccessMessage("Article added successfully.");
      // TODO: Redirect to the new article edit page
    } catch (err) {
      throw new Error(`Unable to add new article, ${err}`);
    }
  };

  const deleteArticle = async (article: BlogArticleModel) => {
    try {
      await blogService.deleteBlogArticleByIdAsync(article.id);
      gridRef.current?.refreshData();
      showSuccessMessage("Article deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete article: ${err}`);
    }
  };

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <>
      <ODataGrid
        ref={gridRef}
        fullHeight={true}
        getData={getArticles}
        dataState={gridData}
        onRowMenuSelect={onRowMenuSelect}
        onRowClick={onRowClick}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={100}
      >
        <ResponsiveGridColumn
          title="Title"
          field="title"
          sortable={true}
          filterable= {true}
        />
        <ResponsiveGridColumn
          title="Blog"
          field="blog.name"
          sortable={true}
          filterable= {true}
        />
        <ResponsiveGridColumn
          title="Author"
          field="author.displayName"
          sortable={true}
          filterable= {true}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={commandsCell}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Delete"
          data={{ action: "delete" }}
          icon={IconDefinitions.delete}
        />
      </ODataGrid>
      <AddArticleModal 
        showState={showAddState}
        onSubmit={addArticle}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.title}`}
        message={`You are about to delete this article, it cannot be undone. Are you sure you want to delete  ${deleteItemState.value.title}?`}
        confirmText="Delete"
        onConfirm={() => deleteArticle(deleteItemState.value)}
      />
    </>
  )
}

export default BlogArticlesList
