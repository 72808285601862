import { useEffect, useState } from "react";
import { router, routes } from "../../app/routes";
import { Form, Button } from "react-bootstrap";
import { showSuccessMessage } from "../../app/tools";
import UrlService from "../../services/UrlService";
import UserService from "../../services/UserService";
import { SimpleCard } from "../../components/card/Card";
import PasswordWithValidation from "../../components/form/input/PasswordWithValidation";

export function ChangePasswordPage() {
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isConfirmValid, setIsConfirmValid] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(isNewPasswordValid && isConfirmValid);
  }, [isNewPasswordValid, isConfirmValid]);

  enum fields {
    currentPassword,
    newPassword,
    confirmNewPassword,
  }

  const validateField = (field: fields, input: string, result: boolean) => {
    switch (field) {
      case fields.currentPassword:
        setCurrentPassword(input);
        break;
      case fields.newPassword:
        setNewPassword(input);
        setIsNewPasswordValid(result);
        break;
      case fields.confirmNewPassword:
        setConfirmNewPassword(input);
        if (newPassword === confirmNewPassword) result = true;
        else result = false;
        setIsConfirmValid(result);
        break;
    }
  };

  async function resetPasswordButtonClick() {

    if(!isValid) return;

    const ok = await new UserService().changePassword(
      currentPassword,
      newPassword,
      confirmNewPassword
    );

    if (!ok) return;

    showSuccessMessage("Your password was successfully updated.");
    const returnUrl = UrlService.getQueryParams("returnUrl");
    const destination = returnUrl ?? routes.resetPasswordConfirmation;
    router.navigate(destination);
  }

  return (
    <SimpleCard title="Pick a new password.">
      <Form>
        <PasswordWithValidation
          id="currentPassword"
          label="Current Password"
          validationResult={(input, result) => {
            validateField(fields.currentPassword, input, result);
          }}
        ></PasswordWithValidation>
        <PasswordWithValidation
          id="newPassword"
          label="New Password"
          validationResult={(input, result) => {
            validateField(fields.newPassword, input, result);
          }}
        ></PasswordWithValidation>
        <PasswordWithValidation
          id="confirmNewPassword"
          label="Confirm Password"
          validationResult={(input, result) => {
            validateField(fields.confirmNewPassword, input, result);
          }}
        ></PasswordWithValidation>
        <div className="col-1">
          <Button onClick={resetPasswordButtonClick} disabled={false}>Save</Button>
        </div>
      </Form>
    </SimpleCard>
  );
}

export default ChangePasswordPage;