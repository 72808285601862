import { HttpStatusCode } from "axios";
import { BlogArticleModel, BlogAuthorModel, BlogModel, ODataResponse } from "../entities";
import BaseRepository from "./BaseRepository";

export class BlogRepo extends BaseRepository {

  // Blogs
  async getBlogsAsync(oDataQuery: string = ""): Promise<ODataResponse<BlogModel>>{
    const url = `odata/blogs/?${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<BlogModel>>(response);
    return handledResponse!;
  }

  async getBlogAsync(id: number) {
    const url = `/blog/${id}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<BlogModel>(response);
    return handledResponse;
  }

  async addBlogAsync(blog: BlogModel) : Promise<boolean> {
    const url = `/blog`;
    const response = await this.client.post(url, blog);
    const code = response.status;
    if (code === HttpStatusCode.Created) {
      return true;
    }
    return false;
  }

  async editBlogAsync(blog: BlogModel) : Promise<boolean> {
    const url = `/blog/${blog.id}`;
    const response = await this.client.put(url, blog);
    const code = response.status;
    if (code === HttpStatusCode.NoContent) {
      return true;
    }
    return false;
  }

  async deleteBlogAsync(id: number) : Promise<boolean> {
    const url = `/blog/${id}`;
    const response = await this.client.delete(url);
    const code = response.status;
    if (code === HttpStatusCode.NoContent) {
      return true;
    }
    return false;
  }

  // Blog Authors
  async getBlogAuthorsAsync(oDataQuery: string = ""): Promise<ODataResponse<BlogAuthorModel>>{
    const url = `odata/blogauthors`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<BlogAuthorModel>>(response);
    return handledResponse!;
  }

  async addBlogAuthorAsync(author: BlogAuthorModel): Promise<boolean>{
    const url = `BlogAuthor`;
    const response = await this.client.post(url, author);
    const code = response.status;
    if (code === HttpStatusCode.Created) {
      return true;
    }
    return false;
  }

  async editBlogAuthorAsync(author: BlogAuthorModel): Promise<boolean>{
    const url = `/blogauthor/${author.id}`;
    const response = await this.client.put(url, author);
    const code = response.status;
    if (code === HttpStatusCode.NoContent) {
      return true;
    }
    return false;
  }

  async deleteBlogAuthorByIdAsync(id: number): Promise<boolean>{
    const url = `/blogauthor/${id}`;
    const response = await this.client.delete(url);
    const code = response.status;
    if (code === HttpStatusCode.NoContent) {
      return true;
    }
    return false;
  }

  // Blog Articles
  async getBlogArticlesAsync(oDataQuery: string = ""): Promise<ODataResponse<BlogArticleModel>>{
    const url = `odata/blogarticles/?$expand=Blog,Author`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<BlogArticleModel>>(response);
    return handledResponse!;
  }

  async getBlogArticleByIdAsync(id: number): Promise<BlogArticleModel | null> {
    const url = `/blogarticle/${id}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<BlogArticleModel>(response);
    return handledResponse;
  }

  async addBlogArticleAsync(article: BlogArticleModel): Promise<boolean>{
    const url = `BlogArticle`;
    const response = await this.client.post(url, article);
    const code = response.status;
    if (code === HttpStatusCode.Created) {
      return true;
    }
    return false;
  }

  async editBlogArticleAsync(article: BlogArticleModel): Promise<boolean>{
    const url = `/blogarticle/${article.id}`;
    const response = await this.client.put(url, article);
    const code = response.status;
    if (code === HttpStatusCode.NoContent) {
      return true;
    }
    return false;
  }

  async deleteBlogArticleByIdAsync(id: number): Promise<boolean>{
    const url = `/blogarticle/${id}`;
    const response = await this.client.delete(url);
    const code = response.status;
    if (code === HttpStatusCode.NoContent) {
      return true;
    }
    return false;
  }

}
