import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  SimpleCard,
  SpinnerDefault,
  TextWithValidation,
  Toggle,
} from "../../components";
import { Button, Form } from "react-bootstrap";
import { UrlService, UserService } from "../../services";
import { UserDetailsModel, UserDeleteModel } from "../../data/entities";
import { router, routes } from "../../app/routes";
import { showErrorMessage, showSuccessMessage } from "../../app/tools";

export function UserDeleteCard() {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [data, setData] = useState<UserDetailsModel>();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  /**
   * Load the user details.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new UserService().getUserById(id);
        setData(loadedData!);
      } catch (e) {
        console.error("Error loading user details", e);
      }
    };
    fetchData();
  }, [id]);

  /**
   * Handle the update user details button click.
   */
  const handleSubmitForm = async () => {
    try {
      const body: UserDeleteModel = {
        id: data?.id!,
        userName: data?.userName!,
        confirmed: isFormValid,
      };
      const ok = await new UserService().deleteUser(id, body);
      if (!ok) {
        showErrorMessage(`Error deleting user.`);
        return;
      } else {
        showSuccessMessage(`Deleted ${data?.userName}.`);
      }
    } catch (e) {
      console.error("Error deleting user profile", e);
    }
    router.navigate(routes.user).catch((error) => {});
  };

  if (data === undefined) return <SpinnerDefault />

  if (data === null) {
    return (
      <SimpleCard title="Failed to load data.">
        <div>
          <em>Failed to load.</em>
        </div>
      </SimpleCard>
    );
  }

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Delete this user.</h6>
      </CardTitle>
      <CardBody>
        <Form>
          <div className="row">
            <div className="col col-12">
              <TextWithValidation
                id="username"
                label="Username"
                initialValue={data?.userName}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-12">
              <p>
                You must confirm that you want to delete this user account. This
                isn't permanent, but you should be careful. If you accidentally
                delete an account or if you need to reactivate one, you have to
                send a new invitation to the same email address as the deleted
                account. Once the user registers again using the new invitation,
                the system will reactivate their old account exactly as it was
                when you deleted it.
              </p>
            </div>
            <div className="col-12 mb-2">
              <Toggle
                id="deleteToggle"
                description="Confirm Deletion"
                initialValue={false}
                onChange={(value) => setIsFormValid(value)}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-1">
              <Button onClick={handleSubmitForm} disabled={!isFormValid}>Delete</Button>
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

export default UserDeleteCard;

