import { classNames, SvgIcon } from '@progress/kendo-react-common';
import { getName } from '../helpers/helperMethods';
import { DataModel } from './../interfaces';
import { combinePaths } from '../../../../../app/tools';

export const ListView = (props: any) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: DataModel) => {
    props.onItemClick({
      dataItem: item,
      event: event
    });
    props.onSelectionChange(item)
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: DataModel) => {
    props.onDoubleClick({
      dataItem: item,
      event: event
    });
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: DataModel) => {
    event.preventDefault();
    props.onContextMenu({
      dataItem: item,
      event: event
    });
  };


  
  return (
    <div className="k-listview k-selectable k-filemanager-listview">
      <div className="k-listview-content k-d-flex k-flex-row k-flex-wrap">
        {props.data && 
          props.data.map((item: any, i: number) => {
            const name = getName(item.path);
            const { svgIcon } = item.icon;
      
            return (
                <div key={`${name}/${i}`} className={classNames("k-listview-item", { "k-selected": item.selected })}
                  onClick={event => handleClick(event, item)}
                  onDoubleClick={event => handleDoubleClick(event, item)}
                  onContextMenu={event => handleContextMenu(event, item)}
                >
                  <span className="k-file-preview">          
                  {item.isImage ? 
                    <div style={{ width: "48px", height: "48px", backgroundRepeat:'no-repeat', backgroundImage:`url(${combinePaths('/files', item.path ?? '')})`, backgroundPosition: 'center center', backgroundSize:"cover" }} /> :
                    <SvgIcon icon={svgIcon} size='xxxlarge'/>
                  }
                  </span>
                  <span className="k-file-name">{name}</span>
                </div>
            )
          })}
      </div>
    </div>
  );
}
