import { LessonModel, LevelModel } from '../../../../../data/entities';
import { BlockButton, ConfirmationModal, Icon, IconAndText, IconDefinitions } from '../../../../../components';
import { LessonService, UrlService } from '../../../../../services';
import { showSuccessMessage } from '../../../../../app/tools';
import { useSharedState } from '../../../../../app/sharedProperty';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ManageLevelsModal } from '.';
import { usePageActions } from '../../../../PageContent';


export function UpdateLessonLevels() {
  
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [lesson, setLesson] = useState<LessonModel>();
  const [levels, setLevels] = useState<Array<LevelModel>>();
  
  const showManageState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const deleteItemState = useSharedState<LevelModel>({} as LevelModel);

  const fetchLesson = useCallback(async () => {
    try {
      const loadedData = await new LessonService().getLessonByIdAsync(id!);
      if (loadedData) {
        setLesson(loadedData ?? []);
      }
    } catch (e) {
      console.error("Error loading lesson", e);
    };
  }, [id]);

  usePageActions(
    <BlockButton onClick={() => { showManageState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.edit} text="Manage Levels" />
    </BlockButton>
  );

  useEffect(() => {
    fetchLesson();
  }, [fetchLesson]);

  useEffect(() => {
    setLevels(lesson?.levels ?? []);
  }, [lesson]);

  const updateLevelsAsync = async (selectedLevels: number[]) => {
    if (lesson === undefined) return;
    try {
      await new LessonService().updateLevelsAsync(lesson.id, selectedLevels);
      fetchLesson();
      showSuccessMessage(`Levels updated successfully.`);
    }
    catch (err) {
      throw new Error(`Unable to update levels: ${err}`);
    }
  };

  const removeLevel = async (level: LevelModel) => {
    if (lesson === undefined) return;
    try {
      await new LessonService().removeLevelAsync(lesson.id, level.id)
      fetchLesson();
      showSuccessMessage("Level deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete level: ${err}`);
    } 
  }

  const showDelete = (dataItem: LevelModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }


  if (lesson === undefined) return null;
  if (lesson === null) return null;
  return (
    <>
      <table className="table hover-row mt-3">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {levels?.map((level) => (
            <tr key={level.id}>
              <td>
                {level.name}
              </td>
              <td>
                {level.code}
              </td>
              <td>
                <Icon 
                  onClick={() => showDelete(level)}
                  className="mx-2 text-danger cursor-pointer"
                  iconName={IconDefinitions.delete}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ManageLevelsModal
        showState={showManageState}
        assignedLevels={levels}
        onSubmit={updateLevelsAsync}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Remove Level ${deleteItemState.value?.name}`}
        message={`You are about to remove this level. Are you sure you want to remove ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => removeLevel(deleteItemState.value)}
      />
    </>
  )
}

export default UpdateLessonLevels
