import { useEffect, useState } from "react";
import { Option } from "..";
import { ModuleService } from "../../../services/ModuleService";
import DropdownWithValidation from "./DropdownWithValidation";

export interface ModulePickerProps {
  id: string;
  label: string;
  isInvalid?: boolean;
  onSelect: (value: string) => void;
  initialValue?: string;
  feedback?: React.ReactNode;
}

export function ModulePicker(props: Readonly<ModulePickerProps>) {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        let moduleList: { value: string, text: string }[] = [{ value: "", text: "Select Module" }];
        const data = await new ModuleService().getModulesAsync();
        if (!data) return;
        data.value.forEach((module) => {
          const map = new Map(Object.entries(module));
          const id = map.get("id");
          const name = map.get("name");
          let moduleInfo = { value: id, text: name };
          moduleList.push(moduleInfo);
        });
        setOptions(moduleList);
      } catch (e) {
        console.error("Error loading modules", e);
      }
    };

    fetchModules();
  }, []);

  return (
    <DropdownWithValidation
      required={true}
      id={props.id}
      label={props.label}
      options={options}
      initialValue={props.initialValue}
      onChangeSelected={(value: React.SetStateAction<string>) => {
        props.onSelect(value.toString());
      }}
      isInvalid={props.isInvalid}
      feedback={props.feedback}
    />
  );
}

export default ModulePicker;
