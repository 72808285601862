import "./SubmenuSideBarItem.scss";
import { router } from "../../app/routes";
import UrlService from "../../services/UrlService";
import { SubmenuSideBarNavItemModel } from "../../data/entities";

export function SubmenuSideBarNavItem({
  text,
  url,
  additionalClass,
  activeMatchUrl,
  activeMatchExact,
  clickHandler,
}: SubmenuSideBarNavItemModel) {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (clickHandler) {
      clickHandler();
    } else {
      router.navigate(url).catch((error) => {});
    }
  };

  if (activeMatchUrl) {
    const isActive = UrlService.isUrlActive(
      activeMatchUrl,
      activeMatchExact ?? activeMatchExact === undefined
    );
    const activeText = isActive ? "active" : "";
    additionalClass = `${additionalClass ?? ""} ${activeText}`.trim();
  }

  return (
    <li className={`nav-item ${additionalClass ?? ""}`}>
      <span
        className="nav-link submenu-sidebar-nav-link"
        title={text}
        onClick={handleClick}
      >
        {text}
      </span>
    </li>
  );
}

export default SubmenuSideBarNavItem;

