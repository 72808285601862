import { routes, router } from "../../../app/routes";
import { useParams } from "react-router-dom";
import SubmenuSideBar from "../../../components/sideBar/SubmenuSideBar";
import { ActivityService, UrlService } from "../../../services";
import { Button } from "react-bootstrap";
import { useSharedState } from "../../../app/sharedProperty";
import { SlideOutModal } from "../../../components/modals/SlideOutModal";
import { showSuccessMessage } from "../../../app/tools";

export function ActivitySidebar() {

  const urlParams = useParams();
  const activityId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  const deleteSelectClick = async () => {
    const ok = await deleteActivity(activityId);
    console.log(ok);
    if(!ok){return}
    else{
      showSuccessMessage(`Activity deleted`);
    };
    router.navigate(routes.catalogActivities).catch((error) => {});
  };

  async function deleteActivity(id: number) {
    await new ActivityService().deleteActivityByIdAsync(id);
    return true;
  }


  const menuItems = [
    {
      text: 'Details',
      url: router.getUrl(routes.activityDetails, new Map([
        ['Id', activityId.toString()]
      ])),
      activeMatchUrl: routes.activityDetails,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Objectives',
      url: router.getUrl(routes.activityAssessments, new Map([
        ['Id', activityId.toString()]
      ])),
      activeMatchUrl: routes.activityAssessments,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Delete',
      url: "",
      additionalClass: "submenu-sidebar-nav-item",
      clickHandler: () => showSelectModalState.setter(true),
    }
  ];

  return (
    <>
      <SubmenuSideBar menuItems={menuItems} />
      <SlideOutModal
        showState={showSelectModalState}
        title={"Delete Activity"}
        footer={<>
          <Button onClick={selectModalCancelClick} variant="primary" className="w-100">
            Close
          </Button>
          <Button onClick={deleteSelectClick} variant="secondary" className="w-100">
            Delete
          </Button>
        </>}
      >
        Are you sure you want to delete this activity?
      </SlideOutModal>
    </>
  );
}

export default ActivitySidebar;
