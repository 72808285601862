import "./NavigationItem.scss";
import UrlService from "../../services/UrlService";
import { IconAndText } from "../icons";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

interface NavigationItemProps extends React.HTMLAttributes<HTMLLIElement> {
  text: string;
  url: string;
  icon: string;
  onClick?: React.MouseEventHandler;
  additionalClass?: string;
  activeMatchUrl?: string | string[];
  activeMatchExact?: boolean;
}

export function NavigationItem(props: NavigationItemProps) {
  const { text, url, icon, onClick, activeMatchUrl, activeMatchExact } = props;
  let additionalClass = props.additionalClass;

  const location = useLocation();

  useEffect(() => {
    // This makes the menus being "active" when the user navigates to a page
    return () => {};
  }, [location]);

  if (activeMatchUrl) {
    const isActive = UrlService.isUrlActive(
      activeMatchUrl,
      activeMatchExact ?? activeMatchExact === undefined
    );
    const activeText = isActive ? "active" : "";
    additionalClass = `${additionalClass ?? ""} ${activeText}`.trim();
  }

  const allKeys = Object.keys(props);
  const blockedKeys = [
    "text",
    "url",
    "icon",
    "onClick",
    "additionalClass",
    "activeMatchUrl",
    "activeMatchExact",
  ];
  const filteredProps = allKeys
    .filter((key) => !blockedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = props[key as keyof NavigationItemProps];
      return obj;
    }, {} as { [key: string]: any });

  return (
    <li {...filteredProps} className={`nav-item ${additionalClass ?? ""}`}>
      <Link to={url} onClick={onClick} className="nav-link">
        <IconAndText iconName={icon.toString()} text={text}></IconAndText>
      </Link>
    </li>
  );
}

export default NavigationItem;

