import { router, routes } from "../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function generateBreadcrumbs(
  location: string,
  userName: string | null,
  userId: number
): BreadcrumbProps {
  const idMap = new Map([["Id", userId.toString()]]);

  switch (location) {
    case routes.user:
      return {
        showHome: true,
        values: [{ label: "Users", url: null }],
      };
    case router.getUrl(routes.userDetails, idMap):
      return {
        showHome: true,
        values: [
          { label: "Users", url: routes.user },
          {
            label: userName ?? "",
            url: null,
          },
        ],
      };
    case router.getUrl(routes.userSecurity, idMap):
      return {
        showHome: true,
        values: [
          { label: "Users", url: routes.user },
          {
            label: userName ?? "",
            url: router.getUrl(routes.userDetails, idMap),
          },
          { label: "Security", url: null },
        ],
      };
    case router.getUrl(routes.userDelete, idMap):
      return {
        showHome: true,
        values: [
          { label: "Users", url: routes.user },
          {
            label: userName ?? "",
            url: router.getUrl(routes.userDetails, idMap),
          },
          { label: "Delete", url: null },
        ],
      };

    default:
      return { showHome: false, values: [] };
  }
}

export default generateBreadcrumbs;

