import { PageContext, usePageActions } from '../../PageContent';
import { useOutletContext } from 'react-router';
import AddBlogModal from './AddBlogModal';
import { useSharedState } from '../../../app/sharedProperty';
import { useCallback, useRef } from 'react';
import { showSuccessMessage } from '../../../app/tools';
import { BlogModel, ODataResponse } from '../../../data/entities';
import { BlockButton, Breakpoints, ConfirmationModal, Icon, IconAndText, IconDefinitions, ODataGrid, ResponsiveGridColumn } from '../../../components';
import { BlogService } from '../../../services';
import EditBlogModal from './components/EditBlogModal';
import { MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { GridCellProps } from '@progress/kendo-react-grid';

export function BlogsList() {

  const gridData = useSharedState<Array<BlogModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showAddState = useSharedState(false);
  const showEditState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const editItemState = useSharedState<BlogModel>({} as BlogModel)
  const deleteItemState = useSharedState<BlogModel>({} as BlogModel)
  const blogService = new BlogService();

  usePageActions(
    <BlockButton onClick={() => { showAddState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.add} text="Add New Blog" />
    </BlockButton>
  );

  const getBlogs = (oDataQuery: string) => 
     blogService.getBlogsAsync(oDataQuery);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;
    switch (e.item.data.action){
      case "edit":
        showEdit(dataItem);
        break;
      case "delete":
        showDelete(dataItem);
    }
  }

  const showEdit = (dataItem: BlogModel) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  }

  const showDelete = (dataItem: BlogModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }

  const addBlog = async (blog: BlogModel) => {
    try {
      showAddState.setter(false);
      await blogService.addBlogAsync(blog);
      gridRef.current?.refreshData();
      showSuccessMessage("Blog added successfully.");
    } catch (err) {
      throw new Error(`Unable to add new blog, ${err}`);
    }
  };

  const editBlog = async (blog: BlogModel) => {
    try {
      showEditState.setter(false);
      await blogService.editBlogAsync(blog);
      gridRef.current?.refreshData();
      showSuccessMessage("Blog updated successfully.");
    } catch (err) {
      throw new Error(`Unable to update blog: ${err}`);
    }
  }

  const deleteBlog = async (blog: BlogModel) => {
    try {
      await blogService.deleteBlogAsync(blog.id);
      gridRef.current?.refreshData();
      showSuccessMessage("Blog deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete blog: ${err}`);
    }
  };

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <>
      <ODataGrid
        ref={gridRef}
        fullHeight={true}
        getData={getBlogs as () => Promise<ODataResponse<any>>}
        dataState={gridData}
        onRowMenuSelect={onRowMenuSelect}
        onRowClick={(e) => { showEdit(e.dataItem); }}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={100}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={commandsCell}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Delete"
          data={{ action: "delete" }}
          icon={IconDefinitions.delete}
        />
      </ODataGrid>

      <AddBlogModal 
        showState={showAddState}
        onSubmit={addBlog}
      />
      <EditBlogModal 
        showState={showEditState}
        dataItemState= {editItemState}
        onSubmit={editBlog}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.name}`}
        message={`You are about to delete this blog, it cannot be undone. Are you sure you want to delete blog: ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteBlog(deleteItemState.value)}
      />
      
    </>
  )
}

export default BlogsList
