import { Card, CardBody, CardTitle } from '../../../../../components';
import UpdateLessonRegions from './UpdateLessonRegions';

export function LessonRegions() {
  

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Edit Lesson Regions</h6>
      </CardTitle>
      <CardBody>
        <UpdateLessonRegions/>
      </CardBody>
    </Card>
  )
}

export default LessonRegions
