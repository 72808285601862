import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { PageBreadcrumbProps, PageContent } from "../PageContent"
import UsersSubmenu from "./UsersSubmenu";
import { UrlService, UserService } from "../../services";
import { ODataResponse, UserDetailsModel } from "../../data/entities";
import { generateBreadcrumbs } from "./UsersBreadcrumbs";
import { BreadcrumbProps } from "../../components";


export function UsersPage() {

  const location = useLocation();
  const urlParams = useParams();
  const userId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [userName, setUserName] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();

  /**
   * When the user id changes, set the new user's name
   */
  useEffect(() => {
    async function getUserById(){
      const user = await new UserService().getUsers(`filter=id eq ${userId}`);
      return user;
    };

    if(userId){
      getUserById().then((response: ODataResponse<UserDetailsModel>) => {
        setUserName(response.value[0].userName);
      });
    }
  }, [userId]);

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    const newBreadcrumbs:BreadcrumbProps = generateBreadcrumbs(location.pathname, userName, userId);
    setBreadcrumbs(newBreadcrumbs);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <PageContent submenu={<UsersSubmenu />} title="Manage user accounts" breadcrumbs={breadcrumbs} footerless>
      <Outlet />
    </PageContent >
  )
}

export default UsersPage

