import { BlogAuthorsList, BlogsSubmenu, PageBreadcrumbProps, PageContent, PageContext, getBlogsBreadcrumbs } from '../..';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function BlogAuthorsPage() {
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();
  const title = "Blogs: Authors"

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getBlogsBreadcrumbs(location.pathname);
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      title={title}
      breadcrumbs={breadcrumbs}
      submenu={<BlogsSubmenu />}
    >
      <BlogAuthorsList />
    </PageContent>
  );
}

export default BlogAuthorsPage
