import { useEffect } from "react";
import { router, routes } from "../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function getActivityBreadcrumbs(location: string, activityId: number): BreadcrumbProps {
  const idMap = new Map([["Id", activityId.toString()]]);

    console.log("location", location);
    console.log("idMap", idMap)
    console.log("route", router.getUrl(routes.activityDetails, idMap));

  switch (location) {
    case router.getUrl(routes.activityDetails, idMap):
      return {
        showHome: true,
        values: [
          { label: "Catalog", url: routes.catalog },
          {
            label: "Activities",
            url: routes.catalogActivities
          },
          { label: "Details", url: null }
        ],
      };
    case router.getUrl(routes.activityAssessments, idMap):
      return {
        showHome: true,
        values: [
          { label: "Catalog", url: routes.catalog },
          {
            label: "Activities",
            url: routes.catalogActivities
          },
          { label: "Assessments", url: null }
        ],
      };
    default:
      return { showHome: false, values: [] };
  }
}

export default getActivityBreadcrumbs;

