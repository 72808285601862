import React, { SetStateAction, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";

interface Option {
  value: string;
  text: string;
}

interface DropdownProps {
  id: string;
  label: string;
  required?: boolean;
  initialValue?: any;
  isInvalid?: boolean;
  disabled?: boolean;
  feedback?: React.ReactNode;
  options: Option[] | null;
  validationFunction?: (input: string) => boolean;
  onChangeSelected?: (value: SetStateAction<string>) => void;
  validationResult?: (input: string, result: boolean) => void;
  formatFunction?: (input: string) => string;
}

export const DropdownWithValidation = forwardRef(
  (props: DropdownProps, ref) => {
  
    const {
      initialValue,
      id,
      label,
      disabled,
      required,
      validationFunction,
      options,
      onChangeSelected,
      validationResult,
      formatFunction
  } = props;

    //Handle the initial value different scenarios
    const startValue = (): string => {
      if (initialValue === undefined) return "1";
      const asString = initialValue?.toString() ?? "";
      if (!formatFunction) return asString;
      return formatFunction(asString);
    };

    const [selectedOption, setSelectedOption] = useState(startValue());
    const [customClassString, setCustomString] = useState("");
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const [validationMessage, setValidationMessage] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(e.target.value);
      if (onChangeSelected) onChangeSelected(e.target.value);
    };

    function handleBlur(): boolean {
      let message = `Invalid ${label}`;
      let result = false;
      let valid = required ? selectedOption !== "" : true;

      if (required && selectedOption === "")
        message = "Field is required. Please select an option.";
      else result = valid;

      setValidationMessage(message);

      if (validationFunction) result = validationFunction(selectedOption.toString());
      if (validationResult) validationResult(selectedOption.toString(), result);

      console.debug(`${label} is ${result ? "valid" : "INVALID"}.}`);
      setIsValid(result);

      if (formatFunction) {
        const formattedValue = formatFunction(selectedOption.toString());
        setSelectedOption(formattedValue);
      }

      return result;
    }

    useEffect(() => {
      if (disabled) setCustomString("");
      else setCustomString("clickable");
    }, [disabled]);

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return handleBlur();
      },
    }));

    return (
      <FloatingLabel
        controlId={id}
        label={label}
        className="mb-3"
        aria-label={label}
        title={label}
      >
        <Form.Select
          className={customClassString}
          id={id}
          onChange={handleChange}
          value={initialValue}
          onBlur={handleBlur}
          disabled={disabled}
          isInvalid={props.isInvalid ? props.isInvalid : isValid !== undefined && !isValid}
          isValid={isValid}
          required={required}
          aria-label={label}
          title={label}
        >
          {options?.sort((a: Option, b: Option) => {
            // Place option with a value of "" at the beginning
            if (a.value === "" && b.value !== "") return -1;
            if (a.value !== "" && b.value === "") return 1;

            // Alphabetically sort the remaining options
            return a.text.localeCompare(b.text);
          }).map((option: Option, index: number) => (
              <option
                key={`option-${option.value}`}
                value={option.value}
                title={option.text}
                label={option.text}
                aria-label={option.text}
              >
                {option.text}
              </option>
            ))
          }
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {props.feedback}
        </Form.Control.Feedback>
      </FloatingLabel>
    );
  }
);

DropdownWithValidation.defaultProps = {
  required: false
}

export default DropdownWithValidation;

