import { FormEvent, useRef, useState } from 'react'
import { SlideOutModal, SlideOutModalProps } from '../../../../../../components'
import sharedProperty from '../../../../../../app/sharedProperty'
import { BlogArticleModel } from '../../../../../../data/entities';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';

interface ManageArticleMediaModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<BlogArticleModel>;
  onSubmit: (data: BlogArticleModel) => void;
}

export function ManageArticleMediaModal(props: Readonly<ManageArticleMediaModalProps>) {

  const schema = yup.object().shape({
    thumbnailImageUrl: yup.string().notRequired(),
    headerImageUrl: yup.string().notRequired(),
    openGraphImageUrl: yup.string().notRequired(),
  })

  const formSubmitRef = useRef<HTMLButtonElement>(null);

  
  return (
    <SlideOutModal {...props}
      title={`Edit ${props.dataItemState.value.title} Media`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Update
            </Button>
          </div>
        </div>
      </div>}>
        <Formik
          validationSchema={schema}
          initialValues={{
            thumbnailImageUrl: props.dataItemState.value.thumbnailImageUrl,
            headerImageUrl: props.dataItemState.value.headerImageUrl,
            openGraphImageUrl: props.dataItemState.value.openGraphImageUrl,
          }}
          onSubmit = { async (values, { setSubmitting }) => {
            const newArticle = {
              ...props.dataItemState.value,
              ...values
            } as BlogArticleModel;
            props.onSubmit(newArticle);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {

            return(
              <Form onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col>
                    <Form.Group controlId="thumbnailImageUrl">
                      <FloatingLabel label="Thumbnail Image URL" className="mb-3">
                        <Form.Control
                          type="text"
                          name="thumbnailImageUrl"
                          value={values.thumbnailImageUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.thumbnailImageUrl && !!errors.thumbnailImageUrl}
                          isValid={touched.thumbnailImageUrl && !errors.thumbnailImageUrl}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.thumbnailImageUrl}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="headerImageUrl">
                      <FloatingLabel label="Header Image URL" className="mb-3">
                        <Form.Control
                          type="text"
                          name="headerImageUrl"
                          value={values.headerImageUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.headerImageUrl && !!errors.headerImageUrl}
                          isValid={touched.headerImageUrl && !errors.headerImageUrl}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.headerImageUrl}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="openGraphImageUrl">
                      <FloatingLabel label="Open Graph Image URL" className="mb-3">
                        <Form.Control
                          type="text"
                          name="openGraphImageUrl"
                          value={values.openGraphImageUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.openGraphImageUrl && !!errors.openGraphImageUrl}
                          isValid={touched.openGraphImageUrl && !errors.openGraphImageUrl}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.openGraphImageUrl}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Button ref={formSubmitRef} type="submit" className="d-none" />
              </Form>
            );
          }}
        </Formik>
      {props.children}
    </SlideOutModal>
  )
}

export default ManageArticleMediaModal
