import BlogsSubmenu from "../BlogsSubmenu";
import { PageContent, PageBreadcrumbProps } from "../../PageContent";
import { useLocation } from "react-router-dom";
import { getBlogsBreadcrumbs } from "../BlogsBreadcrumbs";
import { useEffect, useState } from "react";
import { BlogsList } from ".";

export function BlogsPage() {
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();
  const title = "Blogs";

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getBlogsBreadcrumbs(location.pathname);
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      title={title}
      breadcrumbs={breadcrumbs}
      submenu={<BlogsSubmenu />}
    >
      <BlogsList />
    </PageContent>
  );
}

export default BlogsPage;

