import { ModuleModel, ODataResponse } from "../data/entities";
import { ModuleRepo } from "../data/repo";

export class ModuleService {
  
  async getModulesAsync(oDataQuery: string = ""): Promise<ODataResponse<ModuleModel>> {
    return await new ModuleRepo().getModulesAsync(oDataQuery);
  }

  async addModuleAsync(module: ModuleModel): Promise<boolean | null> {
    return await new ModuleRepo().addModuleAsync(module);
  }

  async editModuleAsync(module: ModuleModel): Promise<boolean | null> {
    return await new ModuleRepo().editModuleAsync(module);
  }

  async deleteModuleByIdAsync(id: number): Promise<boolean | null> {
    return await new ModuleRepo().deleteModuleByIdAsync(id);
  }
  
}
