import { FormEvent, useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { TextWithValidation, MultiLineTextWithValidation, SpinnerDefault } from '../../../../../components'
import { ActivityModel } from '../../../../../data/entities';
import { ActivityService, UrlService } from '../../../../../services';
import { useParams } from 'react-router-dom';
import { SubdisciplinePicker } from '../../../../../components/form/dropdown';

interface UpdateActivityDetailsProps {
  onSubmit: (data: ActivityModel) => void;
}

export function UpdateActivityDetails(props: Readonly<UpdateActivityDetailsProps>) {

  
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [formData, setFormData] = useState<ActivityModel>();
  const [validated, setValidated] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new ActivityService().getActivityByIdAsync(Number(id)!);
        if(loadedData) {
          setFormData(loadedData);
        }
      } catch(e) {
        console.error("Error loading activity", e);
      };
    };
    fetchData();
  }, [id]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, formData!);
      setValidated(false);
    } else {
      setValidated(false);
    }
  };

  /**
   * Handle the form field changes.
   *
   * @param fieldName
   * @param value
   */
  const handleFieldChange = (key: string, value: any) => {
    setFormData({ ...formData!, [key]: value });
  };

  
  if (formData === undefined) return <SpinnerDefault />
  return (
    <>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="col-12">
          <TextWithValidation
            id="name"
            required={true}
            label="Name"
            initialValue={formData.name}
            onChangeText={(v) => handleFieldChange("name", v)}
          /> 
        </div>
        <div className="col-12">
          <TextWithValidation
            id="code"
            required={true}
            label="Code"
            initialValue={formData.code}
            onChangeText={(v) => handleFieldChange("code", v)}
          />
        </div>
        <div className="col-12">
          <SubdisciplinePicker
            id="subdisciplineId"
            label="Subdiscipline"
            onSelect={(v) => handleFieldChange("subdisciplineId", v)}
            initialValue={formData.subdisciplineId.toString()}
          />
        </div>
        <div className="col-12">
          <TextWithValidation
            id="duration"
            type="number"
            label="Duration"
            initialValue={formData.duration}
            onChangeText={(v) => handleFieldChange("duration", v)}
          />
        </div>
        <div className="col-12">
          <MultiLineTextWithValidation
            id="description"
            label="Description"
            initialValue={formData.description}
            onChangeText={(v) => handleFieldChange("description", v)}
          />
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      <div className="container p-0">
        <div className="row g-0">
          <div className="col ps-0">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="">
              Update
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateActivityDetails
