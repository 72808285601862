import React from "react";
import "./Breadcrumbs.scss";
import { v4 as uuidv4 } from "uuid";
import { Link } from "../link";

export interface Breadcrumb {
  label: string;
  url?: string | null;
  params?: Map<string, string>;
}

export interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li className="breadcrumb-item" key={uuidv4()}>
            {breadcrumb.url ? (
              <Link url={breadcrumb.url} params={breadcrumb.params}>{breadcrumb.label}</Link>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

