import React, { ReactElement } from "react";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";

import {
  Grid,
  GridProps,
  GridColumn,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";

import {
  GridODataLoader,
  GridRowContextMenu,
  GridRowContextMenuCell,
  GridRowContextMenuSelectFunction,
  GridODataLoaderGetDataFunction,
  ResponsiveGridColumn,
} from "./";
import { State } from "@progress/kendo-data-query";
import sharedProperty from "../../app/sharedProperty";
import { getUuid } from "../../app/tools";

export interface ODataGridProps extends GridProps {
  fullHeight?: boolean;
  onRowMenuSelect?: GridRowContextMenuSelectFunction;
  getData: GridODataLoaderGetDataFunction;
  dataState?: sharedProperty<any>;
}

interface ODataGridState {
  dataState: State;
  data: any;
  total: number;
  loading: boolean;
}

export class ODataGrid extends React.Component<ODataGridProps, ODataGridState> {
  private gridRef;
  private rowMenuRef;
  private dataLoaderRef;
  private columnComponents = [GridColumn, ResponsiveGridColumn];

  get columns(): ReactElement[] {
    return React.Children.toArray(this.props.children).filter((child) => {
      const node = child as ReactElement;
      return node && this.columnComponents.some((c) => c === node.type);
    }) as ReactElement[];
  }

  get rowMenuItems() {
    return React.Children.toArray(this.props.children).filter((child) => {
      const node = child as ReactElement;
      return node && node.type === MenuItem;
    });
  }

  constructor(props: ODataGridProps) {
    super(props);

    this.onDataReceived = this.onDataReceived.bind(this);
    this.onDataStateChange = this.onDataStateChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onRowMenuSelect = this.onRowMenuSelect.bind(this);
    this.resizeGrid = this.resizeGrid.bind(this);

    this.gridRef = React.createRef<Grid>();
    this.rowMenuRef = React.createRef();
    this.dataLoaderRef = React.createRef();

    this.state = {
      dataState: {
        skip: 0,
        take: props.take ?? 100,
        sort: props.sort,
        filter: props.filter,
        group: props.group,
      },
      data: [],
      total: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.resizeGrid();
    window.addEventListener("resize", this.resizeGrid);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeGrid);
  }

  resizeGrid() {
    if (this.props.fullHeight && this.gridRef.current) {
      let grid: any = this.gridRef.current;
      let wrapper: HTMLDivElement =
        grid.containerRef.current.closest(".grid-fullheight");
      wrapper.style.height = `${
        window.innerHeight - wrapper.getBoundingClientRect().top - 16
      }px`;
    }
    this.setState({});
  }

  //This is used byRef on different components, despite the SonarLint alerts.
  //DO NOT REMOVE IT
  //TODO: Refactor this to use the hook useImperativeHandle
  refreshData() {
    (this.dataLoaderRef.current as any)?.refresh();
  }

  onDataReceived(data: any, total: number) {
    this.props.dataState?.setter(data);
    this.setState({ data: data, total: total });
    this.resizeGrid();
  }

  onDataStateChange(e: any) {
    this.setState({
      dataState: e.dataState,
    });
  }

  onRowClick(e: any) {
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopPropagation();

    const menu: any = this.rowMenuRef.current;
    menu.open(e.nativeEvent.clientX, e.nativeEvent.clientY, e.dataItem);
  }

  onRowMenuSelect(e: MenuSelectEvent, dataItem: any) {
    if (this.props.onRowMenuSelect) {
      this.props.onRowMenuSelect.call(this, e, dataItem);
    }
  }

  contextMenuCell = (props: GridCustomCellProps) => {
    return (
      <GridRowContextMenuCell
        dataItem={props.dataItem}
        menuRef={this.rowMenuRef}
      />
    );
  };

  getColumns() {
    const width = window.innerWidth;
    const validColumns = this.columns.filter((column: ReactElement) => {
      const breakpoint = column.props.breakpoint ?? 0;
      return (
        column.type === GridColumn ||
        (column.type === ResponsiveGridColumn && width > breakpoint)
      );
    });

    return validColumns.map((column: ReactElement) => {
      return <GridColumn key={getUuid()} {...column.props} />;
    });
  }

  render() {
    // This removes the state props from the props passed to the Grid component.
    // This way the grid can update its state without the parent component
    const stateProps = ["sort", "filter", "group", "skip", "take"];
    const propsArray = Object.entries(this.props);
    const filteredProps = propsArray.filter(([key, value]) => {
      return !stateProps.includes(key);
    });
    const newProps = Object.fromEntries(filteredProps);

    return (
      <>
        <Grid
          ref={this.gridRef}
          className={`m-0 ${this.props.fullHeight ? "grid-fullheight" : ""} ${
            this.props.onRowClick ? "gridrow-clickable" : ""
          }`}
          filterable={true}
          sortable={true}
          pageable={true}
          {...this.state.dataState}
          total={this.state.total}
          data={this.props.dataState?.value ?? this.state.data}
          onDataStateChange={this.onDataStateChange}
          onRowClick={this.onRowClick}
          onContextMenu={this.onRowClick}
          {...newProps}
        >
          {!this.rowMenuItems.length ? (
            <></>
          ) : (
            <GridColumn
              filterable={false}
              sortable={false}
              width={35}
              cells={{
                data: this.contextMenuCell,
              }}
            />
          )}
          {this.getColumns()}
        </Grid>
        <GridODataLoader
          ref={this.dataLoaderRef}
          dataState={this.state.dataState}
          onDataReceived={this.onDataReceived}
          getData={this.props.getData}
        />
        <GridRowContextMenu
          ref={this.rowMenuRef}
          onSelect={this.onRowMenuSelect}
        >
          {this.rowMenuItems}
        </GridRowContextMenu>
      </>
    );
  }
}

export default ODataGrid;

