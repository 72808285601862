import React, { useEffect, useState } from 'react'
import { BlogArticleModel } from '../../../../../../data/entities';
import { BlogService, UrlService } from '../../../../../../services';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { showErrorMessage, showSuccessMessage } from '../../../../../../app/tools';
import BlogPicker from '../../../../../../components/form/dropdown/BlogPicker';
import { Card, CardBody, CardTitle, CustomDatePicker, HandleDatePickerChange } from '../../../../../../components';
import AuthorPicker from '../../../../../../components/form/dropdown/AuthorPicker';
import { useFormPrompt } from '../../../../../../app/hooks';
import { EditorMountEvent } from '@progress/kendo-react-editor';

export function BlogArticleEditor() {
  // Get the id from the url
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  const [pageData, setPageData] = useState<BlogArticleModel | null>();
  const [notSaved, setNotSaved] = useState(false);

  useFormPrompt(notSaved);

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    summary: yup.string().notRequired(),
    content: yup.string().notRequired(),
    authorId: yup.number().notRequired(),
    blogId: yup.number().required("Blog is required"),
    showOnDate: yup.date().required('Show on date is required'),
  });

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
  } = EditorTools;

  useEffect(() => {
    const blogService = new BlogService();
    const fetchData = async () => {
      try {
        const loadedData = await blogService.getBlogArticleByIdAsync(Number(id)!);
        setPageData(loadedData);
      }
      catch (e) {
        console.error("Error loading article", e);
      }
    };

    fetchData();
  }, [id]);

  const handleEditorMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const styleEl = iframeDocument.createElement('style');
    styleEl.type = 'text/css';
    styleEl.innerHTML = `
      body {
        font-family: Satoshi, sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #191726;
      }

      p {
        color: #55568b;
        letter-spacing: -.02em;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 0;
        padding: 0 !important;
      }

      h1, h2, h3, h4 {
        color: #191726;
        letter-spacing: -.04em;
        margin-top: 0;
        font-weight: 700;
        line-height: 48px;
      }

      h1 {
        font-size: 40px;
      }

      h2 {
        font-size: 32px;
      }

      h3 {
        font-size: 24px;
      }

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 18px;
      }

      h6 {
        font-size: 16px;
      }
      
      blockquote p{
        background-color: #daeafa;
        color: #191726 !important;
        border: 1px #000;
        border-radius: 8px;
        margin-bottom: 48px;
        padding: 28px !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    `;
    iframeDocument.head.appendChild(styleEl);
  };

  if (!pageData) {
    return <></>;
  }

  return (
    <Card>
      <CardTitle>
        <h6 className={`text-light m-0`}>Article Details</h6>
        <h6 className={`text-light m-0`}>{notSaved ? "Not Saved" : ""}</h6>
      </CardTitle>
      <CardBody>
        <Formik
          initialValues={{
              title: pageData.title ?? "",
              summary: pageData.summary ?? "",
              content: pageData.content ?? "",
              authorId: pageData.authorId ?? "",
              blogId: pageData.blogId,
              showOnDate: pageData.showOnDate ?? new Date().toISOString(),
            }}
          validationSchema={schema}
          onSubmit = {async (values, { setSubmitting }) => {
            const blogService = new BlogService();
            const updatedArticle = {
              ...pageData,
              ...values
            } as BlogArticleModel;
            const response = await blogService.editBlogArticleAsync(updatedArticle);
            if (response){
              showSuccessMessage("Article updated successfully");
              setNotSaved(false);
            }
            else {
              showErrorMessage("Error updating article");
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              setNotSaved(true);
            };
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="title">
                      <FloatingLabel label="Title" className="mb-3">
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          isInvalid={touched.title && !!errors.title}
                          isValid={touched.title && !errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <AuthorPicker
                      id="authorId"
                      label="Author"
                      onSelect={(authorId) => {
                        setFieldValue('authorId', authorId);
                        setNotSaved(true);
                      }}
                      initialValue={values.authorId.toString()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="mb-3">
                      <FloatingLabel className="h-100 pb-0 floating-label-fixed" label="Show on date" style={{"color": "rgba(var(--bs-body-color-rgb), 0.65)"}}>
                        <CustomDatePicker
                          name="showOnDate"
                          value={new Date(values.showOnDate)}
                          onChange={(e) => {
                            HandleDatePickerChange(e, setFieldValue)
                            setNotSaved(true);
                          }}
                          isInvalid={touched.showOnDate && !!errors.showOnDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {String(errors.showOnDate)}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                  </Col>
                  <Col lg={6}>
                    <BlogPicker 
                      id="blogId"
                      label="Blog"
                      onSelect={(blogId) => {
                        setFieldValue('blogId', blogId);
                        setNotSaved(true);
                      }}
                      initialValue={values.blogId.toString()}
                      isInvalid={touched.blogId && !!errors.blogId}
                      feedback={errors.blogId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="summary">
                      <FloatingLabel label="Summary" className="mb-3">
                        <Form.Control
                          type="text"
                          name="summary"
                          value={values.summary}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          isInvalid={touched.summary && !!errors.summary}
                          isValid={touched.summary && !errors.summary}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.summary}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Editor
                      tools={[
                        [Bold, Italic, Underline, Strikethrough],
                        [Subscript, Superscript],
                        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                        [Indent, Outdent],
                        [OrderedList, UnorderedList],
                        FontSize,
                        FormatBlock,
                        [Undo, Redo],
                        [Link, Unlink, InsertImage, ViewHtml],
                      ]}
                      onMount={handleEditorMount}
                      contentStyle={{ height: 630 }}
                      onChange={(event) => {
                        setFieldValue('content', event.html);
                        setNotSaved(true);
                      }}
                      value={values.content}
                    />
                  </Col>
                </Row>
                <Button type="submit" className="btn btn-primary">Save</Button>
              </Form>
            )
          }}
        </Formik>
      </CardBody>
    </Card>
  )
}

export default BlogArticleEditor
