import { Card, CardBody, CardTitle } from '../../../../../components';
import UpdateLessonMedia from './UpdateLessonMedia';

export function LessonMedia() {

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Manage Lesson Media</h6>
      </CardTitle>
      <CardBody>
        <UpdateLessonMedia/>
      </CardBody>
    </Card>
  )
}

export default LessonMedia
