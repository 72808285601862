import React, { useEffect, useMemo, useState } from "react";
import {
  ButtonWithSpinner,
  EmailWithValidation,
  SlideOutModal,
  SlideOutModalProps,
  TextWithValidation,
} from "../../components";
import { propsFilter, showSuccessMessage } from "../../app/tools";
import FormWithValidation from "../../components/form/FormWithValidation";
import { Button } from "react-bootstrap";
import { UserService } from "../../services";

export interface AddNewUserModalProps extends SlideOutModalProps {
  onCancel?: () => void;
  onSaved?: () => void;
}

function AddNewUserModal(props: Readonly<AddNewUserModalProps>) {
  const formName = "frmAddUser";
  const [showSpinner, setShowSpinner] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const filteredProps = useMemo(
    () => propsFilter(props, ["onCancel", "onConfirm"]),
    [props]
  );

  useEffect(() => {
    setEmail("");
    setFirstName("");
    setLastName("");
    return () => {};
  }, [props.showState.value]);

  const handleSubmitForm = async () => {
    setShowSpinner(true);
    try {
      const isUpdated = await new UserService().register(
        email,
        firstName,
        lastName
      );
      if (isUpdated) {
        showSuccessMessage("User added successfully.");
        if (props.onSaved) props.onSaved();
        props.showState.setter(false);
      }
    } catch (e) {
      console.error("Error adding user", e);
    }
    setShowSpinner(false);
  };

  return (
    <SlideOutModal
      title="New User Data"
      {...(filteredProps as SlideOutModalProps)}
    >
      <FormWithValidation
        id={formName}
        onSubmit={handleSubmitForm}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div className="row">
          <div className="col col-12">
            <EmailWithValidation
              id="userName"
              required={true}
              onChangeText={(value: string) => setEmail(value)}
              initialValue={email}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextWithValidation
              id="firstName"
              label="First Name"
              required={true}
              onChangeText={(value: string) => setFirstName(value)}
              initialValue={firstName}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextWithValidation
              id="lastName"
              label="Last Name"
              required={true}
              onChangeText={(value: string) => setLastName(value)}
              initialValue={lastName}
            />
          </div>
        </div>
        <div className="mt-auto d-grid gap-2">
          <hr />
          <ButtonWithSpinner
            showSpinner={showSpinner}
            className="btn btn-primary w-100"
          >
            Save
          </ButtonWithSpinner>
          <Button
            type="button"
            className="btn btn-secondary w-100"
            variant="secondary"
            onClick={() => {
              props.showState.setter(false);
              props.onCancel?.();
            }}
          >
            Cancel
          </Button>
        </div>
      </FormWithValidation>
    </SlideOutModal>
  );
}

export default AddNewUserModal;
