import * as React from 'react';

import { FileService } from '../../../../services';
import '@progress/kendo-theme-default/dist/all.css';
import { Splitter, SplitterOnChangeEvent, BreadcrumbLinkMouseEvent } from '@progress/kendo-react-layout';
import { useInternationalization } from '@progress/kendo-react-intl';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { clone } from '@progress/kendo-react-common';
import { GridRowDoubleClickEvent, GridRowClickEvent, getSelectedState } from '@progress/kendo-react-grid';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { Offset } from '@progress/kendo-react-popup';

import { FileManagerToolbar } from './components/FileManagerToolbar';
import { GridView } from './components/GridView';
import { ListView } from './components/ListView';
import { FileInformation } from './components/FileInformation';
import { BreadcrumbComponent } from './components';
import { DeleteDialog, EditDialog } from './components/Dialog';

import {
  DataModel,
  PanesModel,
  SplitBtnItems,
  SelectedItemType,
  UploadAddEvent,
  ViewChangeEvent,
  SortChangeEvent,
  AppSwitchChangeEvent,
  ContextMenuEvent
} from './interfaces/FileManagerModels';
import {
  formatData,
  searchTreeItem,
  addFolder,
  convertExtensionToIcon,
  convertToBreadcrumbData,
} from './helpers/helperMethods';
import { ContextMenu } from './components/ContextMenu';

const splitterPanes: PanesModel[] = [
  {},
  {
    size: '30%',
    min: '20px',
    collapsible: true,
  },
];

const initialSort: SortDescriptor[] = [{
  field: 'path',
  dir: 'asc'
}];

const DATA_ITEM_KEY = 'path';

const FileManager = () => {
  const intl = useInternationalization();
  const contentRoot: string = "/";
  const fileService = new FileService(contentRoot);

  const [stateData, setStateData] = React.useState<DataModel[]>([]);

  const [panes, setPanes] = React.useState<PanesModel[]>(splitterPanes);

  const [currentPath, setCurrentPath] = React.useState<string>("/");
  const [selectedGridItem, setSelectedGridItem] = React.useState<DataModel>({});
  const [selectedTreeItem, setSelectedTreeItem] = React.useState<DataModel | null>(null);
  const [gridSelection, setGridSelection] = React.useState<{ [id: string]: boolean | number[]; }>({});

  const [detailsData, setDetailsData] = React.useState<null | number | Object>(null);
  const [files, setFiles] = React.useState<UploadFileInfo[]>([]);
  const [contentView, setContentView] = React.useState<string>('list');
  const [contextMenuView, setContextMenuView] = React.useState<boolean>(false);
  const [menuOffSet, setMenuOffSet] = React.useState<Offset>({ left: 0, top: 0 });
  const [editDialogView, setEditDialogView] = React.useState<boolean>(false);
  const [deleteDialogView, setDeleteDialogView] = React.useState<boolean>(false);

  const [selectedItemPath, setSelectedItemPath] = React.useState<string>('');

  const splitBtnItems: SplitBtnItems[] = [
    { text: 'Name', value: 'path' },
    { text: 'File Size', value: 'size' },
    { text: 'Date Created', value: 'dateCreated' }
  ];

  const initialLogic: 'and' | 'or' = 'and';

  const [stateContentData, setStateContentData] = React.useState<State>({
    sort: initialSort,
    filter: {
      logic: initialLogic,
      filters: [
        { field: 'path', operator: 'contains', value: '' }
      ]
    }
  });

  React.useEffect(() => {
    document.addEventListener('click', () => {
      setContextMenuView(false);
    });
    getContents(currentPath);
  }, []);

  React.useEffect(() => {
    getContents(currentPath);
  }, [currentPath]);


  React.useEffect(() => {
    setCurrentPath(selectedTreeItem?.path ?? "/");
  }, [selectedTreeItem]);

  const getContents = async (path?: string) => {
    try {
      const response = await fileService.getFilesAsync(path ?? "/");
      const data = formatData(response, intl);
      setStateData(data);
      return data;
    }
    catch(e){
      console.log(e);
    }
  };

  const updateFileDetailsData = React.useCallback(
    (selection: SelectedItemType | DataModel) => {
      if (selection.path) {
        const newSelectedItem = searchTreeItem(stateData, selection)
        setDetailsData(newSelectedItem);
      } else if (!selection.path && Object.keys(selection).length) {
        setDetailsData(Object.keys(selection).length);
      }
      else {
        setDetailsData(null);
      }
    },
    [stateData]
  );

  const handleSplitterChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const handleSelectionChange = (event: GridRowClickEvent) => {
    console.debug("Selected Item: ", event.dataItem);
    setSelectedGridItem(event.dataItem);
    updateFileDetailsData(event.dataItem);
    if (event.dataItem.path) {
      stateData.forEach((item:any) => {
        item.selected = false;
      });
      event.dataItem.selected = true;
    }
  };

  const handleListSelectionChange = (item: DataModel) => {
    setSelectedGridItem(item);
    updateFileDetailsData(item);
  };


  const handleSortChange = (event: SortChangeEvent) => {
    const newSortedData = clone(stateContentData);

    if (event.direction === 'asc' || event.direction === 'desc') {
      newSortedData.sort[0].dir = event.direction;
    }

    if (event.item) {
      newSortedData.sort[0].field = event.item.value;
    }

    if (event.sort) {
      newSortedData.sort = event.sort;
    }
    setStateContentData(newSortedData);
  };

  const handleGridDoubleClick = (event: GridRowDoubleClickEvent) => {
    const itemType = convertExtensionToIcon(event.dataItem.path);
    if (itemType?.type === 'Folder') {

      const newSelectedItem = event.dataItem;
      newSelectedItem.expanded = true;
      getContents(newSelectedItem.path);
      setSelectedTreeItem(searchTreeItem(stateData, newSelectedItem));
    }
  };

  const handleNewFolderClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    addFolder(stateData, intl, currentPath, () => getContents(currentPath));
  };

  const handleSwitchChange = (event: AppSwitchChangeEvent) => {
    const newPanes: PanesModel[] = panes.slice(0)
    if (event.value) {
      newPanes[1].size = '30%';
      setPanes(newPanes)
    } else {
      newPanes[1].size = '0%';
      setPanes(newPanes)
    }
  };

  const handleViewChange = (event: ViewChangeEvent) => {
    if (event.viewValue.gridView) {
      setContentView('grid');
    }
    if (event.viewValue.listView) {
      setContentView('list');
    }
  };

  const handleFileChange = (event: UploadAddEvent) => {
    if (event.files) {
      setFiles(event.files);
    }
  };

  const handleClearFileList = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      setFiles([]);
    }
  };

  const handleUploadComplete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    getContents(currentPath);
    setFiles([]);
  };


  const handleBreadcrumbSelection = (event: BreadcrumbLinkMouseEvent) => {
    const newPath = "/" + event.id?.split('/').slice(1).join('/');
    setCurrentPath(newPath);
  };

  const handleDialogClick = (event: any) => {
    if (event.type === 'cancel') {
      setDeleteDialogView(false);
      setEditDialogView(false);
    }

    if (event.type === 'delete') {
      const deleteItem = async (path: string) => {
        try {
          await fileService.deleteItemAsync(path);
          
        } catch (error){
          console.error(error);
        }
        await getContents(currentPath);
      }

      deleteItem(selectedItemPath);

      setDeleteDialogView(false);
    }

    if (event.type === 'rename') {
      const newPath = `${currentPath}/${event.value}`

      // check if item with same name exits
      const existingItem: DataModel = searchTreeItem(stateData, { path: newPath });
      if (!existingItem) {
        // rename the item using the api
        const renameItem = async (path: string, name: string, extension?: string) => {
          try {
            await fileService.editItemAsync(path, name);
          } catch (error){
            console.error(error);
          }
          await getContents(currentPath);
        }

        renameItem(selectedItemPath, event.value);
      }
      setEditDialogView(false);

    }
  };

  const handleListItemClick = (event: any) => {
    let newSelectedGridItem = {};
    if (event.dataItem.path) {
      stateData.forEach((item:any) => {
        item.selected = false;
      });

      setSelectedItemPath(event.dataItem.path);
      const newSelectedGridItem: { [key: string]: any } = event.dataItem;
      newSelectedGridItem[event.dataItem.path] = true;
      newSelectedGridItem['selected'] = true;
      console.debug('Selected item', newSelectedGridItem);

      setSelectedGridItem(newSelectedGridItem);
      if (event.dataItem.path) {
        const key = event.dataItem.path.toString();
        setGridSelection({ [key]: true });
      }

    }

    if (event.tree) {
      const newSelectedTreeItem: DataModel = searchTreeItem(stateData, newSelectedGridItem);
      setSelectedTreeItem(newSelectedTreeItem);
    }
    updateFileDetailsData(newSelectedGridItem);
  };

  const handleContextMenuView = (event: ContextMenuEvent) => {
    setContextMenuView(true);
    setMenuOffSet({ left: event.event.clientX, top: event.event.clientY });
    handleListItemClick(event);
  };

  const handleContextMenuClick = (event: ContextMenuEvent) => {
    switch (event.itemId) {
      case '0':
        // copy the path of the selected item to the clipboard
        navigator.clipboard.writeText(selectedItemPath);
        break;
      case '1':
        setEditDialogView(true);
        break;
      case '2':
        setDeleteDialogView(true);
        break;
    }
  };

  const handleDialogClose = () => {
    setDeleteDialogView(false);
    setEditDialogView(false);
  };

  

  return (
    <div className='k-widget k-filemanager k-filemanager-resizable h-100'>
      <div className='k-filemanager-header'>
        <FileManagerToolbar
          splitItems={splitBtnItems}
          sort={stateContentData.sort}
          files={files}
          onNewFolderClick={handleNewFolderClick}
          onSwitchChange={handleSwitchChange}
          onViewChange={handleViewChange}
          onSortChange={handleSortChange}
          onFileChange={handleFileChange}
          onClearFileList={handleClearFileList}
          onUploadComplete={handleUploadComplete}
          path={currentPath}
        />
      </div>
      <div className='k-filemanager-content-container'>
        <Splitter
          panes={panes}
          onChange={handleSplitterChange}
        >
          <div className='k-filemanager-content' style={{userSelect:"none"}}>
            <BreadcrumbComponent
              data={convertToBreadcrumbData(`VRCore${currentPath}`)}
              path={currentPath}
              onBreadcrumbSelect={handleBreadcrumbSelection}
            />
            {contentView === 'grid'
              ? <GridView
                data={stateData}
                dataItemKey={DATA_ITEM_KEY}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
                onDoubleClick={handleGridDoubleClick}
                onContextMenu={handleContextMenuView}
              />
              : <ListView
                data={stateData}
                onSelectionChange={handleListSelectionChange}
                onItemClick={handleListItemClick}
                onDoubleClick={handleGridDoubleClick}
                onContextMenu={handleContextMenuView}
              />
            }
            {contextMenuView ? <ContextMenu offset={menuOffSet} onContextMenuCLick={handleContextMenuClick} /> : ''}
            {editDialogView
              ? <EditDialog
                editValue={Object.keys(selectedGridItem).length ? selectedGridItem : selectedTreeItem}
                onDialogClose={handleDialogClose}
                onDialogClick={handleDialogClick}
              />
              : ''}
            {deleteDialogView ? <DeleteDialog onDialogClose={handleDialogClose} onDialogClick={handleDialogClick} /> : ''}
          </div>
          <FileInformation data={detailsData} />
        </Splitter>
      </div>
    </div>
  );
}

export default FileManager;
