import { reorderIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";

export const DragHandleCell = () => {
  return (
    <td
      style={{
        touchAction: "none",
      }}
    >
      <span
        style={{
          cursor: "move",
        }}
        data-drag-handle="true"
      >
        <SvgIcon
          style={{
            pointerEvents: "none",
          }}
          icon={reorderIcon}
        />
      </span>
    </td>
  );
};

export default DragHandleCell;
