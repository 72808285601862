import React from "react";
import { NavigationItem } from "./";
import { routes } from "../../app/routes";
import UserService from "../../services/UserService";
import "./SideBar.scss";
import ActionItem from "../actionItem/ActionItem";
import { IconDefinitions } from "../icons";
import { Roles } from "../../models";

interface SideBarProps extends React.PropsWithChildren {
  showToggle: boolean
  onToggle?: () => void
}

export function SideBar(props: SideBarProps) {
  const menuToggle = (e: any) => {
    props.onToggle?.call(null);
  };

  return (
    <div className="sidebar">
      <ul className="nav flex-column">
        {!props.showToggle ? <></> :
          <NavigationItem
            additionalClass="sidebar-toggle"
            text="Main Menu"
            url="#"
            onClick={menuToggle}
            icon={IconDefinitions.menu}
          />
        }
        {props.children ?? <>
          <NavigationItem
            text="Dashboard"
            url={routes.home}
            icon={IconDefinitions.home}
            activeMatchUrl={routes.home}
          />
          
          <NavigationItem
            text="My Profile"
            url={routes.manage}
            icon={IconDefinitions.user}
            activeMatchUrl={[routes.manage, routes.changePassword]}
          />
          <ActionItem
            text="Sign out"
            icon={IconDefinitions.signOut}
            additionalClass="text-nowrap"
            onClick={() => {
              UserService.logout();
            }}
          />
          </>}
      </ul>
      {props.children ? <></> : <>{
        UserService.isInRole(Roles.admin) ? (
          <ul className="nav flex-column mb-2 mt-auto">
          </ul>
      ) : null}
        {UserService.isInRole(Roles.admin) ? (
          <ul className="nav flex-column mb-2 bg-light border-top">
            <NavigationItem
              text="Catalog"
              url={routes.catalog}
              icon={IconDefinitions.book}
              activeMatchUrl={[
                routes.catalog,
                routes.catalogLessons,
                routes.catalogActivities,
              ]}
            />
            <NavigationItem
              text="Blogs"
              url={routes.blogs}
              icon={IconDefinitions.blog}
              activeMatchUrl={[
                routes.blogs
              ]}
            />
            <NavigationItem
              text="Files"
              url={routes.files}
              icon={IconDefinitions.file}
              activeMatchUrl={[
                routes.files
              ]}
            />
            <NavigationItem
              text="Users"
              url={routes.user}
              icon={IconDefinitions.users}
              activeMatchUrl={[
                routes.user, 
                routes.userDetails, 
                routes.userSecurity, 
                routes.userDelete, 
                routes.userInvitations, 
                routes.userInsertInvitation
              ]}
            />
            <NavigationItem
              text="Settings"
              url={routes.settings}
              icon={IconDefinitions.settings}
              activeMatchUrl={[
                routes.settings,
                routes.settingsDiscipline,
                routes.settingsLevel,
                routes.settingsSkill,
              ]}
            />
            {/* <NavigationItem
              text="Activity logs"
              url={routes.log}
              icon={IconDefinitions.logs}
              activeMatchUrl={routes.log}
            /> */}
          </ul>
        ) : null}
      </>}
    </div>
  );
}

export default SideBar;

