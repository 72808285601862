import React, { useState } from 'react';
import { Submenu, SubmenuNavigationItem } from '../../../components';

interface ManageDisciplineSubmenuProps {
  onChangeTab: (tab: string) => void;
}

export const ManageDisciplineSubmenu: React.FC<ManageDisciplineSubmenuProps> = ({ onChangeTab }) => {
  const [activeTab, setActiveTab] = useState<string>('Details');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    onChangeTab(tab);
  };

  const items: SubmenuNavigationItem[] = [
    {
      label: 'Details',
      url: '',
      clickHandler: () => handleTabChange('Details'),
      ifActive: activeTab === 'Details' ? 'active' : '',
    },
    {
      label: 'Subdisciplines',
      url: '',
      clickHandler: () => handleTabChange('Subdisciplines'),
      ifActive: activeTab === 'Subdisciplines' ? 'active' : '',
    },
  ]

  return (
    <Submenu items={items} />
  );
};

export default ManageDisciplineSubmenu;
