import { LevelModel, ODataResponse } from "../data/entities";
import { LevelRepo } from "../data/repo";

export class LevelService {

  async getLevelsAsync(oDataQuery: string = ""): Promise<ODataResponse<LevelModel>> {
    return await new LevelRepo().getLevelsAsync(oDataQuery);
  }

  async addLevelAsync(level: LevelModel): Promise<boolean | null> {
    return await new LevelRepo().addLevelAsync(level);
  }

  async editLevelAsync(level: LevelModel): Promise<boolean | null> {
    return await new LevelRepo().editLevelAsync(level);
  }

  async deleteLevelByIdAsync(id: number): Promise<boolean | null> {
    return await new LevelRepo().deleteLevelByIdAsync(id);
  }
}
