import { router, routes } from "../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function getLessonBreadcrumbs(location: string, lessonId: number): BreadcrumbProps {
  const idMap = new Map([["Id", lessonId.toString()]]);

  switch (location) {
    case router.getUrl(routes.lessonDetails, idMap):
      return {
        showHome: true,
        values: [
          { label: "Catalog", url: routes.catalog },
          {
            label: "Lessons",
            url: routes.catalogLessons
          },
          { label: "Details", url: null }
        ],
      };
      case router.getUrl(routes.lessonLevels, idMap):
        return {
          showHome: true,
          values: [
            { label: "Catalog", url: routes.catalog },
            {
              label: "Lessons",
              url: routes.catalogLessons
            },
            { label: "Levels", url: null }
          ],
        };
        case router.getUrl(routes.lessonActivities, idMap):
          return {
            showHome: true,
            values: [
              { label: "Catalog", url: routes.catalog },
              {
                label: "Lessons",
                url: routes.catalogLessons
              },
              { label: "Activities", url: null }
            ],
          };
          case router.getUrl(routes.lessonRegions, idMap):
            return {
              showHome: true,
              values: [
                { label: "Catalog", url: routes.catalog },
                {
                  label: "Lessons",
                  url: routes.catalogLessons
                },
                { label: "Regions", url: null }
              ],
            };
            case router.getUrl(routes.lessonMedia, idMap):
              return {
                showHome: true,
                values: [
                  { label: "Catalog", url: routes.catalog },
                  {
                    label: "Lessons",
                    url: routes.catalogLessons
                  },
                  { label: "Media", url: null }
                ],
              };
    default:
      return { showHome: false, values: [] };
  }
}

export default getLessonBreadcrumbs;

