import { ActivityModel } from '../../../../../data/entities';
import {
  IconDefinitions,
  BlockButton,
  IconAndText,
  ReorderContextProps,
  DraggableRow,
  DragHandleCell,
  createReorderContext
} from '../../../../../components';
import { ActivityService, LessonService, UrlService } from '../../../../../services';
import { showSuccessMessage } from '../../../../../app/tools';
import { useSharedState } from '../../../../../app/sharedProperty';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { usePageActions } from '../../../../PageContent';
import ManageActivitiesModal from './ManageActivitiesModal';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DragAndDrop } from "@progress/kendo-react-common";

export const ActivityReorderContext = createReorderContext<ActivityModel>({
  reorder: () => { },
  dragStart: () => { },
});

export function UpdateLessonActivities() {
  
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  // const [lesson, setLesson] = useState<LessonModel>();
  const [activities, setActivities] = useState<ActivityModel[]>();
  const [activeItem, setActiveItem] = React.useState<ActivityModel | null>(null);
  
  const showManageState = useSharedState(false);

  const fetchActivities = useCallback(async () => {
    try {
      const loadedData = await new ActivityService().getActivitiesByLessonAsync(id);
      if (loadedData) {
        setActivities(loadedData ?? []);
      }
    } catch (e) {
      console.error("Error loading activities", e);
    };
  }, [id]);
  
  usePageActions(
    <BlockButton onClick={() => { showManageState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.edit} text="Manage Activities" />
    </BlockButton>
  );

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const updateActivitiesAsync = async (selectedActivities: number[]) => {
    try {
      await new LessonService().updateActivitiesAsync(id, selectedActivities);
      fetchActivities();
      showSuccessMessage(`Activities updated successfully.`);
    }
    catch (err) {
      throw new Error(`Unable to update activities: ${err}`);
    }
  };

  const updateActivitySortOrder = async (activities: ActivityModel[]) => {
    try {
      const activityIds = activities.map((a) => a.id);
      await new LessonService().updateActivitySortOrderAsync(id, activityIds);
    }
    catch (err) {
      throw new Error(`Unable to update activities: ${err}`);
    }
  };

  const reorder = (dataItem: ActivityModel, direction: "before" | "after" | null) => {
    if (activeItem === dataItem) {
      return;
    }
    let reorderedData = activities!.slice();
    let prevIndex = reorderedData.findIndex((p) => p === activeItem);
    let nextIndex =
      reorderedData.findIndex((p) => p === dataItem) +
      (direction === "before" ? -1 : 0);
    if (prevIndex > nextIndex) {
      nextIndex++;
    }
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem || reorderedData[0]);

    setActivities(reorderedData);
    updateActivitySortOrder(reorderedData);
  };

  const dragStart = (dataItem: any) => {
    setActiveItem(dataItem);
  };


  if (activities === undefined) return null;
  if (activities === null) return null;
  return (
    <ActivityReorderContext.Provider value={{ reorder: reorder, dragStart: dragStart }}>
      <DragAndDrop>
        <Grid
          data={activities}
          dataItemKey="id"
          rowRender={(row, rowProps) => (
            <DraggableRow elementProps={row.props} context={ActivityReorderContext} {...rowProps} />
          )}
          scrollable='none'
        >
          <GridColumn title="" width="50px" cell={DragHandleCell} />
          <GridColumn field="name" title="Name" />
          <GridColumn field="code" title="Code" />

        </Grid>
        <ManageActivitiesModal
          showState={showManageState}
          assignedActivities={activities}
          onSubmit={updateActivitiesAsync}
        />
      </DragAndDrop>
    </ActivityReorderContext.Provider>
  )
}

export default UpdateLessonActivities
