import BaseRepository from "./BaseRepository";
import { FileModel } from "../entities";

export class FileRepo extends BaseRepository {
  async getFilesAsync(target: string, contentRoot:string = "/"): Promise<FileModel[]> {
    const url = `/file?target=${target}&contentRoot=${contentRoot}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<FileModel[]>(response);
    return handledResponse!;
  }

  async createDirectoryAsync(path: string, name: string): Promise<void> {
    // make name and path from form data
    const url = `/file`;
    const formData = new FormData();
    formData.append("path", path);
    formData.append("name", name);
    const response = await this.client.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    this.handleResponse<void>(response);
  }

  async deleteItemAsync(path: string): Promise<void> {
    const url = `/file?path=${path}`;
    const response = await this.client.delete(url);
    this.handleResponse<void>(response);
  }

  async editItemAsync(path: string, name: string): Promise<void> {
    const url = `/file`;
    const formData = new FormData();
    formData.append("path", path);
    formData.append("name", name);
    const response = await this.client.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    this.handleResponse<void>(response);
  }
}
