import { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ButtonWithSpinner,
  Card,
  CardBody,
  CardTitle,
  SimpleCard,
  SpinnerDefault,
  TextWithValidation,
} from "../../components";
import { UrlService, UserService } from "../../services";
import { UserDetailsModel } from "../../data/entities";
import FormWithValidation from "../../components/form/FormWithValidation";
import { showErrorMessage, showSuccessMessage } from "../../app/tools";

export function UserProfileCard() {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState<UserDetailsModel>();

  /**
   * Load the user details.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new UserService().getUserById(id);
        setData(loadedData!);
      } catch (e) {
        console.error("Error loading user details", e);
      }
    };
    fetchData();
  }, [id]);

  /**
   * Handle the form field changes.
   *
   * @param fieldName
   * @param value
   */
  const handleFieldChange = (fieldName: string, value: any) => {
    setData((current) => ({
      ...(current as UserDetailsModel),
      [fieldName]: value,
    }));
  };

  /**
   * Handle the update user details button click.
   */
  const handleSubmitForm = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    setShowSpinner(true);
    try {
      const isUpdated = await new UserService().updateUserDetails(id, data!);
      if (isUpdated) {
        showSuccessMessage(
          `User profile for ${data?.userName} updated successfully.`
        );
      }
    } catch (e) {
      console.error("Error updating user profile", e);
    }
    setShowSpinner(false);
  };

  if (data === undefined) return <SpinnerDefault />;

  if (data === null) {
    return (
      <SimpleCard title="Failed to load data.">
        <div>
          <em>No Profile data for this User.</em>
        </div>
      </SimpleCard>
    );
  }

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Update user profile details</h6>
      </CardTitle>
      <CardBody>
        <FormWithValidation onSubmit={handleSubmitForm}>
          <div className="row">
            <div className="col col-12">
              <TextWithValidation
                id="userName"
                label="Username"
                initialValue={data.userName}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TextWithValidation
                id="firstName"
                label="First Name"
                initialValue={data.firstName}
                onChangeText={(value: string) =>
                  handleFieldChange("firstName", value)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TextWithValidation
                id="lastName"
                label="Last Name"
                initialValue={data.lastName}
                onChangeText={(value: string) =>
                  handleFieldChange("lastName", value)
                }
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-1">
              <ButtonWithSpinner
                showSpinner={showSpinner}
                disabled={data === undefined}
              >
                Save
              </ButtonWithSpinner>
            </div>
          </div>
        </FormWithValidation>
      </CardBody>
    </Card>
  );
}

export default UserProfileCard;

