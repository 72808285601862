import { Card, CardBody, CardTitle } from '../../../../../components'
import { UpdateActivityDetails } from './UpdateActivityDetails';
import { ActivityService } from '../../../../../services';
import { ActivityModel } from '../../../../../data/entities';
import { showSuccessMessage } from '../../../../../app/tools';

export function ActivityDetails() {

  const activityService = new ActivityService();

  const editActivity = async (activity: ActivityModel) => {
    try{
      await activityService.editActivityAsync(activity);
      showSuccessMessage("Activity updated successfully.");
    } catch (err) {
      throw new Error(`Unable to edit activity: ${err}`);
    }
  };

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Edit Activity Details</h6>
      </CardTitle>
      <CardBody>
        <UpdateActivityDetails onSubmit={editActivity} />
      </CardBody>
    </Card>
  )
}

export default ActivityDetails
