import { DisciplineModel, ODataResponse, SubdisciplineModel } from "../data/entities";
import { DisciplineRepo } from "../data/repo";

export class DisciplineService {

  // #region Disciplines
  async getDisciplinesAsync(oDataQuery: string = ""): Promise<ODataResponse<DisciplineModel>> {
    return await new DisciplineRepo().getDisciplinesAsync(oDataQuery);
  }

  async addDisciplineAsync(discipline: DisciplineModel): Promise<boolean | null> {
    return await new DisciplineRepo().addDisciplineAsync(discipline);
  }

  async editDisciplineAsync(discipline: DisciplineModel): Promise<boolean | null> {
    return await new DisciplineRepo().editDisciplineAsync(discipline);
  }

  async deleteDisciplineByIdAsync(id: number): Promise<boolean | null> {
    return await new DisciplineRepo().deleteDisciplineByIdAsync(id);
  }
  // #endregion

  // #region Subdisciplines
  async getSubdisciplinesAsync(oDataQuery: string = ""): Promise<ODataResponse<SubdisciplineModel> | null> {
    return await new DisciplineRepo().getSubdisciplinesAsync(oDataQuery);
  }

  async addSubdisciplineAsync(subdiscipline: SubdisciplineModel): Promise<boolean | null> {
    return await new DisciplineRepo().addSubdisciplineAsync(subdiscipline);
  }

  async editSubdisciplineAsync(subdiscipline: SubdisciplineModel): Promise<boolean | null> {
    return await new DisciplineRepo().editSubdisciplineAsync(subdiscipline);
  }

  async deleteSubdisciplineByIdAsync(id: number): Promise<boolean | null> {
    return await new DisciplineRepo().deleteSubdisciplineByIdAsync(id);
  }
  // #endregion

}
