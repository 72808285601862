import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Icon,
  IconAndText,
  IconDefinitions,
  SimpleCard,
  SpinnerDefault,
} from "../../components";
import { UrlService, UserService } from "../../services";
import { SecurityModel, UserDetailsModel } from "../../data/entities";
import { useParams } from "react-router-dom";
import { useSharedState } from "../../app/sharedProperty";
import { SlideOutModal } from "../../components/modals/SlideOutModal";
import { Button } from "react-bootstrap";
import { Dropdown, Option } from "../../components/form";
import { Roles } from "../../models";
import { showSuccessMessage } from "../../app/tools";
import { usePageActions } from "../PageContent";

interface AddRoleFormModel {
  role: string;
}
export function UserSecurityCard() {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [assignedRoles, setAssignedRoles] = useState<string[] | null>();
  const [options, setOptions] = useState<Option[]>([]);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [data, setData] = useState<SecurityModel | null>();
  const [formData, setFormData] = useState<AddRoleFormModel>();
  const showSelectModalState = useSharedState(false);
  const [userData, setUserData] = useState<UserDetailsModel | undefined | null>(
    undefined
  );

  usePageActions(
    <Button
      onClick={() => {
        showSelectModalState.setter(true);
      }}
    >
      <IconAndText iconName={IconDefinitions.add} text={"Add Role"} />
    </Button>
  );

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    const fetchData = async () => {
      new UserService()
        .getUserById(id)
        .then((data) => {
          setUserData(data);
        })
        .catch((e) => {
          console.error("Error loading user details", e);
        });

      try {
        const loadedData = await new UserService().getUserSecurity(id);
        setData(loadedData);
        setAssignedRoles(Object.values(loadedData!.roles ?? []));
      } catch (e) {
        console.error("Error loading user security", e);
      }
    };
    fetchData();
  }, [id]);

  /**
   * Updates the available roles when the assigned roles change.
   */
  useEffect(() => {
    setAvailableRoles(
      Object.values(Roles).filter((role) => {
        return !assignedRoles?.includes(role);
      })
    );
  }, [assignedRoles]);

  /**
   * Updates the options when the available roles change.
   */
  useEffect(() => {
    setOptions(
      availableRoles.map((role) => {
        return { value: role, text: role };
      })
    );
    setFormData({
      role: availableRoles.length > 0 ? availableRoles[0] : "",
    });
  }, [availableRoles]);

  /**
   * Adds a role to the user
   * @returns
   */
  const addRoleHandler = async () => {
    const ok = await new UserService().addUserSecurity(
      userData!,
      formData!.role
    );
    console.log(ok);
    if (!ok) {
      return;
    } else {
      setAssignedRoles([...assignedRoles!, formData!.role]);
      showSuccessMessage(`Added ${formData!.role} access to ${data!.userName}`);
      const loadedData = await new UserService().getUserSecurity(id);
      setData(loadedData);
    }
  };

  /**
   * Deletes an invitation.
   * @param id Id of the invitation to delete
   * @returns
   */
  const deleteRoleHandler = async (id: number, role: string) => {
    try {
      const ok = await new UserService().removeUserSecurity(userData!, role);
      if (!ok) {
        return;
      } else {
        setAssignedRoles(assignedRoles?.filter((r) => r !== role));
        showSuccessMessage(`Removed ${role} access from ${data!.userName}`);
      }
      const loadedData = await new UserService().getUserSecurity(id);
      setData(loadedData);
    } catch (e) {
      console.error("Error deleting invitation", e);
    }
  };

  /**
   * Handle the form field changes.
   *
   * @param fieldName
   * @param value
   */
  const handleFieldChange = (fieldName: string, value: string) => {
    setFormData((current) => ({
      ...(current as AddRoleFormModel),
      [fieldName]: value,
    }));
  };

  if (data === undefined) return <SpinnerDefault />;

  if (data === null) {
    return (
      <SimpleCard title="Failed to load data.">
        <div>
          <em>No Security data for this User.</em>
        </div>
      </SimpleCard>
    );
  }

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Modify this user's security access.</h6>
      </CardTitle>
      <CardBody>
        <table className="table hover-row mt-3">
          <thead>
            <tr>
              <th scope="col">Role</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {Object.values(data.roles!).map((role) => {
              return (
                <tr key={role}>
                  <td>{role}</td>
                  <td className="text-end">
                    <Icon
                      onClick={() => {
                        deleteRoleHandler(data?.id!, role);
                      }}
                      className="text-danger cursor-pointer"
                      iconName={IconDefinitions.delete}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <SlideOutModal
          showState={showSelectModalState}
          title={"Add Role"}
          footer={
            <>
              <Button
                onClick={() => {
                  showSelectModalState.setter(false);
                }}
                variant="primary"
                className="w-100"
              >
                Cancel
              </Button>
              {availableRoles.length > 0 ? (
                <Button
                  onClick={addRoleHandler}
                  variant="secondary"
                  className="w-100"
                >
                  Save
                </Button>
              ) : null}
            </>
          }
        >
          {availableRoles.length > 0 ? (
            <Dropdown
              id="security"
              label="Roles"
              options={options}
              selectedOption={
                availableRoles.length > 0 ? availableRoles[0] : ""
              }
              onChangeSelected={(value) =>
                handleFieldChange("role", value.toString())
              }
            />
          ) : (
            <div className="text-center alert alert-info">
              <p>
                There are no additional roles that can be added for this user.
              </p>
            </div>
          )}
        </SlideOutModal>
      </CardBody>
    </Card>
  );
}

export default UserSecurityCard;



