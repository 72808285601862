import { ODataResponse, SkillModel } from "../entities";
import BaseRepository from "./BaseRepository";

export class SkillRepo extends BaseRepository{

  
  async getSkillsAsync(oDataQuery: string = ""): Promise<ODataResponse<SkillModel>> {
    const url = `odata/Skills/?${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<SkillModel>>(response);
    return handledResponse!;
  };

  async addSkillAsync(skill: SkillModel): Promise<boolean | null> {
    const url = `Skill`;
    const response = await this.client.post(url, skill);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  };

  async editSkillAsync(skill: SkillModel): Promise<boolean | null> {
    const url = `Skill/${skill.id}`;
    const response = await this.client.put(url, skill);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteSkillByIdAsync(id: number): Promise<boolean | null> {
    const url = `Skill/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

}
