import { DisciplineModel, ODataResponse, SubdisciplineModel } from "../entities";
import BaseRepository from "./BaseRepository";

export class DisciplineRepo extends BaseRepository {

  // #region Disciplines
  async getDisciplinesAsync(oDataQuery: string = ""): Promise<ODataResponse<DisciplineModel>> {
    const url = `odata/Disciplines/?${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<DisciplineModel>>(response);
    return handledResponse!;
  };

  async addDisciplineAsync(discipline: DisciplineModel): Promise<boolean | null> {
    const url = `Discipline`;
    const response = await this.client.post(url, discipline);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  };

  async editDisciplineAsync(discipline: DisciplineModel): Promise<boolean | null> {
    const url = `Discipline/${discipline.id}`;
    const response = await this.client.put(url, discipline);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteDisciplineByIdAsync(id: number): Promise<boolean | null> {
    const url = `Discipline/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }
  // #endregion

  // #region Subdisciplines
  async getSubdisciplinesAsync(oDataQuery: string = ""): Promise<ODataResponse<SubdisciplineModel> | null> {
    const url = `odata/Subdisciplines/${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<SubdisciplineModel>>(response);
    return handledResponse;
  }

  async addSubdisciplineAsync(subdiscipline: SubdisciplineModel): Promise<boolean | null> {
    const url = `Subdiscipline`;
    const response = await this.client.post(url, subdiscipline);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async editSubdisciplineAsync(subdiscipline: SubdisciplineModel): Promise<boolean | null> {
    const url = `Subdiscipline/${subdiscipline.id}`;
    const response = await this.client.put(url, subdiscipline);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteSubdisciplineByIdAsync(id: number): Promise<boolean | null> {
    const url = `Subdiscipline/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  // #endregion
}
