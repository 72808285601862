import { routes, router } from "../../../app/routes";
import { useParams } from "react-router-dom";
import SubmenuSideBar from "../../../components/sideBar/SubmenuSideBar";
import { LessonService, UrlService } from "../../../services";
import { Button } from "react-bootstrap";
import { useSharedState } from "../../../app/sharedProperty";
import { SlideOutModal } from "../../../components/modals/SlideOutModal";
import { showSuccessMessage } from "../../../app/tools";

export function LessonSidebar() {

  const urlParams = useParams();
  const lessonId = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const showSelectModalState = useSharedState(false);

  const selectModalCancelClick = () => {
    showSelectModalState.setter(false);
  };

  const deleteSelectClick = async () => {
    const ok = await deleteLesson(lessonId);
    console.log(ok);
    if(!ok){return}
    else{
      showSuccessMessage(`Lesson deleted`);
    };
    router.navigate(routes.catalogLessons).catch((error) => {});
  };

  async function deleteLesson(id: number) {
    await new LessonService().deleteLessonByIdAsync(id);
    return true;
  }


  const menuItems = [
    {
      text: 'Details',
      url: router.getUrl(routes.lessonDetails, new Map([
        ['Id', lessonId.toString()]
      ])),
      activeMatchUrl: routes.lessonDetails,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Activities',
      url: router.getUrl(routes.lessonActivities, new Map([
        ['Id', lessonId.toString()]
      ])),
      activeMatchUrl: routes.lessonActivities,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Levels',
      url: router.getUrl(routes.lessonLevels, new Map([
        ['Id', lessonId.toString()]
      ])),
      activeMatchUrl: routes.lessonLevels,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Regions',
      url: router.getUrl(routes.lessonRegions, new Map([
        ['Id', lessonId.toString()]
      ])),
      activeMatchUrl: routes.lessonRegions,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Media',
      url: router.getUrl(routes.lessonMedia, new Map([
        ['Id', lessonId.toString()]
      ])),
      activeMatchUrl: routes.lessonMedia,
      additionalClass: "submenu-sidebar-nav-item",
    },
    {
      text: 'Delete',
      url: "",
      additionalClass: "submenu-sidebar-nav-item",
      clickHandler: () => showSelectModalState.setter(true),
    }
  ];

  return (
    <>
      <SubmenuSideBar menuItems={menuItems} />
      <SlideOutModal
        showState={showSelectModalState}
        title={"Delete Lesson"}
        footer={<>
          <Button onClick={selectModalCancelClick} variant="primary" className="w-100">
            Close
          </Button>
          <Button onClick={deleteSelectClick} variant="secondary" className="w-100">
            Delete
          </Button>
        </>}
      >
        Are you sure you want to delete this lesson?
      </SlideOutModal>
    </>
  );
}

export default LessonSidebar;
