import { Icon, IconDefinitions, SkillSelector } from '../../../../../../../components';
import { useState, useEffect } from 'react';
import { AssessmentService, SkillService } from '../../../../../../../services';
import { showSuccessMessage } from '../../../../../../../app/tools';
import { SkillModel, AssessmentModel } from '../../../../../../../data/entities';

interface AssessmentSkillsProps {
  assessmentId: number
}

export function AssessmentSkills({ assessmentId }: Readonly<AssessmentSkillsProps>) {

  const [skills, setSkills] = useState<Array<SkillModel>>();
  const [assessment, setAssessment] = useState<AssessmentModel>({} as AssessmentModel);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new AssessmentService().getAssessmentByIdAsync(assessmentId);
        if(loadedData) {
          setAssessment(loadedData ?? []);
        }
      } catch(e) {
        console.error("Error loading lesson", e);
      };
    };
    fetchData();
  }, [assessmentId]);

  useEffect(() => {
    setSkills(assessment?.skills ?? []);
  }, [assessment.skills]);

  const fetchAssessment = async () => {
    try {
      const loadedData = await new AssessmentService().getAssessmentByIdAsync(assessmentId);
      if(loadedData) {
        setAssessment(loadedData ?? []);
      }
    } catch(e) {
      console.error("Error loading assessment", e);
    };
  }

  const addSkill = async (skill: SkillModel) => {
    let newAssessment = {
      ...assessment,
      skills: [
        ...assessment.skills ?? [],
        skill
      ]
    }
    try {
      await new AssessmentService().editAssessmentAsync(newAssessment);
      fetchAssessment();
      showSuccessMessage(`Skill ${skill.name} added to ${assessment.code}`);
      // refresh assessment
    } catch (err) {
      throw new Error(`Unable to add skill to assessment, ${err}`);
    };
  };

  const removeSkill = async (skill: SkillModel) => {
    let updatedSkills = assessment.skills?.filter(s => s.id !== skill.id);
    let newAssessment = {
      ...assessment,
      skills: updatedSkills
    }

    try {
      await new AssessmentService().editAssessmentAsync(newAssessment);
      fetchAssessment();
      showSuccessMessage(`Skill ${skill.name} removed from ${assessment.code}`);
      // refresh assessment
    } catch (err) {
      throw new Error(`Unable to remove skill from assessment, ${err}`);
    };
  }

  console.log("Assessment: ", assessment);
  if (skills === undefined) return null;
  if (skills === null) return null;
  return (
    <>
      <table className="table hover-row mt-3">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {skills.map((skill) => (
            <tr key={skill.id}>
              <td>
                {skill.name}
              </td>
              <td>
                {skill.code}
              </td>
              <td>
                <Icon 
                  onClick={() => removeSkill(skill)}
                  className="mx-2 text-danger cursor-pointer"
                  iconName={IconDefinitions.delete}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SkillSelector
        assignedSkills={skills}
        clickHandler={addSkill}
      />
    </>
  )
}

export default AssessmentSkills
