import { TitlelessCard } from '../../../../../components'
import AssessmentsList from './AssessmentsList';

export function ActivityAssessments() {

  return (
    <TitlelessCard bodyClassName="p-0">
      <AssessmentsList />
    </TitlelessCard>
  )
}

export default ActivityAssessments
