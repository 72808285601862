import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";
import BlockButton from "../../components/form/buttons/BlockButton";
import { router, routes } from "../../app/routes";
import { RegisterModel } from "../../data/entities";
import { PasswordWithValidation } from "../../components";
import AccountService from "../../services/AccountService";
import { useParams } from "react-router-dom";
import { UrlService } from "../../services";

export interface RegisterFormValidationModel {
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
}

export function RegisterPage() {

  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");
  const key = UrlService.getParamValue("code", urlParams);

  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState<RegisterModel>({
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [isFormValid, setIsFormValid] = useState<RegisterFormValidationModel>();
  
  /**
   * Initialize the form data.
   */
  useEffect(() => {
    setIsFormValid({
      email: false,
      password: false,
      confirmPassword: false
    })
  }, [])

  /**
   * Handle the form field changes.
   *
   * @param fieldName
   * @param value
   */
  const handleFieldChange = (fieldName: string, value: any) => {
    setFormData((current) => ({
      ...current,
      [fieldName]: value,
    }));
  };

  /**
   * Checks if every field in the form is valid.
   * @returns boolean
   */
  function isButtonEnabled() {
    if (isFormValid) {
      const result = Object.values(isFormValid).every(
        (value) => value === true
      );
      return result;
    }
  }

  async function register() {
    setShowSpinner(true);
    const ok = await new AccountService().register(id, key ?? "", formData);
    setShowSpinner(false);

    if (!ok) return;
    router.navigate(routes.registerConfirmation);
  }
  
  return (
    <>
      <h2>Register</h2>
      <section>
        <h4 className="mb-4">Create your new account.</h4>
        <Form>
          <div className="row">
            <div className='col col-12'>
              <EmailWithValidation
                required={true}
                id="email"
                label="Email"
                onChangeText={(value) => handleFieldChange("email", value)}
                validationResult={(input, result) => {
                  setIsFormValid((current) => {                    
                    return {
                      ...current!,
                      email: result,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className='col col-12'>
              <PasswordWithValidation
                required={true}
                id="password"
                label="Password"
                onChangeText={(value) => handleFieldChange("password", value)}
                validationResult={(input, result) => {
                  setIsFormValid((current) => {
                    return {
                      ...current!,
                      password: result,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className='col col-12'>
              <PasswordWithValidation
                required={true}
                id="passwordConfirmation"
                label="Confirm Password"
                onChangeText={(value) => handleFieldChange("confirmPassword", value)}
                validationResult={(input, result) => {
                  setIsFormValid((current) => {
                    return {
                      ...current!,
                      confirmPassword: result && input === formData.password,
                    };
                  });
                }}
              />
            </div>
          </div>
          <BlockButton
            label="Submit"
            onClick={register}
            disabled={!isButtonEnabled()}
            showSpinner={showSpinner}
          ></BlockButton>
        </Form>
      </section>
    </>
  );
}

export default RegisterPage;
