import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  MultiLineTextWithValidation,
  SpinnerDefault,
  TextWithValidation,
  Toggle,
} from "../../../../../components";
import { LessonModel } from "../../../../../data/entities";
import { Button, Form } from "react-bootstrap";
import ModulePicker from "../../../../../components/form/dropdown/ModulePicker";
import SubdisciplinePicker from "../../../../../components/form/dropdown/SubdisciplinePicker";
import { useParams } from "react-router-dom";
import { LessonService, UrlService } from "../../../../../services";

interface LessonDetailsProps {
  onSubmit: (data: LessonModel) => void;
}

export function UpdateLessonDetails(props: Readonly<LessonDetailsProps>) {
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [formData, setFormData] = useState<LessonModel>();
  const [validated, setValidated] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new LessonService().getLessonByIdAsync(
          Number(id)!
        );
        if (loadedData) {
          setFormData(loadedData);
        }
      } catch (e) {
        console.error("Error loading activity", e);
      }
    };
    fetchData();
  }, [id]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, formData!);
      setValidated(false);
    } else {
      setValidated(false);
    }
  };

  /**
   * Handle the form field changes.
   *
   * @param fieldName
   * @param value
   */
  const handleFieldChange = (key: string, value: any) => {
    setFormData({ ...formData!, [key]: value });
  };

  if (formData === undefined)
    return (
      <div className="position-relative py-5">
        <SpinnerDefault />
      </div>
    );
  return (
    <>
      <Form
        ref={formRef}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div className="col-12">
          <TextWithValidation
            id="name"
            required={true}
            label="Name"
            initialValue={formData.name}
            onChangeText={(v) => handleFieldChange("name", v)}
          />
        </div>
        <div className="col-12">
          <TextWithValidation
            id="code"
            required={true}
            label="Code"
            initialValue={formData.code}
            onChangeText={(v) => handleFieldChange("code", v)}
          />
        </div>
        <div className="col-12 my-3">
          <Toggle
            id="comingSoon"
            description="Coming Soon"
            initialValue={formData.comingSoon}
            onChange={(v) => handleFieldChange("comingSoon", v)}
          />
        </div>
        <div className="col-12">
        <TextWithValidation
          id="priority"
          required={true}
          label="Priority"
          initialValue={formData.priority}
          onChangeText={(v) => handleFieldChange("priority", v)}
          />
        </div>
        <div className="col-12">
          <ModulePicker
            id="moduleId"
            label="Module"
            initialValue={`${formData.moduleId}`}
            onSelect={(v) => handleFieldChange("moduleId", v)}
          />
        </div>
        <div className="col-12">
          <SubdisciplinePicker
            id="subdisciplineId"
            label="Primary Subdiscipline"
            initialValue={`${formData.subdisciplineId}`}
            onSelect={(v) => handleFieldChange("subdisciplineId", v)}
          />
        </div>
        <div className="col-12">
          <MultiLineTextWithValidation
            id="preview"
            label="Preview"
            initialValue={formData.preview}
            onChangeText={(v) => handleFieldChange("preview", v)}
          />
        </div>
        <div className="col-12">
          <MultiLineTextWithValidation
            id="description"
            label="Description"
            initialValue={formData.description}
            onChangeText={(v) => handleFieldChange("description", v)}
          />
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      <Button
        onClick={() => formSubmitRef.current?.click()}
        variant="primary"
        className="w-auto"
      >
        Update
      </Button>
    </>
  );
}

export default UpdateLessonDetails;

