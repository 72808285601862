import React from 'react'
import './NumericDashboardItem.scss';


interface NumericDashboardItemProps {
  label: string;
  value: number;
  icon?: string;
  color?: string;
}

export function NumericDashboardItem({label, value, icon, color}: Readonly<NumericDashboardItemProps>) {
  return (
    <div className="numeric-dashboard-item">
      <i className={`${icon} icon`} style={{ color }}></i>
      <div className="numeric-dashboard-value">{value.toLocaleString()}</div>
      <div className="numeric-dashboard-label">{label}</div>
    </div>
  );
}

export default NumericDashboardItem


NumericDashboardItem.defaultProps = {
  label: 'Label',
  value: 0,
  icon: 'fa fa-question',
  color: 'black'
}
