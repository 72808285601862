import { FormEvent, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MultiLineTextWithValidation, SlideOutModal, SlideOutModalProps, TextWithValidation } from "../../../../../components";
import { SubdisciplineModel } from "../../../../../data/entities";

export interface AddSubdisciplineModalProps extends SlideOutModalProps {
  onSubmit: (data: any) => void;
  disciplineId: number;
}

export function AddSubdisciplineModal(props: Readonly<AddSubdisciplineModalProps>) {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    name: "",
    code: "",
    description: "",
    disciplineId: props.disciplineId,
  } as SubdisciplineModel);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, values);
      resetForm();
    } else {
      setValidated(false);
    }
  };

  const resetForm = () => {
    setValues({
      name: "",
      code: "",
      description: "",
      disciplineId: props.disciplineId,
    } as SubdisciplineModel);
    setValidated(false);
  }

  return (
    <SlideOutModal {...props}
      title={`Add New Subdiscipline`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Update
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>  
        <div className="col-12">
          <TextWithValidation
            id="name"
            required={true}
            label="Name"
            initialValue={values.name}
            onChangeText={(v) => setValues({ ...values, name: v })}
          /> 
        </div>
        <div className="col-12">
          <TextWithValidation
            id="code"
            required={true}
            label="Code"
            initialValue={values.code}
            onChangeText={(v) => setValues({ ...values, code: v })}
          />
        </div>
        <div className="col-12">
          <MultiLineTextWithValidation
            id="description"
            required={false}
            label="Description"
            initialValue={values.description}
            onChangeText={(v) => setValues({ ...values, description: v })}
          />
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default AddSubdisciplineModal;

