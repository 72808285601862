import { GridColumn, GridRowClickEvent } from "@progress/kendo-react-grid";
import { UserService } from "../../services";
import {
  BlockButton,
  IconAndText,
  IconDefinitions,
  ODataGrid,
  TitlelessCard,
} from "../../components";
import { router, routes } from "../../app/routes";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { usePageActions } from "../PageContent";
import AddNewUserModal from "./AddNewUserModal";
import { useSharedState } from "../../app/sharedProperty";
import { useRef } from "react";

export function UsersGrid() {
  const showAddState = useSharedState(false);
  const gridRef = useRef<ODataGrid>(null);

  usePageActions(
    <BlockButton
      onClick={() => {
        showAddState.setter(true);
      }}
    >
      <IconAndText iconName={IconDefinitions.add} text="Add New Account" />
    </BlockButton>
  );

  const getUsers = async (oDataQuery: string) => {
    const users = await new UserService().getUsers(oDataQuery);
    return users;
  };

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    switch (e.item.data.action) {
      case "open":
        router.navigateWithParams(
          routes.userDetails,
          new Map([["id", dataItem.id]])
        );
        break;
      case "opentab":
        router.navigateWithParams(
          routes.userDetails,
          new Map([["id", dataItem.id]]),
          true
        );
        break;
    }
  };

  const onRowClick = (e: GridRowClickEvent) => {
    router.navigateWithParams(
      routes.userDetails,
      new Map([["id", e.dataItem.id]])
    );
  };

  const onNewUserAdded = () => {
    gridRef.current?.refreshData();
  };

  return (
    <>
      <TitlelessCard className="p-0 m-0" bodyClassName="p-0">
        <ODataGrid
          ref={gridRef}
          getData={getUsers}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={onRowClick}
          sort={[{ field: "userName", dir: "asc" }]}
          scrollable="scrollable"
          fullHeight={true}
        >
          <GridColumn
            title="User"
            field="userName"
            sortable={true}
            filter="text"
          />
          <MenuItem
            text="Open"
            data={{ action: "open" }}
            icon="hyperlink-open"
          />
          <MenuItem
            text="Open Tab"
            data={{ action: "opentab" }}
            icon="windows"
          />
        </ODataGrid>
      </TitlelessCard>
      <AddNewUserModal showState={showAddState} onSaved={onNewUserAdded} />
    </>
  );
}

export default UsersGrid;

