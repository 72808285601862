import { useParams } from "react-router-dom";
import { routes } from "../../app/routes";
import {
  Submenu,
  IconDefinitions,
  SubmenuNavigationItem,
} from "../../components";
import { UrlService } from "../../services";
import { useEffect, useMemo, useState } from "react";

export const UsersSubmenu = (props: any) => {
  const params = useParams();
  const returnUrl = UrlService.getQueryParams("returnUrl");
  const id = params["id"] ?? "";

  const defaultSubmenuItems: SubmenuNavigationItem[] = useMemo(
    (): SubmenuNavigationItem[] => [
      {
        label: "Users",
        icon: IconDefinitions.users,
        url: routes.user,
        activeMatch: routes.user,
        activeMatchExact: true,
        params: new Map([["id", id]]),
      },      
    ],
    [id]
  );

  useEffect(() => {
    const backItem: SubmenuNavigationItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, id, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuNavigationItem[]>(defaultSubmenuItems);

  return <Submenu items={submenuItems}></Submenu>;
};

export default UsersSubmenu;

