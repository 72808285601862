import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { CustomDatePicker, HandleDatePickerChange, SlideOutModal, SlideOutModalProps } from "../../../../components";
import { useRef } from "react";
import BlogPicker from "../../../../components/form/dropdown/BlogPicker";
import * as yup from 'yup';
import { Formik } from 'formik';

interface AddArticleModalProps extends SlideOutModalProps {
  onSubmit: (data: any) => void;
}

export const AddArticleModal = (props: AddArticleModalProps) => {

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    blogId: yup.number().required("Blog is required"),
    showOnDate: yup.date().required('Show on date is required'),
  });

  const formSubmitRef = useRef<HTMLButtonElement>(null);
  
  return (
    <SlideOutModal {...props}
      title={`Add New Article`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Add
            </Button>
          </div>
        </div>
      </div>}>

        <Formik
          validationSchema={schema}
          initialValues={{
            title: "",
            blogId: "",
            showOnDate: new Date().toISOString(),
          }}
          onSubmit = { async (values, { setSubmitting }) => {
            props.onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {

            return(
              <Form onSubmit={handleSubmit} noValidate>
                
                <Row>
                  <Col>
                    <Form.Group controlId="title">
                      <FloatingLabel label="Title" className="mb-3">
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BlogPicker 
                      id="blogId"
                      label="Blog"
                      onSelect={(blogId) => {
                        setFieldValue('blogId', blogId);
                      }}
                      initialValue={values.blogId.toString()}
                      isInvalid={touched.blogId && !!errors.blogId}
                      feedback={errors.blogId}
                    />
                  </Col>
                </Row>
                <Button ref={formSubmitRef} type="submit" className="d-none" />
              </Form>
            );
          }}
        </Formik>
      {props.children}
    </SlideOutModal>
  )
}

export default AddArticleModal;
