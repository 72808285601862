import { LessonModel, RegionModel } from '../../../../../data/entities';
import { BlockButton, ConfirmationModal, Icon, IconAndText, IconDefinitions } from '../../../../../components';
import { LessonService, UrlService } from '../../../../../services';
import { showSuccessMessage } from '../../../../../app/tools';
import { useSharedState } from '../../../../../app/sharedProperty';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePageActions } from '../../../../PageContent';
import { ManageRegionsModal } from '.';


export function UpdateLessonRegions() {
  
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [lesson, setLesson] = useState<LessonModel>();
  const [regions, setRegions] = useState<Array<RegionModel>>();
  
  const showManageState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const deleteItemState = useSharedState<RegionModel>({} as RegionModel);

  usePageActions(
    <BlockButton onClick={() => { showManageState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.edit} text="Manage Regions" />
    </BlockButton>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedData = await new LessonService().getLessonByIdAsync(Number(id)!);
        if(loadedData) {
          setLesson(loadedData ?? []);
        }
      } catch(e) {
        console.error("Error loading lesson", e);
      };
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    setRegions(lesson?.regions ?? []);
  }, [lesson]);

  const fetchLesson = async () => {
    try {
      const loadedData = await new LessonService().getLessonByIdAsync(Number(id)!);
      if(loadedData) {
        setLesson(loadedData ?? []);
      }
    } catch(e) {
      console.error("Error loading lesson", e);
    };
  }


  const updateRegionsAsync = async (selectedRegionIds: number[]) => {
    if (lesson === undefined) return;
    try {
      await new LessonService().updateRegionsAsync(lesson.id, selectedRegionIds);
      fetchLesson();
      showSuccessMessage(`Regions updated successfully.`);
    }
    catch (err) {
      throw new Error(`Unable to update regions: ${err}`);
    }
  };

  const removeRegion = async (region: RegionModel) => {
    if (lesson === undefined) return;
    try {
      await new LessonService().removeRegionAsync(lesson.id, region.id);
      fetchLesson();
      showSuccessMessage("Region deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete level: ${err}`);
    } 
  }

  const showDelete = (dataItem: RegionModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }

  if (lesson === undefined) return null;
  if (lesson === null) return null;
  return (
    <>
      <table className="table hover-row mt-3">
        <thead>
          <tr>
            <th scope="col">Region</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {regions?.map((region) => (
            <tr key={region.id}>
              <td>
                {region.name}
              </td>
              <td className="text-right">
                <Icon 
                  onClick={() => showDelete(region)}
                  className="mx-2 text-danger cursor-pointer"
                  iconName={IconDefinitions.delete}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <ManageRegionsModal
        showState={showManageState}
        assignedRegions={regions}
        onSubmit={updateRegionsAsync}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Remove Level ${deleteItemState.value?.name}`}
        message={`You are about to remove this region. Are you sure you want to remove ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => removeRegion(deleteItemState.value)}
      />
    </>
  )
}

export default UpdateLessonRegions
