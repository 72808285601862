import React, { PropsWithChildren } from 'react';
import './DashboardCard.scss';

interface DashboardCardProps extends PropsWithChildren {
  title?: string;
}

export function DashboardCard({ children, title }: Readonly<DashboardCardProps>){

  return (
    <div className={`dashboard-card-container`}>
      {title && <h2 className="dashboard-title">{title}</h2>}
      <div className="dashboard-card py-4 px-0">
        {React.Children.map(children, (child) => (
          <div className="dashboard-item-container">
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardCard;
