import { useEffect, useMemo, useState } from "react";
import { routes } from "../../app/routes";
import { IconDefinitions, SubmenuNavigationItem } from "../../components";
import Submenu from "../../components/submenu/Submenu";
import { useParams } from "react-router-dom";
import { UrlService } from "../../services";

export function BlogsSubmenu() {
  const params = useParams();
  const returnUrl = UrlService.getQueryParams("returnurl");

  const defaultSubmenuItems: SubmenuNavigationItem[] = useMemo(
    (): SubmenuNavigationItem[] => [
      {
        label: "Blogs",
        icon: "",
        url: routes.blogs,
        activeMatch: routes.blogs,
        activeMatchExact: true
      },
      {
        label: "Articles",
        icon: "",
        url: routes.blogArticles,
        activeMatch: [routes.blogArticles],
        activeMatchExact: true
      },
      {
        label: "Authors",
        icon: "",
        url: routes.blogAuthors,
        activeMatch: routes.blogAuthors,
        activeMatchExact: true
      },
    ],
    []
  );

  useEffect(() => {
    const backItem: SubmenuNavigationItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuNavigationItem[]>(defaultSubmenuItems);

  return <Submenu items={submenuItems}></Submenu>;
}

export default BlogsSubmenu
