import { router, routes } from "../../../../app/routes";

interface BreadcrumbProps {
  showHome: boolean;
  values: { label: string; url: string | null }[];
}

export function getBlogArticleBreadcrumbs(location: string, articleId: number): BreadcrumbProps {
  const idMap = new Map([["Id", articleId.toString()]]);

  switch (location) {
    case router.getUrl(routes.blogArticleEdit, idMap):
      return {
        showHome: true,
        values: [
          { label: "Blogs", url: routes.blogs },
          { label: "Articles", url: routes.blogArticles },
          { label: "Edit", url: null },
        ],
      };
    case router.getUrl(routes.blogArticleMedia, idMap):
      return {
        showHome: true,
        values: [
          { label: "Blogs", url: routes.blogs },
          { label: "Articles", url: routes.blogArticles },
          { label: "Media", url: null },
        ],
      };
    // case router.getUrl(routes.blogArticlePreview, idMap):
    //   return {
    //     showHome: true,
    //     values: [
    //       { label: "Blogs", url: routes.blogs },
    //       { label: "Articles", url: routes.blogArticles },
    //       { label: "Preview", url: null },
    //     ],
    //   };
    default:
      return { showHome: false, values: [] };
  }
}

export default getBlogArticleBreadcrumbs;

