import { SetStateAction, useEffect, useState } from "react";
import { router, routes } from "../../app/routes";
import { Button } from "react-bootstrap";
import { showSuccessMessage } from "../../app/tools";
import { UserProfileModel } from "../../data/entities";
import UserService from "../../services/UserService";
import { SimpleCard } from "../../components/card/Card";
import TextWithValidation from "../../components/form/input/TextWithValidation";
import EmailWithValidation from "../../components/form/input/EmailWithValidation";

export function ProfilePage() {
  // Fields that contain the updated User Profile info.
  const [email, setEmail] = useState(UserService.getUserEmail());
  const [firstName, setFirstName] = useState(UserService.getFirstName());
  const [lastName, setLastName] = useState(UserService.getLastName());

  // User Profile values onload are valid by default.
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isValid, setIsValid] = useState(
    isEmailValid && isFirstNameValid && isLastNameValid
  );


  enum fields {
    email,
    firstName,
    lastName,
    preferredMeasurement,
  }

  const handleProfileChanges = (
    field: fields,
    input: SetStateAction<string>,
    result?: boolean
  ) => {
    switch (field) {
      case fields.email:
        setEmail(input);
        setIsEmailValid(result ?? false);
        break;
      case fields.firstName:
        setFirstName(input);
        setIsFirstNameValid(result ?? false);
        break;
      case fields.lastName:
        setLastName(input);
        setIsLastNameValid(result ?? false);
        break;
    }
  };

  useEffect(() => {
    setIsValid(isEmailValid && isFirstNameValid && isLastNameValid);
  }, [isEmailValid, isFirstNameValid, isLastNameValid]);

  async function updateProfileButtonClick() {
    if (!isValid) return;
    let updatedProfileModel: UserProfileModel = {
      id: parseInt(UserService.getId()),
      email: email,
      firstName: firstName,
      lastName: lastName,
    };

    const ok = await new UserService().updateProfile(updatedProfileModel);

    if (!ok) return;

    showSuccessMessage("Your profile was successfully updated.");
    router.navigate(routes.manage);
  }


  return (
    <SimpleCard title="Update your profile.">
      <TextWithValidation
        label="UserName"
        initialValue={UserService.getDisplayName()}
        id=""
        disabled={true}
      ></TextWithValidation>
      <div className="row">
        <div className="col-12">
          <EmailWithValidation
            label="Email"
            initialValue={email}
            required={true}
            id=""
            validationResult={(input, result) => {
              handleProfileChanges(fields.email, input, result);
            }}
          ></EmailWithValidation>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <TextWithValidation
            label="First Name"
            initialValue={firstName}
            id=""
            validationResult={(input, result) => {
              handleProfileChanges(fields.firstName, input, result);
            }}
          ></TextWithValidation>
        </div>
        <div className="col-12 col-md-6">
          <TextWithValidation
            label="Last Name"
            initialValue={lastName}
            id=""
            validationResult={(input, result) => {
              handleProfileChanges(fields.lastName, input, result);
            }}
          ></TextWithValidation>
        </div>
      </div>
      <div className="col-1">
        <Button onClick={updateProfileButtonClick} disabled={false}>Save</Button>
      </div>
    </SimpleCard>
  );
}

export default ProfilePage;

