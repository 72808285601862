import React from 'react';
import { Submenu, SubmenuNavigationItem } from '../../../../components';
import { routes } from '../../../../app/routes';

interface BLogArticleSubmenuProps {
  id: string
}

export const BlogArticleSubmenu: React.FC<BLogArticleSubmenuProps> = ({ id }) => {
  const items: SubmenuNavigationItem[] = [
    {
      label: "Back",
      url: routes.blogArticles,
      icon: "back",
    },
    {
      label: "Edit",
      url: routes.blogArticleEdit,
      params: new Map([["id", id]]),
      activeMatch: routes.blogArticleEdit,
      activeMatchExact: true,
    },
    {
      label: "Media",
      url: routes.blogArticleMedia,
      params: new Map([["id", id]]),
      activeMatch: routes.blogArticleMedia,
      activeMatchExact: true,
    },
    // {
    //   label: "Preview",
    //   url: routes.blogArticlePreview,
    //   params: new Map([["id", id]]),
    //   activeMatch: routes.blogArticlePreview,
    //   activeMatchExact: true,
    // },
  ]

  return (
    <Submenu items={items} />
  );
};

export default BlogArticleSubmenu;
