import { FormEvent, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { SlideOutModal, SlideOutModalProps, TextWithValidation } from "../../../../components";
import { ModuleModel } from "../../../../data/entities";

export interface AddModuleModalProps extends SlideOutModalProps {
  onSubmit: (data: any) => void;
}

export function AddModuleModal(props: Readonly<AddModuleModalProps>) {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    name: "",
    code: "",
  } as ModuleModel);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, values);
      resetForm();
    } else {
      setValidated(false);
    }
  };

  const resetForm = () => {
    setValues({
      name: "",
      code: "",
    } as ModuleModel);
    setValidated(false);
  }

  return (
    <SlideOutModal {...props}
      title={`Add New Module`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Add
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>  
        <div className="col-12">
          <TextWithValidation
            id="name"
            required={true}
            label="Name"
            initialValue={values.name}
            onChangeText={(v) => setValues({ ...values, name: v })}
          /> 
        </div>
        <div className="col-12">
          <TextWithValidation
            id="code"
            required={true}
            label="Code"
            initialValue={values.code}
            onChangeText={(v) => setValues({ ...values, code: v })}
          />
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default AddModuleModal;

