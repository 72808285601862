import { ODataResponse, AssessmentModel } from '../data/entities';
import { AssessmentRepo } from '../data/repo'

export class AssessmentService {

  async getAssessmentsAsync(oDataQuery: string = ""): Promise<ODataResponse<AssessmentModel>> {
    return await new AssessmentRepo().getAssessmentsAsync(oDataQuery);
  }
  
  async getAssessmentByIdAsync(id: number): Promise<AssessmentModel | null> {
    return await new AssessmentRepo().getAssessmentByIdAsync(id);
  }

  async addAssessmentAsync(assessment: AssessmentModel): Promise<boolean | null> {
    return await new AssessmentRepo().addAssessmentAsync(assessment);
  }

  async editAssessmentAsync(assessment: AssessmentModel): Promise<boolean | null> {
    return await new AssessmentRepo().editAssessmentAsync(assessment);
  }

  async deleteAssessmentAsync(assessment: AssessmentModel): Promise<boolean | null> {
    return await new AssessmentRepo().deleteAssessmentAsync(assessment);
  }

}
