import { routes } from "../../app/routes";
import { PageBreadcrumbProps } from "../PageContent";

export function getBlogsBreadcrumbs(location: string): PageBreadcrumbProps {

  switch (location) {
    case routes.blogs:
      return {
        showHome: true,
        values: [
          { label: "Blogs"},
        ],
      };
    case routes.blogArticles:
      return {
        showHome: true,
        values: [
          { label: "Blogs", url: routes.blogs },
          { label: "Articles" },
        ],
      };
    case routes.blogAuthors:
      return {
        showHome: true,
        values: [
          { label: "Blogs", url: routes.blogs },
          { label: "Authors" },
        ],
      };
    case routes.blogArticleEdit:
      return {
        showHome: true,
        values: [
          { label: "Blogs", url: routes.blogs },
          { label: "Articles", url: routes.blogArticles },
          { label: "Article" },
        ],
      };
    default:
      return { showHome: false, values: [] };
  }
}

export default getBlogsBreadcrumbs;

