import React, { useState } from "react";
import { TextWithValidation } from "..";

interface DatePickerProps {
  id: string;
  label: string;
  required?: boolean;
  initialValue?: Date;
  disabled?: boolean;
  validationFunction?: (input: string) => boolean;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
}

export const DatePicker = React.forwardRef(
  (
    props: Readonly<DatePickerProps>,
    ref: React.ForwardedRef<HTMLInputElement | null>
  ) => {
    const {
      id,
      label,
      required,
      initialValue,
      disabled,
      validationFunction,
      onChangeText,
      validationResult,
    } = props;

    const [value, setValue] = useState<string>(
      initialValue?.toISOString().substring(0, 10) ?? ""
    );

    function handleInputChange(value: string) {
      const changedValue = value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    return (
      <TextWithValidation
        id={id}
        label={label}
        type="date"
        required={required}
        initialValue={value}
        disabled={disabled ?? false}
        validationFunction={validationFunction}
        onChangeText={handleInputChange}
        validationResult={validationResult}
        ref={ref}
      />
    );
  }
);

DatePicker.defaultProps = {
  required: false,
  disabled: false,
};

export default DatePicker;

