import { FileModel } from '../data/entities';
import { FileRepo } from '../data/repo';

export class FileService {
  contentRoot: string;

  constructor(contentRoot: string = "/") {
    this.contentRoot = contentRoot;
  }

  async getFilesAsync(target:string): Promise<FileModel[]> {
    return new FileRepo().getFilesAsync(target, this.contentRoot);
  }

  async createDriectoryAsync(path: string, name: string): Promise<void> {
    return new FileRepo().createDirectoryAsync(path, name);
  }

  async deleteItemAsync(path: string): Promise<void> {
    return new FileRepo().deleteItemAsync(path);
  }

  async editItemAsync(path: string, name: string): Promise<void> {
    return new FileRepo().editItemAsync(path, name);
  }

}
