import SettingsSubmenu from "./SettingsSubmenu";
import { PageContent, PageBreadcrumbProps } from "../PageContent";
import { Outlet, useLocation } from "react-router-dom";
import {getSettingsBreadcrumbs} from "./SettingsBreadcrumbs";
import { useEffect, useState } from "react";
import { PageContext } from "../PageContent";

export function SettingsPage() {
  const [title, setTitle] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();

  const GetContext = (): PageContext => {
    return {
      title: title,
      setTitle: setTitleRelay
    };
  }

  const setTitleRelay = (title?: string) => {
    setTimeout(() => {
      setTitle(title ?? "");
    }, 100);
  }

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getSettingsBreadcrumbs(location.pathname);
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      title={title}
      breadcrumbs={breadcrumbs}
      submenu={<SettingsSubmenu />}
    >
      <Outlet context={GetContext()}/>
    </PageContent>
  );
}

export default SettingsPage;

