import { LessonModel, ODataResponse } from "../entities";
import BaseRepository from "./BaseRepository";

export class LessonRepo extends BaseRepository {

  async getLessonsAsync(oDataQuery: string = ""): Promise<ODataResponse<LessonModel>> {
    const url = `odata/Lessons?$expand=Levels,Regions&${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<LessonModel>>(response);
    return handledResponse!;
  }

  async getLessonByIdAsync(id: number): Promise<LessonModel | null> {
    const url = `odata/Lessons/${id}?$expand=Levels,Regions`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<LessonModel>(response);
    return handledResponse!;
  }

  async addLessonAsync(lesson: LessonModel): Promise<boolean | null> {
    const url = `Lesson`;
    const response = await this.client.post(url, lesson);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async editLessonAsync(lesson: LessonModel): Promise<boolean | null> {
    const url = `Lesson/${lesson.id}`;
    const response = await this.client.put(url, lesson);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async updateActivitySortOrderAsync(id: number, activityIds: number[]): Promise<boolean | null> {
    const url = `Lesson/${id}/Activities/SortOrder`;
    const response = await this.client.put(url, activityIds);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async deleteLessonByIdAsync(id: number): Promise<boolean | null> {
    const url = `Lesson/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  

  async addLevelAsync(id: number, levelId: number): Promise<boolean | null> {
    const url = `Lesson/${id}/Level/${levelId}`;
    const response = await this.client.post(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }
  

  async removeLevelAsync(id: number, levelId: number): Promise<boolean | null> {
    const url = `Lesson/${id}/Level/${levelId}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async updateLevelsAsync(id: number, levelIds: number[]): Promise<boolean | null> {
    const url = `Lesson/${id}/Levels`;
    const response = await this.client.put(url, levelIds);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  

  async updateRegionsAsync(id: number, regionIds: number[]): Promise<boolean | null> {
    const url = `Lesson/${id}/Regions`;
    const response = await this.client.put(url, regionIds);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async addRegionAsync(id: number, regionId: number): Promise<boolean | null> {
    const url = `Lesson/${id}/Region/${regionId}`;
    const response = await this.client.post(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }
  

  async removeRegionAsync(id: number, regionId: number): Promise<boolean | null> {
    const url = `Lesson/${id}/Region/${regionId}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  

  async addActivityAsync(id: number, activityId: number): Promise<boolean | null> {
    const url = `Lesson/${id}/Activity/${activityId}`;
    const response = await this.client.post(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }
  

  async removeActivityAsync(id: number, activityId: number): Promise<boolean | null> {
    const url = `Lesson/${id}/Activity/${activityId}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async updateActivitiesAsync(id: number, activityIds: number[]): Promise<boolean | null> {
    const url = `Lesson/${id}/Activities`;
    const response = await this.client.put(url, activityIds);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async removeFeatureImageAsync(id: number): Promise<boolean | null> {
    const url = `Lesson/${id}/FeatureImage`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

}
