import { useEffect, useState } from "react";
import { Option } from "..";
import DropdownWithValidation from "./DropdownWithValidation";
import { BlogService } from "../../../services";

export interface BlogPickerProps {
  id: string;
  label: string;
  isInvalid?: boolean;
  onSelect: (value: string) => void;
  initialValue?: string;
  feedback?: React.ReactNode;
}

export function BlogPicker(props: Readonly<BlogPickerProps>) {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        let blogList: { value: string, text: string }[] = [{ value: "", text: "Select Blog" }];
        const data = await new BlogService().getBlogsAsync();
        if (!data) return;
        data.value.forEach((blog) => {
          const map = new Map(Object.entries(blog));
          const id = map.get("id");
          const name = map.get("name");
          let blogInfo = { value: id, text: name };
          blogList.push(blogInfo);
        });
        setOptions(blogList);
      } catch (e) {
        console.error("Error loading blogs", e);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <DropdownWithValidation
      required={true}
      id={props.id}
      label={props.label}
      options={options}
      initialValue={props.initialValue}
      onChangeSelected={(value: React.SetStateAction<string>) => {
        props.onSelect(value.toString());
      }}
      isInvalid={props.isInvalid}
      feedback={props.feedback}
    />
  );
}

export default BlogPicker;
