import { routes } from "../../app/routes";
import { PageBreadcrumbProps } from "../PageContent";



export function getSettingsBreadcrumbs(location: string): PageBreadcrumbProps {

  switch (location) {
    case routes.settings:
      return {
        showHome: true,
        values: [
          { label: "Settings"},
        ],
      };
    case routes.settingsModule:
      return {
        showHome: true,
        values: [
          { label: "Settings", url: routes.settings},
          { label: "Modules"},
        ],
      };
    case routes.settingsDiscipline:
      return {
        showHome: true,
        values: [
          { label: "Settings", url: routes.settings},
          { label: "Disciplines"},
        ],
      };
    case routes.settingsSkill:
      return {
        showHome: true,
        values: [
          { label: "Settings", url: routes.settings},
          { label: "Skills"},
        ],
      };
    case routes.settingsLevel:
      return {
        showHome: true,
        values: [
          { label: "Settings", url: routes.settings},
          { label: "Levels"},
        ],
      };
    default:
      return { showHome: false, values: [] };
  }
}

export default getSettingsBreadcrumbs;

