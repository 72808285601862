import { ActivityModel, LessonModel, ODataResponse } from "../data/entities";
import { LessonRepo } from "../data/repo/LessonRepo";

export class LessonService {

  async getLessonsAsync(oDataQuery: string = ""): Promise<ODataResponse<LessonModel>> {
    return await new LessonRepo().getLessonsAsync(oDataQuery);
  }

  async getLessonByIdAsync(id: number): Promise<LessonModel | null> {
    return await new LessonRepo().getLessonByIdAsync(id);
  }

  async addLessonAsync(lesson: LessonModel): Promise<boolean | null> {
    return await new LessonRepo().addLessonAsync(lesson);
  }

  async editLessonAsync(lesson: LessonModel): Promise<boolean | null> {
    return await new LessonRepo().editLessonAsync(lesson);
  }

  async deleteLessonByIdAsync(id: number): Promise<boolean | null> {
    return await new LessonRepo().deleteLessonByIdAsync(id);
  }

  async updateActivitySortOrderAsync(id: number, activityIds: number[]): Promise<boolean | null> {
    return await new LessonRepo().updateActivitySortOrderAsync(id, activityIds);
  }
  

  async updateLevelsAsync(id: number, levelIds: number[]): Promise<boolean | null> {
    return await new LessonRepo().updateLevelsAsync(id, levelIds);
  }

  async addLevelAsync(id: number, levelId: number): Promise<boolean | null> {
    return await new LessonRepo().addLevelAsync(id, levelId);
  }
  async removeLevelAsync(id: number, levelId: number): Promise<boolean | null> {
    return await new LessonRepo().removeLevelAsync(id, levelId);
  }

  
  async updateRegionsAsync(id: number, regionIds: number[]): Promise<boolean | null> {
    return await new LessonRepo().updateRegionsAsync(id, regionIds);
  }

  async addRegionAsync(id: number, regionId: number): Promise<boolean | null> {
    return await new LessonRepo().addRegionAsync(id, regionId);
  }
  async removeRegionAsync(id: number, regionId: number): Promise<boolean | null> {
    return await new LessonRepo().removeRegionAsync(id, regionId);
  }


  async addActivityAsync(id: number, activityId: number): Promise<boolean | null> {
    return await new LessonRepo().addActivityAsync(id, activityId);
  }
  async removeActivityAsync(id: number, activityId: number): Promise<boolean | null> {
    return await new LessonRepo().removeActivityAsync(id, activityId);
  }

  async removeFeatureImageAsync(id: number): Promise<boolean | null> {
    return await new LessonRepo().removeFeatureImageAsync(id);
  }
  
  async updateActivitiesAsync(id: number, activityIds: number[]): Promise<boolean | null> {
    return await new LessonRepo().updateActivitiesAsync(id, activityIds);
  }

}
