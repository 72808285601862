import { HeadingBar } from '../../../../../components';
import { LessonService, UrlService, UserService } from '../../../../../services';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Image }  from '../../../../../components/image/Image';
import { maxFileSize } from '../../../../../app/settings'
import {
  Upload, UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";


export function UpdateLessonMedia() {
  
  const urlParams = useParams();
  const id = parseInt(UrlService.getParamValue("id", urlParams) ?? "0");

  const [featureImage, setFeatureImage] = useState(<></>);
  const [data, setData] = useState<string>();

  const getImage = useCallback(async () => {
    const lessonService = new LessonService();
    const loadedData = await lessonService.getLessonByIdAsync(Number(id));
    if (loadedData) {
      setData(loadedData.featureImage);
    }
  }, [id]);

  const onStatusChange = useCallback(
    (event: UploadOnStatusChangeEvent) => {
      if (event.response?.status === 204) {
        getImage();
      }
    },
    [getImage]
  );

  const addImageBtn = useCallback(
    () => (
      <Upload
        batch={false}
        multiple={false}
        defaultFiles={[]}
        withCredentials={false}
        autoUpload={true}
        restrictions={
          {
            maxFileSize: maxFileSize
          }
        }
        saveHeaders={{
          Authorization: `Bearer ${UserService.getUserToken()}`,
        }}
        saveUrl={`${process.env.REACT_APP_API_URL}Lesson/${Number(id)}/FeatureImage`}
        onStatusChange={onStatusChange}
      />
    ),
    [id, onStatusChange]
  );

  const handleDeleteImage = async () => {
    const confirm = window.confirm("Are you sure you want to delete this image?");
    if (!confirm) return;

    const lessonService = new LessonService();
    await lessonService.removeFeatureImageAsync(Number(id));

    await getImage();
  }

  useEffect(() => {
    data
      ? setFeatureImage(
          <Image
            onChange={getImage}
            data={data}
            deleteHandler = {handleDeleteImage}
          />
        )
      : setFeatureImage(addImageBtn());
  }, [addImageBtn, data, getImage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getImage();
      } catch (e) {
        console.error("Error loading calibration data", e);
      }
    };
    fetchData();
  }, [getImage]);

  return (
    <div className="row">
      <div className="col-12 col-lg-6 mb-5">
        <HeadingBar title="Feature Image" />
        {featureImage}
      </div>
    </div>
  )
}

export default UpdateLessonMedia
