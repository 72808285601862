import { routes } from "../../app/routes";
import { PageBreadcrumbProps } from "../PageContent";



export function getCatalogBreadcrumbs(location: string): PageBreadcrumbProps {

  switch (location) {
    case routes.catalog:
      return {
        showHome: true,
        values: [
          { label: "Catalog"},
        ],
      };
    case routes.catalogLessons:
      return {
        showHome: true,
        values: [
          { label: "Catalog", url: routes.catalog},
          { label: "Lessons"},
        ],
      };
    case routes.catalogActivities:
      return {
        showHome: true,
        values: [
          { label: "Catalog", url: routes.catalog},
          { label: "Activities"},
        ],
      };
    default:
      return { showHome: false, values: [] };
  }
}

export default getCatalogBreadcrumbs;

