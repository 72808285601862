import { ActivityModel, ODataResponse } from '../entities';
import BaseRepository from './BaseRepository';

export class ActivityRepo extends BaseRepository{


  async getActivitiesAsync(oDataQuery: string): Promise<ODataResponse<ActivityModel>> {
    const url = `odata/Activities/?${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<ActivityModel>>(response);
    return handledResponse!;
  };

  async getActivityByIdAsync(id: number): Promise<ActivityModel | null> {
    const url = `odata/Activities/${id}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ActivityModel>(response);
    return handledResponse!;
  }

  async getActivitiesByLessonAsync(lessonId: number) : Promise<ActivityModel[]> {
    const url = `Lesson/${lessonId}/Activities`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ActivityModel[]>(response);
    return handledResponse!;
  }

  async addActivityAsync(activity: ActivityModel): Promise<boolean | null> {
    const url = `Activity`;
    const response = await this.client.post(url, activity);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async editActivityAsync(activity: ActivityModel): Promise<boolean | null> {
    const url = `Activity/${activity.id}`;
    const response = await this.client.put(url, activity);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteActivityAsync(activity: ActivityModel): Promise<boolean | null> {
    const url = `Activity/${activity.id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteActivityByIdAsync(id: number): Promise<boolean | null> {
    const url = `Activity/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }
}
