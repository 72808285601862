import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { PageBreadcrumbProps, PageContent } from "../../PageContent";
import { ActivityModel } from "../../../data/entities";
import { ActivityService, UrlService } from "../../../services";
import { useLocation } from "react-router-dom";
import ActivitySidebar from "./ActivitySidebar";
import ActivitySubmenu from "./ActivitySubmenu";
import getActivityBreadcrumbs from "./ActivityBreadcrumbs";

export function ActivityPage() {
  // Get the id from the url
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  

  // Instantiate page data
  const [pageData, setPageData] = useState<ActivityModel | undefined | null>();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();
  const title = pageData?.name ?? "Activity";

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    // Instantiate services
    const activityService = new ActivityService();
    const fetchData = async () => {
      try {
        const loadedData = await activityService.getActivityByIdAsync(Number(id)!);
        setPageData(loadedData);
      } catch (e) {
        console.error("Error loading activity", e);
      }
    };
    fetchData();
  }, [id]);

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getActivityBreadcrumbs(location.pathname, Number(id));
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      title={title}
      breadcrumbs={breadcrumbs}
      submenu={<ActivitySubmenu id={id!}/>}
    >
      <div className="row">
        <div className="col-12 col-lg-3">
          <ActivitySidebar />
        </div>
        <div className="col-12 col-lg-9 position-relative">
          <Outlet />
        </div>
      </div>
    </PageContent>
  );
}

export default ActivityPage;

