import React, { useEffect, useState } from 'react'
import { SubdisciplineModel } from '../../../../../data/entities';
import { BlockButton, ConfirmationModal, Icon, IconAndText, IconDefinitions } from '../../../../../components';
import EditSubdisciplineModal from './EditSubdisciplineModal';
import { useSharedState } from '../../../../../app/sharedProperty';
import AddSubdisciplineModal from './AddSubdisciplineModal';
import { showErrorMessage, showSuccessMessage } from '../../../../../app/tools';
import { DisciplineService } from '../../../../../services';
import { Button } from 'react-bootstrap';

interface SubdisciplinesProps {
  disciplineId: number;
}

export function Subdisciplines(props: Readonly<SubdisciplinesProps>) {

  const disciplineService = new DisciplineService();

  const [subdisciplines, setSubdisciplines] = useState<SubdisciplineModel[] | null>([]);
  const showEditState = useSharedState(false);
  const showAddState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const editItemState = useSharedState<SubdisciplineModel>({} as SubdisciplineModel);
  const deleteItemState = useSharedState<SubdisciplineModel>({} as SubdisciplineModel);

  useEffect(() => {
    fetchSubdisciplines();
  }, [props.disciplineId]);

  const fetchSubdisciplines = async () => {
    disciplineService.getSubdisciplinesAsync(`?$filter=disciplineId eq ${props.disciplineId}`)
      .then((result) => {
        if (!result) return;
        if (Array.isArray(result.value)) {
          setSubdisciplines(result.value);
        } else {
          setSubdisciplines([]);
        };
      })
      .catch(error => {
        console.error("Error fetching subdisciplines:", error)
        setSubdisciplines([]);
      });
  }

  const addSubdiscipline = async (subdiscipline: SubdisciplineModel) => {
    try {
      showAddState.setter(false);
      const response = await disciplineService.addSubdisciplineAsync(subdiscipline);
      fetchSubdisciplines();
      if (response){
        showSuccessMessage("Subdiscipline added successfully.");
      } else {
        showErrorMessage("Unable to add new subdiscipline.");
      }
    } catch (err) {
      throw new Error(`Unable to add new subdiscipline, ${err}`);
    }
  }

  const editSubdiscipline = async (subdiscipline: SubdisciplineModel) => {
    try{
      showEditState.setter(false);
      await disciplineService.editSubdisciplineAsync(subdiscipline);
      fetchSubdisciplines();
      showSuccessMessage("Subdiscipline updated successfully.");
    } catch (err) {
      throw new Error(`Unable to edit subdiscipline: ${err}`);
    }
  }

  const deleteSubdiscipline = async (subdiscipline: SubdisciplineModel) => {
    try {
      await disciplineService.deleteSubdisciplineByIdAsync(subdiscipline.id);
      fetchSubdisciplines();
      showSuccessMessage("Subdiscipline deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete subdiscipline: ${err}`);
    } 
  }

  const showEdit = (dataItem: SubdisciplineModel) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  }

  const showDelete = (dataItem: SubdisciplineModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }

  return (
    <>
      <table className="table hover-row mt-3">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {subdisciplines!.map((subdiscipline) => (
            <tr key={subdiscipline.id}>
              <td>
                {subdiscipline.name}
              </td>
              <td>
                {subdiscipline.code}
              </td>
              <td>
                <Icon 
                  onClick={() => showEdit(subdiscipline)}
                  className="mx-2 text-warning cursor-pointer"
                  iconName={IconDefinitions.edit}
                />
                <Icon 
                  onClick={() => showDelete(subdiscipline)}
                  className="mx-2 text-danger cursor-pointer"
                  iconName={IconDefinitions.delete}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button onClick={() => { showAddState.setter(true); }}>
        <IconAndText iconName={IconDefinitions.add} text="Add New Subdiscipline" />
      </Button>
      <AddSubdisciplineModal
        showState={showAddState}
        onSubmit={addSubdiscipline}
        disciplineId={props.disciplineId}
      />
      <EditSubdisciplineModal
        dataItemState={editItemState} 
        onSubmit={editSubdiscipline}
        showState={showEditState} 
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete Subdiscipline ${deleteItemState.value?.name}`}
        message={`You are about to delete this subdiscipline, it cannot be undone. Are you sure you want to delete subdiscipline ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteSubdiscipline(deleteItemState.value)}
      />
    </>
  );
}

export default Subdisciplines
