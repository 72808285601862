import React from 'react';
import PageContent from "../PageContent";
import { UserService } from "../../services";
import { NumericDashboardItem, DashboardCard, ChartDashboardItem } from "../../components";
import { colorPrimary } from '../../app/siteInfo';
import { getUuid } from '../../app/tools';

export function HomePage() {
  // Avoid calling the API if the user is not signed in
  if (!UserService.isSignedIn()) return <></>;

  // ! Replace mock data below with actual data
  const columnSeries = [38, 68, 73, 23, 90, 12, 5, 42]
  const columnCategories = ["KGD", "GR1", "GR2", "GR4", "BIO 30", "MATH 30-1", "SCI 20", "PHYS 30"];
  const lineSeries = [73, 160, 123, 276, 310, 212, 240, 156, 300];
  const lineCategories = ["Dec 10", "Dec 17", "Dec 24", "Dec 31", "Jan 07", "Jan 14", "Jan 21", "Jan 28", "This Week"];
  const numericItems = [
    {label: "USERS", value: 89, icon: "fal fa-users", color: "orange"},
    {label: "LESSONS", value: 71, icon: "fal fa-person-chalkboard", color: "green"},
    {label: "ACTIVITIES", value: 562, icon: "fal fa-head-side-goggles", color: "blue"},
    {label: "DISCIPLINES", value: 23, icon: "fal fa-layer-group", color: "red"}
  ]
  
  return (
    <PageContent>
      <div className="row">
        <div className="col col-12 px-0">
          <DashboardCard>
            {numericItems.map((item) => (
              <NumericDashboardItem key={getUuid()} {...item} />
            ))}
          </DashboardCard>
        </div>
      </div>
      <div className="row">
        <div className="col col-12 col-lg-6 px-0">
          <DashboardCard title="Assessments per week">
            <ChartDashboardItem
              categories = {lineCategories}
              series = {lineSeries}
              type = "line"
            />
          </DashboardCard>
        </div>
        <div className="col col-12 col-lg-6 px-0">
          <DashboardCard title="Lessons per level">
            <ChartDashboardItem 
              series={columnSeries} 
              categories={columnCategories}
              color = {colorPrimary}
              type = "column"
            />
          </DashboardCard>
        </div>
      </div>
    </PageContent>
  );
}

export default HomePage;
