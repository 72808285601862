import React, { useCallback, useRef } from 'react'
import { AssessmentService, UrlService } from '../../../../../services';
import { AssessmentModel, ODataResponse } from '../../../../../data/entities';
import { usePageActions } from '../../../../PageContent';
import { BlockButton, Breakpoints, ConfirmationModal, Icon, IconAndText, IconDefinitions, ODataGrid, ResponsiveGridColumn } from '../../../../../components';
import { useSharedState } from '../../../../../app/sharedProperty';
import { MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { GridCellProps } from '@progress/kendo-react-grid';
import { showSuccessMessage } from '../../../../../app/tools';
import AddAssessmentModal from './AddAssessmentModal';
import ManageAssessmentModal from './ManageAssessmentModal';
import { useParams } from 'react-router-dom';

export function AssessmentsList() {

  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);
  
  const gridData = useSharedState<Array<AssessmentModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showAddState = useSharedState(false);
  const showEditState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const editItemState = useSharedState<AssessmentModel>({} as AssessmentModel)
  const deleteItemState = useSharedState<AssessmentModel>({} as AssessmentModel);

  const assessmentService = new AssessmentService();
  
  usePageActions(
    <BlockButton onClick={() => { showAddState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.add} text="Add New Objective" />
    </BlockButton>
  );

  const getAssessments = (oDataQuery: string) => 
   assessmentService.getAssessmentsAsync(`?filter=activityId eq ${id}&$expand=Skills`);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;
    if (e.item.data.action === "delete") {
      showDelete(dataItem);
    }
  }
  
  const showEdit = useCallback((dataItem: AssessmentModel) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  }, [showEditState, editItemState]);

  const showDelete = useCallback((dataItem: AssessmentModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }, [showDeleteState, deleteItemState]);

  const addAssessment = async (assessment: AssessmentModel) => {
    try {
      showAddState.setter(false);
      await assessmentService.addAssessmentAsync(assessment);
      gridRef.current?.refreshData();
      showSuccessMessage("Objective added successfully.");
    } catch (err) {
      throw new Error(`Unable to add new assessment, ${err}`);
    }
  }

  const editAssessment = async (assessment: AssessmentModel) => {
    try{
      showEditState.setter(false);
      await assessmentService.editAssessmentAsync(assessment);
      gridRef.current?.refreshData();
      showSuccessMessage("Objective updated successfully.");
    } catch (err) {
      throw new Error(`Unable to edit objective: ${err}`);
    }
  }

  const deleteAssessment = async (assessment: AssessmentModel) => {
    try {
      await assessmentService.deleteAssessmentAsync(assessment);
      gridRef.current?.refreshData();
      showSuccessMessage("Objective deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete objective: ${err}`);
    }
  }

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <>
      <ODataGrid
          ref={gridRef}
          fullHeight={true}
          getData={getAssessments as () => Promise<ODataResponse<any>>}
          dataState={gridData}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={(e) => { showEdit(e.dataItem); }}
          sort={[{ field: "code", dir: "desc"}]}
          scrollable="scrollable"
          pageSize={100}
          >
          <ResponsiveGridColumn
            title="Name"
            field="code"
            sortable={true}
          />
          <ResponsiveGridColumn
            className="column-url"
            field="url"
            title=" "
            sortable={false}
            filterable={false}
            headerCell={() => null}
            cell={commandsCell}
            width={50}
            breakpoint={Breakpoints.sm}
          />
          <MenuItem
            text="Delete"
            data={{ action: "delete" }}
            icon={IconDefinitions.delete}
          />
        </ODataGrid>
        <AddAssessmentModal
          showState={showAddState}
          onSubmit={addAssessment}
        />
        <ManageAssessmentModal  
          showState={showEditState}
          dataItemState={editItemState}
          onSubmit={editAssessment}
        />
        <ConfirmationModal
          showState={showDeleteState}
          title={`Delete Assessment ${deleteItemState.value?.name}`}
          message={`You are about to delete this assessment, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
          confirmText="Delete"
          onConfirm={() => deleteAssessment(deleteItemState.value)}
        />
    </>
  )
}

export default AssessmentsList
