import { LoggedUser, LoginResponse } from "../../models";
import { ODataResponse, UserProfileModel, SecurityModel, UserDeleteModel, UserDetailsModel } from "../entities";
import BaseRepository, { RepositoryError } from "./BaseRepository";

export class LoginError extends RepositoryError { }

export class UserRepo extends BaseRepository {

  async login(email: string, password: string, rememberMe: boolean): Promise<LoginResponse | null | undefined> {
    const url = "Account/Login";

    const body = {
      "userName": email,
      "password": password,
    };

    const response = await this.client.post<LoginResponse>(url, body);

    try {
      return this.handleResponse<LoginResponse>(response, false, true);
    }
    catch (repoError: any) {
      throw new LoginError(repoError?.message, repoError?.code);
    }
  }

  async getUserDetails(id: number): Promise<LoggedUser | null> {
    const url = `Account/${id}`;
    const response = await this.client.get(url);
    return this.handleResponse<LoggedUser>(response);
  }

  async forgotPassword(email: string): Promise<boolean | undefined> {
    const url = "Account/ForgotPassword";
    const body = {
      "email": email
    };

    const response = await this.client.post(url, body);
    const handled = this.handleResponse(response);
    return handled !== undefined;
  }

  async resetPassword(email: string, password: string,
    confirmation: string, code: string): Promise<boolean | undefined> {
    const url = `Account/ResetPassword`;
    const body = {
      email: email,
      password: password,
      confirmPassword: confirmation,
      code: code
    };

    const response = await this.client.patch(url, body);
    this.handleResponse(response);
    return response.success;
  };

  async updateUserProfile(model: UserProfileModel): Promise<boolean | undefined> {
    const url = `Account/`;
    const body = {
      user: {
        id: model.id,
        email: model.email,
        firstName: model.firstName,
        lastName: model.lastName,
      }
    };

    const response = await this.client.put(url, body);
    this.handleResponse(response);
    return response.success;
  };

  async changePassword(password: string, newPassword: string,
    confirmation: string): Promise<boolean | undefined> {
    const url = `Account/ChangePassword`;
    const body = {
      oldPassword: password,
      newPassword: newPassword,
      confirmPassword: confirmation
    };

    const response = await this.client.patch(url, body);
    this.handleResponse(response);
    return response.success;
  };

  async register(email: string, firstName: string,
    lastName: string, password: string, roles: string[]): Promise<boolean | undefined> {
    const url = `Account`;
    const body = {
      userName: email,
      firstName,
      lastName,
      password,
      roles
    };

    const response = await this.client.post(url, body);
    this.handleResponse(response);
    return response.success;
  }

  async getUsers(oDataQuery: string): Promise<ODataResponse<UserDetailsModel>> {
    const url = `/OData/Accounts/?$count=true&${oDataQuery}`;
    const response = await this.client.get(url);
    return this.handleResponse<ODataResponse<UserDetailsModel>>(response)!;
  }

  async getUserById(id: number): Promise<UserDetailsModel | null> {
    const url = `/Account/${id}`;
    const response = await this.client.get(url);
    return this.handleResponse<UserDetailsModel>(response);
  }

  async updateUserDetails(model: UserDetailsModel): Promise<boolean> {
    const url = `/Account/${model.id}`;
    const response = await this.client.put(url, model);
    this.handleResponse<boolean>(response);
    return response.success;
  }

  async deleteUser(model: UserDeleteModel): Promise<boolean> {
    const url = `/Account/${model.id}`;
    const response = await this.client.delete(url, model);
    this.handleResponse<boolean>(response);
    return response.success;
  }

  async getUserSecurity(id: number): Promise<SecurityModel | null> {
    const url = `/Account/${id}`;
    const response = await this.client.get(url);
    const userDetails = this.handleResponse<UserDetailsModel>(response);
    if (!userDetails) return null;
    return {
      id: userDetails.id,
      roles: userDetails.roles,
      userName: userDetails.userName,
    };
  }
}

export default UserRepo;
