import * as React from "react";
import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";

const formatBlockToolSettings: EditorToolsSettings.FormatBlockDropDownListSettings = {
  ...EditorToolsSettings.formatBlock,
  items: [
    ...EditorToolsSettings.formatBlock.items,
    { text: "Blockquote", value: "h1"},
  ],
  commandName: "AlignCenter"
};

const CustomFormatBlockDropdownList = EditorTools.createFormatBlockDropDownList(formatBlockToolSettings);

export const CustomFormatBlock = (props: any) => (
  <CustomFormatBlockDropdownList {...props} />
);

export default CustomFormatBlock;
