import { useCallback, useRef } from 'react'
import { BlockButton, Breakpoints, ConfirmationModal, Icon, IconAndText, IconDefinitions, ODataGrid, ResponsiveGridColumn } from '../../../components';
import { usePageActions } from '../../PageContent';
import { MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { LessonModel, ODataResponse } from '../../../data/entities';
import { GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid';
import { useSharedState } from '../../../app/sharedProperty';
import { showSuccessMessage } from '../../../app/tools';
import { LessonService } from '../../../services';
import { AddLessonModal } from './';
import { router, routes } from '../../../app/routes';

export function CatalogLessonsList() {
  const gridData = useSharedState<Array<LessonModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showAddState = useSharedState(false);
  const showEditState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const editItemState = useSharedState<LessonModel>({} as LessonModel)
  const deleteItemState = useSharedState<LessonModel>({} as LessonModel)

  const lessonService = new LessonService();

  usePageActions(
    <BlockButton onClick={() => { showAddState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.add} text="Add New Lesson" />
    </BlockButton>
  );

  const getLessons = (oDataQuery: string): Promise<ODataResponse<any>> => 
    lessonService.getLessonsAsync(`$select=id,name,code,priority,comingSoon&${oDataQuery}`);
  
  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;
    switch (e.item.data.action){
      case "edit":
        showEdit(dataItem);
        break;
      case "delete":
        showDelete(dataItem);
    }
  }

  const onRowClick = (e: GridRowClickEvent) => {
    router.navigateWithParams(
      `${routes.lessonDetails}`,
      new Map([["id", e.dataItem.id]])
    );
  }

  const showEdit = (dataItem: LessonModel) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  }

  const showDelete = useCallback((dataItem: LessonModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  },[deleteItemState, showDeleteState]);

  const addLesson = async (lesson: LessonModel) => {
    try {
      showAddState.setter(false);
      await lessonService.addLessonAsync(lesson);
      gridRef.current?.refreshData();
      showSuccessMessage("Lesson added successfully.");
    } catch (err) {
      throw new Error(`Unable to add new lesson, ${err}`);
    }
  };

  const deleteLesson = async (lesson: LessonModel) => {
    try {
      await lessonService.deleteLessonByIdAsync(lesson.id);
      gridRef.current?.refreshData();
      showSuccessMessage("Lesson deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete lesson: ${err}`);
    }
  };

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <>
      <ODataGrid
        ref={gridRef}
        fullHeight={true}
        getData={getLessons}
        dataState={gridData}
        onRowMenuSelect={onRowMenuSelect}
        onRowClick={onRowClick}
        sort={[{ field: "name", dir: "desc"}]}
        scrollable="scrollable"
        pageSize={100}
      >
        <ResponsiveGridColumn
          title="Name"
          field="name"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Code"
          field="code"
          sortable={true}
        />
        <ResponsiveGridColumn
          title="Priority"
          field="priority"
          filter="numeric"
          sortable={true}
          breakpoint={Breakpoints.md}
        />
        <ResponsiveGridColumn
          title="Coming"
          field="comingSoon"
          filter="boolean"
          sortable={true}
          breakpoint={Breakpoints.lg}
        />
        <ResponsiveGridColumn
          className="column-url"
          field="url"
          title=" "
          sortable={false}
          filterable={false}
          headerCell={() => null}
          cell={commandsCell}
          width={50}
          breakpoint={Breakpoints.sm}
        />
        <MenuItem
          text="Delete"
          data={{ action: "delete" }}
          icon={IconDefinitions.delete}
        />
      </ODataGrid>
      
      <AddLessonModal
        showState={showAddState}
        onSubmit={addLesson}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.name}`}
        message={`You are about to delete this lesson, it cannot be undone. Are you sure you want to delete lesson ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteLesson(deleteItemState.value)}
      />
    </>
  )
}

export default CatalogLessonsList
