import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { SlideOutModal, SlideOutModalProps } from "../../../../../components";
import { ActivityModel } from "../../../../../data/entities";
import { useParams } from "react-router-dom";
import { ActivityService, UrlService } from "../../../../../services";
import { Grid, GridColumn, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from "@progress/kendo-react-grid";

export interface ManageActivitiesModalProps extends SlideOutModalProps {
  onSubmit: (selectedActivityIds: number[]) => Promise<void>;
  assignedActivities: ActivityModel[] | undefined;
}

export function ManageActivitiesModal(props: Readonly<ManageActivitiesModalProps>) {
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  const [dataState, setDataState] = useState<ActivityModel[]>();
  const [selectedCount, setSelectedCount] = useState<number>(0);

  const SELECTED_FIELD: string = "selected";
  const DATA_ITEM_KEY: string = "id";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await new ActivityService().getActivitiesAsync();
        const newData = data.value.map((dataItem: ActivityModel) => ({
          ...dataItem,
          selected: props.assignedActivities?.some(a => a.id === dataItem.id) ?? false
        }));
        setDataState(newData);
      } catch (e) {
        console.error("Error loading activities", e);
      }
    };

    fetchData();
  }, [id, props.assignedActivities]);

  useEffect(() => {
    if (dataState) {
      setSelectedCount(dataState.filter(item => item.selected).length);
    }
  }, [dataState]);

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newDataState = dataState?.map(item => ({
        ...item,
        selected: item.id === event.dataItem.id ? !item.selected : item.selected
      }));
      setDataState(newDataState);
    },
    [dataState]
  );
  
  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const target = event.syntheticEvent.target as HTMLInputElement;
      const isChecked = target.checked;
    
      const newDataState = dataState?.map(item => ({
        ...item,
        selected: isChecked
      }));
    
      setDataState(newDataState);
    },
    [dataState]
  );
  
  

  const handleSubmit = useCallback(() => {
    const selectedItems = dataState?.filter(item => item.selected).map(item => item.id);
    if (selectedItems) {
      props.onSubmit(selectedItems);
    }
  }, [dataState, props]);

  return (
    <SlideOutModal {...props}
      title={`Manage Activities: ${selectedCount}`}
      size="lg"
      bodyClassName="p-0"
      footer={
        <div className="container p-0">
          <div className="row g-0">
            <div className="col-6 pe-2">
              <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
                Cancel
              </Button>
            </div>
            <div className="col-6 ps-2">
              <Button onClick={handleSubmit} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <Grid
        data={dataState?.sort((a, b) => a.name.localeCompare(b.name))}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: false,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        sort={[{ field: "name", dir: "asc" }]}
        reorderable={true}
      >
        <GridColumn
          field={SELECTED_FIELD}
          width="50px"
          title=" "
          headerSelectionValue={
            !dataState || dataState.every((item) => item.selected)
          }
        />
        <GridColumn 
          field={'name'} 
          title={'Name'} 
          sortable={true}
          filterable= {true}
        />
        <GridColumn 
          field={'code'} 
          title={'Code'} 
          sortable={true}
          filterable= {true}
        />
      </Grid>
      {props.children}
    </SlideOutModal>
  );
}

export default ManageActivitiesModal;

