import { useState } from "react";
import Toggle from "../../../components/form/toggle/Toggle";
import Form from "react-bootstrap/Form";
import UserService from "../../../services/UserService";
import { router, routes } from "../../../app/routes";
import UrlService from "../../../services/UrlService";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, FloatingLabel, Row, Spinner } from "react-bootstrap";

export function Login() {
  const [showSpinner, setShowSpinner] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email("Email must be valid").required("Email is required"),
    password: yup.string().required("Password is required"),
    rememberMe: yup.boolean().notRequired()
  })

  return (
    <>
      <h2>Sign in</h2>
      <section>
        <h4 className="mb-4">Enter your credentials.</h4>
        <Formik
          validationSchema={schema}
          initialValues={{
            email: "",
            password: "",
            rememberMe: false
          }}
          onSubmit={async (values) => {
            setShowSpinner(true);
            const userService = new UserService();
            await userService.login(values.email, values.password, values.rememberMe);
            const isSignedIn = UserService.isSignedIn();
            setShowSpinner(false);
            if (!isSignedIn) return;
            const returnUrl = UrlService.getQueryParams("returnUrl");
            const destination = returnUrl ?? routes.home;
            router.navigate(destination);
          }}
        >
          {({values, handleSubmit, handleBlur, handleChange, touched, errors, setFieldValue}) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="email">
                      <FloatingLabel label="Email" className="mb-3">
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="password">
                      <FloatingLabel label="Password" className="mb-3">
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.password && !!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="rememberMe" className="mb-3">
                      <Toggle
                        id="rememberMe"
                        description="Remember me"
                        initialValue={values.rememberMe}
                        onChange={(value) => {setFieldValue("rememberMe", value)}}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                
                <Button type="submit" size="lg" className="btn btn-primary w-100">
                  {
                    showSpinner ? 
                      (<Spinner animation="border" variant="light" as="span" role="status" aria-hidden="true" />) : 
                      "Sign in"
                  }
                </Button>
              </Form>
            )
          }}
        </Formik>
        
      </section>
    </>
  );
}


export default Login;
