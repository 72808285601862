import { FormEvent, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MultiLineTextWithValidation, SlideOutModal, SlideOutModalProps, TextWithValidation } from "../../../../../components";
import { AssessmentModel } from "../../../../../data/entities";
import { useParams } from "react-router-dom";
import { UrlService } from "../../../../../services";

export interface AddAssessmentModalProps extends SlideOutModalProps {
  onSubmit: (data: any) => void;
}

export function AddAssessmentModal(props: Readonly<AddAssessmentModalProps>) {

  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    name: "",
    code: "",
    activityId: Number(id)
  } as AssessmentModel);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, values);
      resetForm();
    } else {
      setValidated(false);
    }
  };

  const resetForm = () => {
    setValues({
      name: "",
      code: "",
      description: ""
    } as AssessmentModel);
    setValidated(false);
  }

  return (
    <SlideOutModal {...props}
      title={`Add New Objective`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Add
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="col-12">
          <TextWithValidation
            id="code"
            required={true}
            label="Name"
            initialValue={values.code}
            onChangeText={(v) => setValues({ ...values, code: v })}
          />
        </div>
        <div className="col-12">
          <MultiLineTextWithValidation
            id="description"
            label="Description"
            initialValue={values.description}
            onChangeText={(v) => setValues({ ...values, description: v })}
          />
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  );
}

export default AddAssessmentModal;

