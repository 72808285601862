import { useEffect, useState } from 'react';
import sharedProperty from '../../../../../app/sharedProperty';
import { SlideOutModal, SlideOutModalProps } from '../../../../../components'
import { ManageAssessmentSubmenu } from './';
import { AssessmentModel } from '../../../../../data/entities';
import { AssessmentSkills } from './tabs/skills';
import { AssessmentDetails } from './tabs';

interface ManageAssessmentModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<AssessmentModel>;
  onSubmit: (data: AssessmentModel) => void;
}

export function ManageAssessmentModal(props: Readonly<ManageAssessmentModalProps>) {

  const [activeTab, setActiveTab] = useState<string>('Details');

  useEffect(() => {
    setActiveTab('Details');
  }, [props.dataItemState.value.id])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <SlideOutModal {...props}
      title={`Objective: ${props.dataItemState.value.code}`}
      submenu={
        <ManageAssessmentSubmenu 
          onChangeTab={handleTabChange}
        />}
        onHide={() => {
          setActiveTab('Details');
        }}
    >
      {activeTab === 'Details' && (
        <AssessmentDetails 
          props={props}
          dataItemState={props.dataItemState}
          onSubmit={props.onSubmit}
        />
      )}

      {activeTab === 'Skills' && (
        <AssessmentSkills 
          assessmentId={props.dataItemState.value.id}
        />
      )}
      
      {props.children}
    </SlideOutModal>
  )
}

export default ManageAssessmentModal;
