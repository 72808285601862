import { usePageActions, PageContext } from "../../PageContent";
import { BlockButton, Breakpoints, ConfirmationModal, Icon, IconAndText, IconDefinitions, ODataGrid, ResponsiveGridColumn, SpinnerDefault, TitlelessCard } from "../../../components";
import { useCallback, useRef, useState } from "react";
import { ODataResponse, SkillModel } from "../../../data/entities";
import { SkillService } from "../../../services";
import { useOutletContext } from "react-router-dom";
import { AddSkillModal, EditSkillModal } from "./components";
import { useSharedState } from "../../../app/sharedProperty";
import { showSuccessMessage } from "../../../app/tools";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { GridCellProps } from "@progress/kendo-react-grid";

export function SettingsSkillsCard() {

  const context: PageContext = useOutletContext();
  context.setTitle("Settings: Skills");
  
  const gridData = useSharedState<Array<SkillModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showAddState = useSharedState(false);
  const showEditState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const editItemState = useSharedState<SkillModel>({} as SkillModel)
  const deleteItemState = useSharedState<SkillModel>({} as SkillModel)
  const [showSpinner, setShowSpinner] = useState(false);

  const skillService = new SkillService();

  usePageActions(
    <BlockButton onClick={() => { showAddState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.add} text="Add New Skill" />
    </BlockButton>
  );

  const getSkills = (oDataQuery: string) => 
    skillService.getSkillsAsync(oDataQuery);
  

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;
    switch (e.item.data.action){
      case "edit":
        showEdit(dataItem);
        break;
      case "delete":
        showDelete(dataItem);
    }
  }

  const showEdit = (dataItem: SkillModel) => {
    editItemState.setter(dataItem);
    showEditState.setter(true);
  }

  const showDelete = (dataItem: SkillModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);
  }

  const addSkill = async (skill: SkillModel) => {
    try {
      showAddState.setter(false);
      setShowSpinner(true);
      await skillService.addSkillAsync(skill);
      gridRef.current?.refreshData();
      showSuccessMessage("Skill added successfully.");
    } catch (err) {
      throw new Error(`Unable to add new skill, ${err}`);
    } finally {
      setShowSpinner(false);
    }
  }

  const editSkill = async (skill: SkillModel) => {
    setShowSpinner(true);
    try{
      showEditState.setter(false);
      await skillService.editSkillAsync(skill);
      gridRef.current?.refreshData();
      showSuccessMessage("Skill updated successfully.");
    } catch (err) {
      throw new Error(`Unable to edit skill: ${err}`);
    } finally {
      setShowSpinner(false);
    }
  }

  const deleteSkill = async (skill: SkillModel) => {
    setShowSpinner(true);
    try {
      await skillService.deleteSkillByIdAsync(skill.id);
      gridRef.current?.refreshData();
      showSuccessMessage("Skill deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete skill: ${err}`);
    } finally {
      setShowSpinner(false);
    }
  }

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    <>
      <SpinnerDefault show={showSpinner} fullscreen={true} />
      <TitlelessCard bodyClassName="p-0">
        <ODataGrid
          ref={gridRef}
          fullHeight={true}
          getData={getSkills}
          dataState={gridData}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={(e) => { showEdit(e.dataItem); }}
          sort={[{ field: "name", dir: "desc"}]}
          scrollable="scrollable"
          pageSize={100}
        >
          <ResponsiveGridColumn
            title="Name"
            field="name"
            sortable={true}
          />
          <ResponsiveGridColumn
            title="Code"
            field="code"
            sortable={true}
          />
          <ResponsiveGridColumn
            className="column-url"
            field="url"
            title=" "
            sortable={false}
            filterable={false}
            headerCell={() => null}
            cell={commandsCell}
            width={50}
            breakpoint={Breakpoints.sm}
          />
          <MenuItem
            text="Edit"
            data={{ action: "edit" }}
            icon="hyperlink-open"
          />
          <MenuItem
            text="Delete"
            data={{ action: "delete" }}
            icon={IconDefinitions.delete}
          />
        </ODataGrid>
      </TitlelessCard>
      <AddSkillModal
        showState={showAddState}
        onSubmit={addSkill}
      />
      <EditSkillModal
        showState={showEditState}
        dataItemState={editItemState}
        onSubmit={editSkill}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete Skill ${deleteItemState.value?.name}`}
        message={`You are about to delete this skill, it cannot be undone. Are you sure you want to delete skill ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteSkill(deleteItemState.value)}
      />
    </>
  );
}

export default SettingsSkillsCard;

