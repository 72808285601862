import { showSuccessMessage } from '../../../../../app/tools';
import { Card, CardBody, CardTitle } from '../../../../../components';
import { LessonModel } from '../../../../../data/entities';
import { LessonService } from '../../../../../services';
import UpdateLessonDetails from './UpdateLessonDetails';


export function LessonDetails() {

  const lessonService = new LessonService();

  const editLesson = async (lesson: LessonModel) => {
    try{
      await lessonService.editLessonAsync(lesson);
      showSuccessMessage("Lesson updated successfully.");
    } catch (err) {
      throw new Error(`Unable to edit lesson: ${err}`);
    }
  };

  return (
    <Card>
      <CardTitle>
        <h6 className="text-light m-0">Edit Lesson Details</h6>
      </CardTitle>
      <CardBody>
        <UpdateLessonDetails onSubmit={editLesson} />
      </CardBody>
    </Card>
  )
}

export default LessonDetails
