import { useEffect, useMemo, useState } from "react";
import { routes } from "../../app/routes";
import { IconDefinitions, SubmenuNavigationItem } from "../../components";
import Submenu from "../../components/submenu/Submenu";
import { useParams } from "react-router-dom";
import { UrlService } from "../../services";


export function SettingsSubmenu() {
  const params = useParams();
  const returnUrl = UrlService.getQueryParams("returnUrl");

  const defaultSubmenuItems: SubmenuNavigationItem[] = useMemo(
    (): SubmenuNavigationItem[] => [
      {
        label: "Settings",
        icon: "",
        url: routes.settings,
        activeMatch: routes.settings,
        activeMatchExact: true
      },
      {
        label: "Modules",
        icon: "",
        url: routes.settingsModule,
        activeMatch: routes.settingsModule,
        activeMatchExact: true
      },
      {
        label: "Disciplines",
        icon: "",
        url: routes.settingsDiscipline,
        activeMatch: routes.settingsDiscipline,
        activeMatchExact: true
      },
      {
        label: "Skills",
        icon: "",
        url: routes.settingsSkill,
        activeMatch: routes.settingsSkill,
        activeMatchExact: true
      },
      {
        label: "Levels",
        icon: "",
        url: routes.settingsLevel,
        activeMatch: routes.settingsLevel,
        activeMatchExact: true
      },
    ],
    []
  );

  useEffect(() => {
    const backItem: SubmenuNavigationItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuNavigationItem[]>(defaultSubmenuItems);

  return <Submenu items={submenuItems}></Submenu>;
}

export default SettingsSubmenu
