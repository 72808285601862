import { ODataResponse, SkillModel } from "../data/entities";
import { SkillRepo } from "../data/repo";


export class SkillService {

  
  async getSkillsAsync(oDataQuery: string = ""): Promise<ODataResponse<SkillModel>> {
    return await new SkillRepo().getSkillsAsync(oDataQuery);
  }

  async addSkillAsync(skill: SkillModel): Promise<boolean | null> {
    return await new SkillRepo().addSkillAsync(skill);
  }

  async editSkillAsync(skill: SkillModel): Promise<boolean | null> {
    return await new SkillRepo().editSkillAsync(skill);
  }

  async deleteSkillByIdAsync(id: number): Promise<boolean | null> {
    return await new SkillRepo().deleteSkillByIdAsync(id);
  }
  
}
