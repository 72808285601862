import * as React from "react";
import { State, toODataString } from "@progress/kendo-data-query";

import { GridLoadingPanel } from "../../components/spinners/GridLoadingPanel";
import { ODataResponse } from "../../data/entities";

export type GridODataLoaderDataReceivedFunction = (
  data: any,
  total: number
) => any;
export type GridODataLoaderGetDataFunction = (
  oDataQuery: string
) => Promise<ODataResponse<any>>;

export interface GridODataLoaderProps {
  dataState: State;
  onDataReceived: GridODataLoaderDataReceivedFunction;
  getData: GridODataLoaderGetDataFunction;
}

export const GridODataLoader = React.forwardRef((props: GridODataLoaderProps, ref) => {
  const lastSuccess = React.useRef("");
  const pending = React.useRef("");

  React.useImperativeHandle(ref, () => ({
    // This allows parents to use ref.refresh();
    refresh() {
      requestDataIfNeeded(true);
    }
  }));

  const requestDataIfNeeded = (force?: boolean) => {
    if (!force && (
      pending.current ||
      toODataString(props.dataState) === lastSuccess.current
    )) {
      return;
    }
    pending.current = toODataString(props.dataState);

    props.getData(pending.current).then((result) => {
      lastSuccess.current = pending.current;
      pending.current = "";

      if (toODataString(props.dataState) === lastSuccess.current) {
        const value = result?.value ?? [];
        const count = result?.["@odata.count"] ?? 0;
        props.onDataReceived.call(undefined, value, count);
      } else {
        requestDataIfNeeded();
      }
    });
  };

  requestDataIfNeeded();
  return pending.current ? <GridLoadingPanel /> : null;
});

export default GridODataLoader;

