export interface SpinnerDefaultProps {
  show?: boolean
  fullscreen?: boolean
}

export function SpinnerDefault(props: SpinnerDefaultProps) {
  if (props.show != null && props.show === false)
    return <></>;

  return (
    <div className={`spinner k-loading-mask w-100 d-flex justify-content-center my-3 ${props.fullscreen ? "fullscreen" : ""}`}>
      <div className="k-loading-image"><div className="k-loading-color"></div></div>
    </div>
  )
}

export default SpinnerDefault;
