import { AssessmentModel, ODataResponse } from "../entities";
import BaseRepository from "./BaseRepository";

export class AssessmentRepo extends BaseRepository {

  async getAssessmentsAsync(oDataQuery: string = ""): Promise<ODataResponse<AssessmentModel>> {
    const url = `odata/Assessments${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<AssessmentModel>>(response);
    return handledResponse!;
  };

  async getAssessmentByIdAsync(id: number): Promise<AssessmentModel | null> {
    const url = `Assessment/${id}`;
    const response = await this.client.get(url);
    if (!response.success) {
      this.handleException(response);
      return null;
    }
    return response.data;
  }

  async addAssessmentAsync(assessment: AssessmentModel): Promise<boolean | null> {
    const url = `Assessment`;
    const response = await this.client.post(url, assessment);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  }

  async editAssessmentAsync(assessment: AssessmentModel): Promise<boolean | null> {
    const url = `Assessment/${assessment.id}`;
    const response = await this.client.put(url, assessment);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteAssessmentAsync(assessment: AssessmentModel): Promise<boolean | null> {
    const url = `Assessment/${assessment.id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

}
