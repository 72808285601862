import { LevelModel, ODataResponse } from "../entities";
import BaseRepository from "./BaseRepository";

export class LevelRepo extends BaseRepository{
  
  async getLevelsAsync(oDataQuery: string = ""): Promise<ODataResponse<LevelModel>> {
    const queryWithCount = `${oDataQuery}${oDataQuery ? "&" : ""}$count=true`;
    const url = `odata/Levels?${queryWithCount}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<LevelModel>>(response);
    return handledResponse!;
  };

  async addLevelAsync(level: LevelModel): Promise<boolean | null> {
    const url = `Level`;
    const response = await this.client.post(url, level);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  };

  async editLevelAsync(level: LevelModel): Promise<boolean | null> {
    const url = `Level/${level.id}`;
    const response = await this.client.put(url, level);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteLevelByIdAsync(id: number): Promise<boolean | null> {
    const url = `Level/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }
}
