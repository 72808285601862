import logo from '../../images/layout/brand.svg';
import { company } from '../../app/siteInfo'; 
import './Footer.scss';

export function Footer() {
  const year = new Date().getFullYear();


  return (
    <footer className="pt-5">
      <div className="py-5 text-center">
        <img src={logo} className="brand" alt="VRCore Education"/>
        <p className="small mt-2"><span className="text-soft small">&copy; {year}, {company}</span></p>
      </div>
    </footer>
  );
}

export default Footer;
