import { useEffect, useState } from 'react';
import sharedProperty from '../../../app/sharedProperty';
import { SlideOutModal, SlideOutModalProps } from '../../../components'
import { DisciplineModel } from '../../../data/entities';
import { ManageDisciplineSubmenu } from './';
import DisciplineDetails from './tabs/details/DisciplineDetails';
import { Subdisciplines } from './tabs/subdisciplines';

interface EditDisciplineModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<DisciplineModel>;
  onSubmit: (data: DisciplineModel) => void;
}

export function ManageDisciplineModal(props: Readonly<EditDisciplineModalProps>) {

  const [activeTab, setActiveTab] = useState<string>('Details');

  useEffect(() => {
    setActiveTab('Details');
  }, [props.dataItemState.value.id])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <SlideOutModal {...props}
      title={`Discipline: ${props.dataItemState.value.name}`}
      submenu={
        <ManageDisciplineSubmenu 
          onChangeTab={handleTabChange}
        />}
        onHide={() => {
          setActiveTab('Details');
        }}
    >
      {activeTab === 'Details' && (
        <DisciplineDetails
          props={props}
          dataItemState={props.dataItemState}
          onSubmit={props.onSubmit}
        />
      )}

      {activeTab === 'Subdisciplines' && (
        <Subdisciplines 
          disciplineId={props.dataItemState.value.id}
        />
      )}
      
      {props.children}
    </SlideOutModal>
  )
}

export default ManageDisciplineModal;
