import { combinePaths } from '../../../../../app/tools';
import { getName, formatBytes } from '../helpers/helperMethods';
import { DataModel } from '../interfaces';
import { SvgIcon } from '@progress/kendo-react-common';

export const FileInformation = (props: any) => {
  return (
    props.data
      ? FileSelectionRendering(props.data)
      : NoDataRendering());
}

const NoDataRendering = () => {
  return (
    <div className="k-filemanager-preview" style={{ width: '100%', border: 0 }}>
      <div className="k-file-info">
        <span className="k-file-preview">
          <span className="k-file-icon k-icon k-icon k-i-none">
          </span>
        </span>
        <span className="k-file-name k-no-file-selected">No File Selected</span>
      </div>
    </div>
  )
}

const FileSelectionRendering = (item: DataModel) => {
  const rawDate: any = item.lastModified;
  const date: Date = rawDate instanceof Date ? rawDate : new Date(rawDate);
  const { svgIcon }  = item.icon!;
  
  return (
    <div className="k-filemanager-preview" style={{ width: '100%', border: 0 }}>
      <div className="k-file-info">
        <span className="k-file-preview text-align-center">
          {item.isImage ? 
            <img src={combinePaths('/files', item.path ?? '')} alt={item.name} style={{ width:"50%", maxWidth:"15rem" }} /> :
            <SvgIcon icon={svgIcon} size='xxxlarge'/>
          }
        </span>
        <span className="k-file-name k-single-file-selected">{item.path ? getName(item.path) : ''}</span>
        <dl className="k-file-meta">
          <dt className="k-file-meta-label">Type:  </dt>
          <dd className={"k-file-meta-value k-file-type"}> {item.icon ? item.icon.type : ''}</dd>
          <dd className="k-line-break"></dd>
          <dt className="k-file-meta-label">Size:  </dt>
          <dd className="k-file-meta-value k-file-size"> {item.size && item.size > 0 ? formatBytes(item.size) : '-'}</dd>
          <dd className="k-line-break"></dd>
          <dt className="k-file-meta-label">URL:  </dt>
          <dd className="k-file-meta-value k-file-modified"> {item.path}</dd>
          <dd className="k-line-break"></dd>
          <dt className="k-file-meta-label">Date Modified:  </dt>
          <dd className="k-file-meta-value k-file-modified"> {date.toLocaleDateString()}</dd>
          <dd className="k-line-break"></dd>
        </dl>
      </div>
    </div>
  );
}

