import { usePageActions } from "../../PageContent";
import { BlockButton, Breakpoints, ConfirmationModal, Icon, IconAndText, IconDefinitions, ODataGrid, ResponsiveGridColumn, SpinnerDefault, TitlelessCard } from "../../../components";
import { useCallback, useRef, useState } from "react";
import { ODataResponse, ActivityModel } from "../../../data/entities";
import { ActivityService } from "../../../services";
import { AddActivityModal } from "./";
import { useSharedState } from "../../../app/sharedProperty";
import { showSuccessMessage } from "../../../app/tools";
import { MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { GridCellProps, GridRowClickEvent } from "@progress/kendo-react-grid";
import { router, routes } from "../../../app/routes";
export function CatalogActivitiesList() {
  
  const gridData = useSharedState<Array<ActivityModel>>([]);
  const gridRef = useRef<ODataGrid>(null);
  const showAddState = useSharedState(false);
  const showDeleteState = useSharedState(false);
  const deleteItemState = useSharedState<ActivityModel>({} as ActivityModel)
  const [showSpinner, setShowSpinner] = useState(false);
  const activityService = new ActivityService();

  usePageActions(
    <BlockButton onClick={() => { showAddState.setter(true); }}>
      <IconAndText iconName={IconDefinitions.add} text="Add New Activity" />
    </BlockButton>
  );

  const getActivities = (oDataQuery: string) =>
    activityService.getActivitiesAsync(`$select=id,name,code&${oDataQuery}`);

  const onRowMenuSelect = (e: MenuSelectEvent, dataItem: any) => {
    if (dataItem.isDeleted) return;
    switch (e.item.data.action){
      case "delete":
        showDelete(dataItem);
    }
  }

  const onRowClick = (e: GridRowClickEvent) => {
    router.navigateWithParams(
      `${routes.activityDetails}`,
      new Map([["id", e.dataItem.id]])
    );
  }

  const showDelete = (dataItem: ActivityModel) => {
    deleteItemState.setter(dataItem);
    showDeleteState.setter(true);

  }

  const addActivity = async (activity: ActivityModel) => {
    try {
      showAddState.setter(false);
      setShowSpinner(true);
      await activityService.addActivityAsync(activity);
      gridRef.current?.refreshData();
      showSuccessMessage("Activity added successfully.");
    } catch (err) {
      throw new Error(`Unable to add new activity, ${err}`);
    } finally {
      setShowSpinner(false);
    };
  };

  const deleteActivity = async (activity: ActivityModel) => {
    setShowSpinner(true);
    try {
      await activityService.deleteActivityAsync(activity);
      gridRef.current?.refreshData();
      showSuccessMessage("Activity deleted successfully.");
    } catch(err) {
      throw new Error(`Unable to delete activity: ${err}`);
    } finally {
      setShowSpinner(false);
    };
  };

  const commandsCell = useCallback(
    (props: GridCellProps) => (
      <td className="text-center">
        <Icon
          iconName={IconDefinitions.delete}
          className="text-danger"
          onClick={() => showDelete(props.dataItem)}
        />
      </td>
    ),
    [showDelete]
  );

  return (
    
    <>
      <SpinnerDefault show={showSpinner} fullscreen={true} />
      <TitlelessCard bodyClassName="p-0">
        <ODataGrid
          ref={gridRef}
          fullHeight={true}
          getData={getActivities}
          dataState={gridData}
          onRowMenuSelect={onRowMenuSelect}
          onRowClick={onRowClick}
          sort={[{ field: "name", dir: "desc"}]}
          scrollable="scrollable"
          pageSize={100}
        >
          <ResponsiveGridColumn
            title="Name"
            field="name"
            sortable={true}
          />
          <ResponsiveGridColumn
            title="Code"
            field="code"
            sortable={true}
          />
          <ResponsiveGridColumn
            className="column-url"
            field="url"
            title=" "
            sortable={false}
            filterable={false}
            headerCell={() => null}
            cell={commandsCell}
            width={30}
            breakpoint={Breakpoints.sm}
          />
          <MenuItem
            text="Delete"
            data={{ action: "delete" }}
            icon={IconDefinitions.delete}
          />
        </ODataGrid>
      </TitlelessCard>
      <AddActivityModal
        showState={showAddState}
        onSubmit={addActivity}
      />
      <ConfirmationModal
        showState={showDeleteState}
        title={`Delete ${deleteItemState.value?.name}`}
        message={`You are about to delete this activity, it cannot be undone. Are you sure you want to delete ${deleteItemState.value.name}?`}
        confirmText="Delete"
        onConfirm={() => deleteActivity(deleteItemState.value)}
      />
    </>
  );
}

export default CatalogActivitiesList;

