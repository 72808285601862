import React, { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap';
import "../FormStyle.scss";

interface NumberWithValidationProps {
  id?: string;
  initialValue?: string;
  label?: string;
  required?: boolean;
  validationFunction?: (input: string) => void;
  onChangeText?: (value: string) => void;
  validationResult?: (input: string, result: boolean) => void;
  validationType: "integer" | "double";
  minLength?: number;
  maxLength?: number;
}

export const NumberWithValidation = React.forwardRef(({
  initialValue: emailText,
  id,
  label,
  required,
  onChangeText,
  validationResult,
  validationType,
  minLength,
  maxLength,
}: NumberWithValidationProps, ref: React.ForwardedRef<HTMLInputElement | null>) => {

  const [isValid, setIsValid] = useState(true);
  const [value, setEmailValue] = useState(emailText ?? "");

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const changedValue = event.target.value ?? "";
    setEmailValue(changedValue);
    if (onChangeText) onChangeText(changedValue);
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
    let regex = null;
    let valid: boolean | null = null;

    if (validationType === "integer") {
      regex = /^\d\d{0,3}$/;
      valid = regex.test(e.target.value);
      setIsValid(
        valid &&
        parseInt(e.target.value) >= minLength! &&
        parseInt(e.target.value) <= maxLength!
      );
      if (validationResult) validationResult(value, valid);
      return;
    }
    if (validationType === "double") {
      regex = /^(?:\d\d{0,3})(?:\.\d{1,2})?$/;
      valid = regex.test(e.target.value);
      setIsValid(
        valid &&
        parseFloat(e.target.value) >= minLength! &&
        parseInt(e.target.value) <= maxLength!
      );
      if (validationResult) validationResult(value, valid);
    }
  }

  return (
    <Form.Group controlId={id}>
      <FloatingLabel label={label} className="mb-3">
        <Form.Control
          ref={ref}
          type="number"
          required={required}
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          isInvalid={!isValid}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          Field is required. It cannot be empty.
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
});

NumberWithValidation.defaultProps = {
  minLength: 0,
  maxLength: 100000,
};

export default NumberWithValidation;
