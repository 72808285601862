import {FilesSubmenu} from ".";
import FileManager from "./components/file-manager/FileManager";

export function FilesPage() {

  return (
    <div className="h-100 d-flex flex-column">
      {/* 2024-05-13: Remove submenu from page. Leaving the component commented out in case we want to expand on this page later. */}
      {/* <FilesSubmenu /> */}
      <div className="flex-grow-1">
        <FileManager />
      </div>
    </div>
  );
}

export default FilesPage;

