import React, { forwardRef, useImperativeHandle, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../FormStyle.scss";

interface EmailWithValidationProps {
  id: string;
  initialValue?: string;
  label?: string;
  required?: boolean;
  validationFunction?: (input: string) => boolean;
  onChangeText?: (value: string) => void;
  useDefaultValidation?: boolean;
  validationResult?: (input: string, result: boolean) => void;
}

export const EmailWithValidation = forwardRef(
  (props: Readonly<EmailWithValidationProps>, ref) => {
    const {
      id,
      initialValue,
      label,
      required,
      validationFunction,
      onChangeText,
      useDefaultValidation,
      validationResult,
    } = props;

    const [value, setValue] = useState(initialValue ?? "");
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      const changedValue = event.target.value ?? "";
      setValue(changedValue);
      if (onChangeText) onChangeText(changedValue);
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>): boolean {
      let result = required ? value !== "" : true;

      if (useDefaultValidation) {
        const regex = /^\S+@\S+\.\S+$/;
        result = regex.test(value);
      }
      
      if (validationFunction) result = validationFunction(value);
      if (validationResult) validationResult(value, result);
      
      setIsValid(result);

      return result;
    }

    useImperativeHandle(ref, () => ({
      validate(): boolean {
        console.debug(`Validating: ${label}.`);
        return handleBlur({} as React.FocusEvent<HTMLInputElement>);
      },
    }));

    return (
      <Form.Group controlId={id}>
        <FloatingLabel label={label} className="mb-3">
          <Form.Control
            type="email"
            placeholder="email@domain.com"
            required={required}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            isValid={isValid}
            isInvalid={isValid !== undefined && !isValid}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            Invalid e-mail.
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    );
  }
);

EmailWithValidation.defaultProps = {
  label: "Email",
  required: true,
  useDefaultValidation: true,
};

export default EmailWithValidation;



