import React, { FormEvent, useRef, useState } from 'react'
import { MultiLineTextWithValidation, SlideOutModal, SlideOutModalProps, TextWithValidation } from '../../../../../components'
import sharedProperty from '../../../../../app/sharedProperty'
import { SubdisciplineModel } from '../../../../../data/entities';
import { Button, Form } from 'react-bootstrap';

interface EditSubdisciplineModalProps extends SlideOutModalProps {
  dataItemState: sharedProperty<SubdisciplineModel>;
  onSubmit: (data: SubdisciplineModel) => void;
}

export function EditSubdisciplineModal(props: Readonly<EditSubdisciplineModalProps>) {
  const [validated, setValidated] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const formSubmitRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      props.onSubmit.call(null, props.dataItemState.value);
      setValidated(false);
    } else {
      setValidated(false);
    }
  };

  const patchDataItem = (patch: Object) => {
    props.dataItemState.setter({ ...props.dataItemState.value, ...patch });
  }
  
  return (
    <SlideOutModal {...props}
      title={`Subdiscipline: ${props.dataItemState.value.name}`}
      footer={<div className="container p-0">
        <div className="row g-0">
          <div className="col-6 pe-2">
            <Button onClick={() => props.showState.setter(false)} variant="cancel" className="w-100">
              Cancel
            </Button>
          </div>
          <div className="col-6 ps-2">
            <Button onClick={() => formSubmitRef.current?.click()} variant="primary" className="w-100">
              Update
            </Button>
          </div>
        </div>
      </div>}>
      <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit}>  
        <div className="col-12">
          <TextWithValidation
            id="name"
            required={true}
            label="Name"
            initialValue={props.dataItemState.value.name}
            onChangeText={(v) => patchDataItem({ name: v })}
          /> 
        </div>
        <div className="col-12">
          <TextWithValidation
            id="code"
            required={true}
            label="Code"
            initialValue={props.dataItemState.value.code}
            onChangeText={(v) => patchDataItem({ code: v })}
          />
        </div>
        <div className="col-12">
          <MultiLineTextWithValidation
            id="description"
            required={false}
            label="Description"
            initialValue={props.dataItemState.value.description}
            onChangeText={(v) => patchDataItem({ description: v })}
          />
        </div>
        <Button ref={formSubmitRef} type="submit" className="d-none" />
      </Form>
      {props.children}
    </SlideOutModal>
  )
}

export default EditSubdisciplineModal
