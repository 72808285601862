import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import alertSlice from './alertSlice';
import authSlice from './authSlice';


const reducer = {
  alerts: alertSlice,
  auth: authSlice,
};

const persistedState = localStorage.getItem("reduxState") ? JSON.parse(localStorage.getItem("reduxState") ?? "") : {};

export const store = configureStore({
  reducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
});

store.subscribe(() => {
  // Saves in the local storage only the auth state;
  const storeObj = {
    auth: store.getState().auth,
    alerts: {},
  }
  localStorage.setItem("reduxState", JSON.stringify(storeObj));
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export * from './authSlice';
export * from './alertSlice';
