import { useOutletContext } from "react-router-dom";
import { PageContext } from "../../PageContent";

export function SettingsLandingCard() {
  const context: PageContext = useOutletContext();
  context.setTitle("Settings");

  return (
    <p className="lead">
      Welcome to your Settings page, where you're in control.
      <br />
      <br />
      Use the tabs above to customize Modules, Disciplines, Skills, and Levels.
    </p>
  );
}

export default SettingsLandingCard;

