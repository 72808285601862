import { ODataResponse, RegionModel } from "../entities";
import BaseRepository from "./BaseRepository";

export class RegionRepo extends BaseRepository{

  async getRegionsAsync(oDataQuery: string = ""): Promise<ODataResponse<RegionModel>> {
    const url = `odata/Regions`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<RegionModel>>(response);
    return handledResponse!;
  }

}
