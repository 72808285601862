import { ActivityModel, ODataResponse } from '../data/entities';
import { ActivityRepo } from '../data/repo';

export class ActivityService {

  async getActivitiesAsync(oDataQuery: string = ""): Promise<ODataResponse<ActivityModel>> {
    return await new ActivityRepo().getActivitiesAsync(oDataQuery);
  }

  async getActivityByIdAsync(id: number): Promise<ActivityModel | null> {
    return await new ActivityRepo().getActivityByIdAsync(id);
  }

  async getActivitiesByLessonAsync(lessonId: number): Promise<ActivityModel[]> {
    return await new ActivityRepo().getActivitiesByLessonAsync(lessonId);
  }

  async addActivityAsync(activity: ActivityModel): Promise<boolean | null> {
    return await new ActivityRepo().addActivityAsync(activity);
  }

  async editActivityAsync(activity: ActivityModel): Promise<boolean | null> {
    return await new ActivityRepo().editActivityAsync(activity);
  }

  async deleteActivityAsync(activity: ActivityModel): Promise<boolean | null> {
    return await new ActivityRepo().deleteActivityAsync(activity);
  }

  async deleteActivityByIdAsync(id: number): Promise<boolean | null> {
    return await new ActivityRepo().deleteActivityByIdAsync(id);
  }
}
