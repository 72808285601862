import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisTitle,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

interface ChartDashboardItemProps {
    categories: any[];
    categoryTitle?: string;
    series: any[];
    valueTitle?: string;
    color?: string;
    type: "line" | "column";
}

export function ChartDashboardItem({categories, categoryTitle, color, valueTitle, type, series} : Readonly<ChartDashboardItemProps>) {
  return (
    <Chart className="w-100 px-4">
      <ChartTooltip visible={true} />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={categories}
          majorGridLines={{ visible: false }} 
          minorGridLines={{ visible: false }}
        >
          <ChartCategoryAxisTitle text={categoryTitle} />
        </ChartCategoryAxisItem>
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem line={{visible: false}}>
          <ChartValueAxisTitle text={valueTitle} />
        </ChartValueAxisItem>
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem 
          type={type} 
          data={series}
          color={color}
        />
      </ChartSeries>
    </Chart>
  )
}

export default ChartDashboardItem

ChartDashboardItem.defaultProps = {
}
