import { useLocation } from "react-router-dom";
import {
  CatalogSubmenu,
  PageBreadcrumbProps,
  PageContent,
  getCatalogBreadcrumbs,
} from "../..";
import { useEffect, useState } from "react";

export function CatalogLandingPage() {
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
    const newBreadcrumbs: PageBreadcrumbProps = getCatalogBreadcrumbs(
      location.pathname
    );
    setBreadcrumbs(newBreadcrumbs);
  }, [location.pathname]);

  return (
    <PageContent
      submenu={<CatalogSubmenu />}
      breadcrumbs={breadcrumbs}
      title="Catalog"
    >
      <p className="lead">
        Welcome to the Catalog page!
        <br />
        <br />
        Here you'll find the educational resources. Use the tabs on top to
        switch between Lessons, Activities, and more
      </p>
    </PageContent>
  );
}

export default CatalogLandingPage;



