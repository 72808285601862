import { useEffect, useMemo, useState } from "react";
import { routes } from "../../app/routes";
import { IconDefinitions, SubmenuNavigationItem } from "../../components";
import Submenu from "../../components/submenu/Submenu";
import { useParams } from "react-router-dom";
import { UrlService } from "../../services";


export function CatalogSubmenu() {
  const params = useParams();
  const returnUrl = UrlService.getQueryParams("returnurl");

  const defaultSubmenuItems: SubmenuNavigationItem[] = useMemo(
    (): SubmenuNavigationItem[] => [
      {
        label: "Catalog",
        icon: "",
        url: routes.catalog,
        activeMatch: routes.catalog,
        activeMatchExact: true
      },
      {
        label: "Lessons",
        icon: "",
        url: routes.catalogLessons,
        activeMatch: routes.catalogLessons,
        activeMatchExact: true
      },
      {
        label: "Activities",
        icon: "",
        url: routes.catalogActivities,
        activeMatch: routes.catalogActivities,
        activeMatchExact: true
      },
    ],
    []
  );

  useEffect(() => {
    const backItem: SubmenuNavigationItem = {
      label: "Back",
      icon: IconDefinitions.back,
      url: returnUrl ?? "",
    };

    if (returnUrl) {
      setSubmenuItems([backItem, ...defaultSubmenuItems]);
    } else {
      setSubmenuItems(defaultSubmenuItems);
    }
  }, [defaultSubmenuItems, returnUrl]);

  const [submenuItems, setSubmenuItems] =
    useState<SubmenuNavigationItem[]>(defaultSubmenuItems);

  return <Submenu items={submenuItems}></Submenu>;
}

export default CatalogSubmenu
