import { setLoggedUser, setToken, setTokenData, setIsLoggedIn } from "../app/store/authSlice";
import { LoginError, UserRepo } from "../data/repo/UserRepo";
import LoggedUser from "../models/LoggedUser";
import { setError, store } from '../app/store/store';
import TokenService from "./TokenService";
import { ODataResponse, UserDetailsModel, UserProfileModel, SecurityModel, UserDeleteModel } from "../data/entities";
import { Roles } from "../models";
import { forceLogout } from "../app/tools";

const TOKEN_STORAGE = 'userToken';
export class UserService {

  static getDisplayName() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser?.firstName ?? loggedUser.userName} ${loggedUser.lastName ?? ""}`;
  }

  static getId() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser?.id ?? 0}`;
  }

  static getFirstName() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser?.firstName ?? ""}`;
  }

  static getLastName() {
    const loggedUser = store.getState().auth.loggedUser;
    if (!loggedUser) return "";
    return `${loggedUser.lastName ?? ""}`;
  }

  static getUserEmail() {
    const loggedUser = store.getState().auth.loggedUser;
    return loggedUser?.userName ?? "";
  }

  static isInRole(roleName: Roles): boolean {
    const tokenData = store.getState().auth.tokenData;
    return tokenData?.roles?.includes(roleName) ?? false;
  }


  static isSignedIn(): boolean {
    const isIn = store.getState().auth.isSignedIn;
    if (!isIn) return false;

    const token = store.getState().auth.userToken;
    if (!token) return false;

    const expired = TokenService.isTokenExpired();
    return !expired;
  }

  async login(email: string, password: string, rememberMe: boolean) {
    var loginResponse = null;
    try {
      loginResponse = await new UserRepo().login(email, password, rememberMe);
      if (!loginResponse)
        throw new LoginError('Unable to get login response.');
    }
    catch (err: any) {
      const loginErr = err as LoginError;
      const errorMsg = loginErr?.code === 401 ?
        "Unable to login. Please check your login details and try again." :
        (loginErr?.message ?? `$err`);
      let loginFailed = setError(errorMsg);
      store.dispatch(loginFailed);
      return;
    }

    // Setting token to the store allows all repos to access it.
    store.dispatch(setToken(loginResponse.token));

    const tokenData = TokenService.getTokenData(loginResponse.token);
    const loggedUser = await this.getUser(tokenData.userId);
    if (!loggedUser) return;

    if (rememberMe) {
      localStorage.setItem(TOKEN_STORAGE, loginResponse.token);
    }

    store.dispatch(setIsLoggedIn(true));
    store.dispatch(setTokenData(tokenData));
    store.dispatch(setLoggedUser(loggedUser));
  }

  static getUserToken(): string | null {
    return store.getState().auth.userToken;
  }

  async forgotPassword(email: string) {
    return await new UserRepo().forgotPassword(email);
  }

  async resetPassword(email: string, password: string, confirmation: string, code: string) {
    return await new UserRepo().resetPassword(email, password, confirmation, code);
  }

  async updateProfile(model: UserProfileModel) {
    const ok = await new UserRepo().updateUserProfile(model);
    const loggedUser = await this.getUser(model?.id ?? 0);
    store.dispatch(setLoggedUser(loggedUser));
    return ok;
  }

  async changePassword(password: string, newPassword: string, confirmation: string) {
    return await new UserRepo().changePassword(password, newPassword, confirmation);
  }

  async register(
    email: string,
    firstName: string,
    lastName: string) {

    const password = this.generateRandomString(6);

    return await new UserRepo().register(email, firstName, lastName, password, []);
  }

  private generateRandomString(size: number) {
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()_+=-';
    const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';

    let result = '';
    result += numbers[Math.floor(Math.random() * numbers.length)];
    result += symbols[Math.floor(Math.random() * symbols.length)];
    result += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
    result += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];

    const allChars = numbers + symbols + upperCaseLetters + lowerCaseLetters;
    for (let i = 4; i < size; i++) {
      result += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle result to ensure requirements are not always at the start of the string
    result = result.split('').sort(() => 0.5 - Math.random()).join('');

    return result;
  }

  static logout() {
    forceLogout();
  }

  private async getUser(id: number): Promise<LoggedUser | undefined> {
    const repo = new UserRepo();
    const details = await repo.getUserDetails(id);
    if (!details) return undefined;

    const loggedUser = {
      id: details.id ?? 0,
      userName: details.userName ?? "",
      email: details.userName,
      firstName: details.firstName,
      lastName: details.lastName,
      roles: details.roles,
    };

    return loggedUser;
  }

  async getUsers(oDataQuery: string): Promise<ODataResponse<UserDetailsModel>> {
    const response = await new UserRepo().getUsers(oDataQuery);
    return response;
  }

  async getUserById(id: number): Promise<UserDetailsModel | null> {
    const response = await new UserRepo().getUserById(id);
    return response;
  }

  async updateUserDetails(id: number, model: UserDetailsModel): Promise<boolean> {
    const response = await new UserRepo().updateUserDetails(model);
    return response;
  }

  async deleteUser(id: number, model: UserDeleteModel): Promise<boolean> {
    const response = await new UserRepo().deleteUser(model);
    return response;
  }

  async getUserSecurity(id: number): Promise<SecurityModel | null> {
    const response = await new UserRepo().getUserSecurity(id);
    return response;
  }

  async removeUserSecurity(user: UserDetailsModel, role: string): Promise<boolean | null> {
    user.roles = (user.roles ?? []).filter(r => r !== role);
    const response = await new UserRepo().updateUserDetails(user);
    return response;
  }

  async addUserSecurity(user: UserDetailsModel, role: string): Promise<boolean | null> {
    if (!user.roles) user.roles = [];
    user.roles.push(role as Roles);
    const response = await new UserRepo().updateUserDetails(user);
    return response;
  }

}

export default UserService;
