import PageContent, { PageBreadcrumbProps } from "../../PageContent";
import { TitlelessCard } from "../../../components";
import { useEffect, useState } from "react";
import CatalogSubmenu from "../CatalogSubmenu";
import { getCatalogBreadcrumbs } from "../CatalogBreadcrumbs";
import { useLocation } from "react-router-dom";
import { CatalogActivitiesList } from "./CatalogActivitiesList";

export function CatalogActivitiesPage() {
  
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getCatalogBreadcrumbs(location.pathname);
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      submenu={<CatalogSubmenu />}
      breadcrumbs={breadcrumbs}
      title="Catalog: Activities"
    >
      <TitlelessCard bodyClassName="p-0">
        <CatalogActivitiesList />
      </TitlelessCard>
    </PageContent>
  );
}

export default CatalogActivitiesPage;

