import { createBrowserRouter, useLocation } from "react-router-dom";
import React from "react";
import Root from "../root";
import * as Pages from "../pages";
import { Roles } from "../models";

const routes = {
  home: "/",
  account: "/account",
  signOut: `/route2`,
  manage: `/manage`,
  company: "/company",
  companyInsert: "/company/insert",
  companyDetails: "/company/:Id/Details",
  companyAddress: "/company/:Id/Address",
  companyUsers: "/company/:Id/Users",
  companyTubings: "/company/:Id/Tubings",
  companyDelete: "/company/:Id/Delete",
  settings: "/settings",
  settingsModule: "/settings/modules",
  settingsDiscipline: "/settings/disciplines",
  settingsLevel: "/settings/levels",
  settingsSkill: "/settings/skills",
  log: "/log",
  register: "/account/register/:id/:code",
  registerConfirmation: "/account/registerConfirmation",
  login: "/account/login",
  forgotPassword: "/account/forgotPassword",
  forgotPasswordConfirmation: "/account/forgotPasswordConfirmation",
  resetPassword: "/account/resetPassword",
  resetPasswordConfirmation: "/account/resetPasswordConfirmation",
  requestAccess: "/account/registrationRequest",
  requestAccessConfirmation: "/account/registrationRequestConfirmation",
  user: "/user",
  userDetails: "/user/:id",
  userSecurity: "/user/:id/security",
  userDelete: "/user/:id/delete",
  userInvitations: "/user/invite",
  userInsertInvitation: "/user/invite/insert",
  changePassword: "/manage/changePassword",
  catalog: "/catalog",
  catalogLessons: "/catalog/lessons",
  catalogActivities: "/catalog/activities",
  lessonDetails: "/catalog/lessons/:id/details",
  lessonRegions: "/catalog/lessons/:id/regions",
  lessonActivities: "/catalog/lessons/:id/activities",
  lessonMedia: "/catalog/lessons/:id/media",
  lessonLevels: "/catalog/lessons/:id/levels",
  activityDetails: "/catalog/activities/:id/details",
  activityAssessments: "/catalog/activities/:id/assessments",
  blogs: "/blogs",
  blogAuthors: "/blogs/authors",
  blogArticles: "/blogs/articles",
  blogArticleEdit: "/blogs/articles/:id/edit",
  blogArticleMedia: "/blogs/articles/:id/media",
  // blogArticlePreview: "/blogs/articles/:id/preview",
  files: "/files",
};

const baseRouter = createBrowserRouter([
  {
    path: "*",
    element: <Pages.NotFound />,
  },
  {
    path: "/",
    element: <Root></Root>,
    errorElement: <Pages.ErrorPage />,
    children: [
      // Empty path
      {
        path: "",
        element: <Pages.HomePage />,
      },
      // Catalog
      {
        path: "catalog",
        element: 
          <Pages.SecurityOutlet roles={[Roles.admin]} />,
        children: [
          {
            path: "",
            element: <Pages.CatalogLandingPage />,
          },
          {
            path: "lessons",
            children: [
              {
                path: "",
                element: <Pages.CatalogLessonsPage />,
              },
              {
                path: ":id",
                element: <Pages.LessonPage />,
                children: [
                  {
                    path: "details",
                    element: <Pages.LessonDetails />,
                  },
                  {
                    path: "activities",
                    element: <Pages.LessonActivities />,
                  },
                  {
                    path: "levels",
                    element: <Pages.LessonLevels />,
                  },
                  {
                    path: "regions",
                    element: <Pages.LessonRegions />,
                  },
                  {
                    path: "media",
                    element: <Pages.LessonMedia />,
                  },
                ]
              },
            ]
          },
          {
            path: "activities",
            children: [
              {
                path: "",
                element: <Pages.CatalogActivitiesPage />, 
              },
              {
                path: ":id",
                element: <Pages.ActivityPage />,
                children: [
                  {
                    path: "details",
                    element: <Pages.ActivityDetails />,
                  },
                  {
                    path: "assessments",
                    element: <Pages.ActivityAssessments />,
                  },
                ]
                
              },
            
            ]
          },
        ],
      },
      // Blogs
      {
        path: "blogs",
        element: <Pages.SecurityOutlet roles={[Roles.admin]} />,
        children: [
          {
            path: "",
            element: <Pages.BlogsPage />,
          },
          {
            path: "authors",
            element: <Pages.BlogAuthorsPage />,
          },
          {
            path: "articles",
            children: [
              {
                path: "",
                element: <Pages.BlogArticlesPage />,
              },
              {
                path: ":id",
                element: <Pages.BlogArticlePage />,
                children: [
                  {
                    path: "edit",
                    element: <Pages.BlogArticleEditor />,
                  },
                  {
                    path: "media",
                    element: <Pages.BlogArticleMediaPage />,
                  },
                  // {
                  //   path: "preview",
                  //   element: <Pages.BlogArticlePreview />,
                  // }
                ]
              }
            ]
          }
        ]
      },
      //Manage
      {
        path: "manage",
        element: <Pages.ManageAccountPage />,
        children: [
          {
            path: "",
            element: <Pages.ProfilePage />,
          },
          {
            path: "changePassword",
            element: <Pages.ChangePasswordPage />,
          },
        ],
      },
      {
        path: "files",
        element: (
          <Pages.SecurityOutlet roles={[Roles.admin]} />
        ),
        children: [
          {
            path: "",
            element: <Pages.FilesPage />,
          }
        ]
      },
      // User
      {
        path: "user",
        element: (
          <Pages.SecurityOutlet roles={[Roles.admin]}>
            <Pages.UsersPage />
          </Pages.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <Pages.UsersGrid />,
          },         
          {
            path: ":id",
            element: <Pages.UserDetailsPage />,
            children: [
              {
                path: "",
                element: <Pages.UserProfileCard />,
              },
              {
                path: "security",
                element: <Pages.UserSecurityCard />,
              },
              {
                path: "delete",
                element: <Pages.UserDeleteCard />,
              },              
            ],
          },
        ],
      },
      // Settings
      {
        path: "settings",
        element: <Pages.SecurityOutlet roles={[Roles.admin]} />,
        children: [
          {
            path: "",
            element: <Pages.SettingsPage />,
            children: [
              {
                path: "",
                element: <Pages.SettingsLandingCard />,
              },
              {
                path: "modules",
                element: <Pages.SettingsModulesCard />,
              },
              {
                path: "levels",
                element: <Pages.SettingsLevelsCard />,
              },
              {
                path: "skills",
                element: <Pages.SettingsSkillsCard />,
              },
              {
                path: "disciplines",
                element: <Pages.SettingsDisciplinesCard />,
              },
            ],
          },
        ],
      },
      // Log
      {
        path: "log",
        element: (
          <Pages.SecurityOutlet roles={[Roles.admin]}>
            <Pages.LogPage />
          </Pages.SecurityOutlet>
        ),
        children: [
          {
            path: "",
            element: <h1>Log</h1>,
          },
        ],
      },
    ],
  },
  //Account
  {
    path: "account",
    element: <Pages.AccountPageLayout />,
    errorElement: <Pages.ErrorPage />,
    children: [
      {
        path: "",
        element: <Pages.Login />,
      },
      {
        path: "register/:id/:code",
        element: <Pages.RegisterPage />,
      },
      {
        path: "registerConfirmation",
        element: <Pages.RegisterConfirmationPage />,
      },
      {
        path: "login",
        element: <Pages.Login />,
      },
      {
        path: `login?returnUrl=:returnUrl`,
        element: <Pages.Login />,
      },
      {
        path: "forgotPassword",
        element: <Pages.ForgotPassword />,
      },
      {
        path: "forgotPasswordConfirmation",
        element: <Pages.ForgotPasswordConfirmation />,
      },
      {
        path: "resetPassword",
        element: <Pages.ResetPassword />,
      },
      {
        path: "resetPasswordConfirmation",
        element: <Pages.ResetPasswordConfirmation />,
      },
      {
        path: "registrationRequest",
        element: <Pages.RegistrationRequest />,
      },
      {
        path: "registrationRequestConfirmation",
        element: <Pages.RegistrationRequestConfirmation />,
      },
    ],
  },
]);

const router = Object.assign(baseRouter, {
  getUrl: (url: string, params: Map<string, string> | undefined) => {
    let lowerUrl = url.toLocaleLowerCase();
    let replacedUrl: string = lowerUrl;
    params?.forEach((value, key) => {
      replacedUrl = lowerUrl.replace(`:${key.toLocaleLowerCase()}`, value);
      lowerUrl = replacedUrl;
    });
    return lowerUrl;
  },
  navigateWithParams: (
    url: string,
    params: Map<string, string> | undefined,
    newTab: boolean = false
  ) => {
    const processedUrl = router.getUrl(url, params);
    if (newTab) {
      window.open(processedUrl, "_blank");
      return;
    }
    baseRouter.navigate(processedUrl).catch((error) => {
      return <Pages.ErrorPage />;
    });
  },
});

// This should be kept private to this file
class CustomURLSearchParams extends URLSearchParams {
  get(name: string): string | null {
    const value =
      super.get(name) ??
      super.get(name.toLowerCase()) ??
      super.get(name.toUpperCase());
    return value;
  }
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new CustomURLSearchParams(search), [search]);
}

function getRouteParams(route: string, path: string): Map<string, string> {
  const routeParts = route.split("/");
  const pathParts = path.split("/");
  const params = new Map<string, string>();
  for (let index = 0; index < routeParts.length; index++) {
    const routePart = routeParts[index];
    const pathPart = pathParts[index];
    if (routePart.startsWith(":")) {
      const key = routePart.substring(1);
      params.set(key, pathPart);
    }
  }
  return params;
}

function getQueryValues(query: string): Map<string, string> {
  const params = new Map<string, string>();
  query = query.replace("?", "");
  const queryParts = query.split("&");
  queryParts.forEach((part) => {
    const keyValue = part.split("=");
    params.set(keyValue[0], keyValue[1]);
  });
  return params;
}

function getQueryValue(query: string, key: string): string | null {
  const params = getQueryValues(query);
  const value =
    params.get(key) ??
    params.get(key.toLowerCase()) ??
    params.get(key.toUpperCase());
  return value ?? null;
}

export {
  router,
  routes,
  useQuery,
  getRouteParams,
  getQueryValues,
  getQueryValue,
};


