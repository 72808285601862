export enum Severity {
  comment = "comment",
  caution = "caution",
  urgent = "urgent",
  preRepair = "preRepair",
  postRepair = "postRepair",
  compromisedOD = "compromisedOD",
  // Repaired was removed by client request.
}

export function getSeverityDescriptor(severity: Severity | string): string {

  let tester = severity.toUpperCase();

  switch (tester) {

    case Severity.caution.toUpperCase():
      return "Caution";
    case Severity.urgent.toUpperCase():
      return "Urgent";
    case Severity.preRepair.toUpperCase():
      return "Pre-Repair";
    case Severity.postRepair.toUpperCase():
      return "Post-Repair";
    case Severity.compromisedOD.toUpperCase():
      return "Compromised OD";
    case "REPAIRED":
      return "Repaired"; // Kept for backwards compatibility
    case Severity.comment.toUpperCase():
    default:
      return "Comment";
  }
}

export default Severity;