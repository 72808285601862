import React, { useState } from 'react';
import { Submenu, SubmenuNavigationItem } from '../../../../../components';

interface ManageAssessmentSubmenuProps {
  onChangeTab: (tab: string) => void;
}

export const ManageAssessmentSubmenu: React.FC<ManageAssessmentSubmenuProps> = ({ onChangeTab }) => {
  const [activeTab, setActiveTab] = useState<string>('Details');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    onChangeTab(tab);
  };

  const items: SubmenuNavigationItem[] = [
    {
      label: 'Details',
      url: '',
      clickHandler: () => handleTabChange('Details'),
      ifActive: activeTab === 'Details' ? 'active' : '',
    },
    {
      label: 'Skills',
      url: '',
      clickHandler: () => handleTabChange('Skills'),
      ifActive: activeTab === 'Skills' ? 'active' : '',
    },
  ]

  return (
    <Submenu items={items} />
  );
};

export default ManageAssessmentSubmenu;
