import { BlogArticleModel, BlogAuthorModel, BlogModel, ODataResponse } from "../data/entities";
import { BlogRepo } from "../data/repo";

export class BlogService {

  // Blogs
  async getBlogsAsync(oDataQuery: string = ""): Promise<ODataResponse<BlogModel>>{
    return new BlogRepo().getBlogsAsync(oDataQuery);
  }

  async getBlogAsync(id: number): Promise<BlogModel | null> {
    return new BlogRepo().getBlogAsync(id);
  }

  async addBlogAsync(blog: BlogModel) : Promise<boolean> {
    return new BlogRepo().addBlogAsync(blog);
  }

  async editBlogAsync(blog: BlogModel) : Promise<boolean> {
    return new BlogRepo().editBlogAsync(blog);
  }

  async deleteBlogAsync(id: number) : Promise<boolean> {
    return new BlogRepo().deleteBlogAsync(id);
  }

  // Blog Authors
  async getBlogAuthorsAsync(oDataQuery: string = ""): Promise<ODataResponse<BlogAuthorModel>>{
    return new BlogRepo().getBlogAuthorsAsync(oDataQuery);
  }

  async addBlogAuthorAsync(author: BlogAuthorModel): Promise<boolean>{
    return new BlogRepo().addBlogAuthorAsync(author);
  }

  async editBlogAuthorAsync(author: BlogAuthorModel): Promise<boolean>{
    return new BlogRepo().editBlogAuthorAsync(author);
  }

  async deleteBlogAuthorByIdAsync(id: number): Promise<boolean>{
    return new BlogRepo().deleteBlogAuthorByIdAsync(id);
  }

  // Blog Articles
  async getBlogArticlesAsync(oDataQuery: string = ""): Promise<ODataResponse<BlogArticleModel>>{
    return new BlogRepo().getBlogArticlesAsync(oDataQuery);
  }

  async getBlogArticleByIdAsync(id: number): Promise<BlogArticleModel | null> {
    return new BlogRepo().getBlogArticleByIdAsync(id);
  }

  async addBlogArticleAsync(article: BlogArticleModel): Promise<boolean>{
    return new BlogRepo().addBlogArticleAsync(article);
  }

  async editBlogArticleAsync(article: BlogArticleModel): Promise<boolean>{
    return new BlogRepo().editBlogArticleAsync(article);
  }

  async deleteBlogArticleByIdAsync(id: number): Promise<boolean>{
    return new BlogRepo().deleteBlogArticleByIdAsync(id);
  }

}
