import { ModuleModel, ODataResponse } from "../entities";
import BaseRepository from "./BaseRepository";

export class ModuleRepo extends BaseRepository {
  
  async getModulesAsync(oDataQuery: string = ""): Promise<ODataResponse<ModuleModel>> {
    const url = `odata/Modules/?${oDataQuery}`;
    const response = await this.client.get(url);
    const handledResponse = this.handleResponse<ODataResponse<ModuleModel>>(response);
    return handledResponse!;
  };

  async addModuleAsync(module: ModuleModel): Promise<boolean | null> {
    const url = `Module`;
    const response = await this.client.post(url, module);
    if (!response.success) {
      this.handleException(response);
      return false;
    }

    return response.success;
  };

  async editModuleAsync(module: ModuleModel): Promise<boolean | null> {
    const url = `Module/${module.id}`;
    const response = await this.client.put(url, module);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }

  async deleteModuleByIdAsync(id: number): Promise<boolean | null> {
    const url = `Module/${id}`;
    const response = await this.client.delete(url);
    if (!response.success) {
      this.handleException(response);
      return false;
    }
    return response.success;
  }
}
