import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store/store";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./app/routes";
import "hammerjs";
import "./css/fontawesome/css/all.css";
import "@progress/kendo-theme-default/dist/all.css";
import "./css/site.scss";
import { ErrorBoundary } from "./pages";

const container = document.getElementById("root")!;
const root = createRoot(container);

window.onerror = function (message, source, lineno, colno, error) {
  console.error(
    "Ooops... An error just occurred:",
    message,
    source,
    lineno,
    colno,
    error
  );

  /* Future logging client events to API
  const errorMessage = (typeof message === 'object' || Array.isArray(message)) ? JSON.stringify(message) : message;
  
  (new LogService()).insertEventLog({
    id: 0,
    createdDate: new Date(),
    category: 'Client',
    eventType: 'Client',
    logLevel: 'Error',
    username: UserService.getUserEmail() ?? '',
    message: source === undefined ? errorMessage : `Error: ${errorMessage}

Source: ${source}
Line: ${lineno}
Col: ${colno}
Details: ${JSON.stringify(error)}`
  });*/

  return true;
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <RouterProvider router={router}></RouterProvider>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

