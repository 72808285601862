import React, { PropsWithChildren } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import { propsFilter } from "../../../app/tools";

interface ButtonWithSpinnerProps extends PropsWithChildren, ButtonProps {
  disabled?: boolean;
  showSpinner?: boolean;
  className?: string;
  variant?: string;
  size?: "lg" | "sm";
  type?: "submit" | "reset" | "button";
}

export const ButtonWithSpinner = React.forwardRef(
  (props: Readonly<ButtonWithSpinnerProps>, ref): JSX.Element => {
    const content = props.showSpinner ? (
      <Spinner
        animation="border"
        variant="light"
        as="span"
        aria-hidden="true"
      />
    ) : (
      props.children
    );

    const filteredProps = propsFilter(props, ["showSpinner", "children"]);

    return (
      <Button
        {...filteredProps}
      >
        {content}
      </Button>
    );
  }
);

ButtonWithSpinner.defaultProps = {
  showSpinner: false,
  disabled: false,
  variant: "primary",
  size: "lg",
  type: "submit",
};

export default ButtonWithSpinner;
