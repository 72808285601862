import React from "react";
import { LinkLooksLike } from "../link";

interface ImageProps {
  data: string;
  onChange: () => void;
  deleteHandler: () => void;
}

export function Image(props: ImageProps) {
  const { data } = props;
  const imageUrl = `data:image/jpeg;base64,${data}`;

  const cardStyle = {
    backgroundColor: '#F7F7F7',
    borderRadius: '4px',
    maxWidth: '100%',
    maxHeight: '300px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '300px',
    height: 'auto',
    width: 'auto',
  };

  return (
    <div className="card">
      <div style={cardStyle}>
        <img src={imageUrl} alt="Dynamic" style={imageStyle} />
      </div>
      <div className="image-card-actions">
        <ul className="nav small border-bottom">
          <li className="nav-item">
            <LinkLooksLike className="nav-link pl-2 pr-0" onClick={props.deleteHandler}>
              <span className="fa fa-times-circle"></span> Delete
            </LinkLooksLike>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Image;
