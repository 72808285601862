import { ODataResponse, RegionModel } from "../data/entities";
import { RegionRepo } from "../data/repo";

export class RegionService {

  async getRegionsAsync(oDataQuery: string = ""): Promise<ODataResponse<RegionModel>> {
    return await new RegionRepo().getRegionsAsync(oDataQuery);
  }

}
