import { useEffect, useState } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { PageBreadcrumbProps, PageContent } from "../../PageContent";
import { LessonModel } from "../../../data/entities";
import { LessonService, UrlService } from "../../../services";
import LessonSidebar from "./LessonSidebar";
import LessonSubmenu from "./LessonSubmenu";
import getLessonBreadcrumbs from "./LessonBreadcrumbs";

export function LessonPage() {
  // Get the id from the url
  const urlParams = useParams();
  const id = UrlService.getParamValue("id", urlParams);

  

  // Instantiate page data
  const [pageData, setPageData] = useState<LessonModel | undefined | null>();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbProps>();
  const location = useLocation();
  const title = pageData?.name ?? "Lesson";

  /**
   * Fetches the page data when the component mounts.
   */
  useEffect(() => {
    // Instantiate services
    const lessonService = new LessonService();
    const fetchData = async () => {
      try {
        const loadedData = await lessonService.getLessonByIdAsync(Number(id)!);
        setPageData(loadedData);
      } catch (e) {
        console.error("Error loading lesson", e);
      }
    };
    fetchData();
  }, [id]);

  /**
   * Initialize breadcrumbs for the page depending on the route
   */
  useEffect(() => {
      const newBreadcrumbs:PageBreadcrumbProps = getLessonBreadcrumbs(location.pathname, Number(id));
      setBreadcrumbs(newBreadcrumbs);
    },[location.pathname]
  );

  return (
    <PageContent
      title={title}
      breadcrumbs={breadcrumbs}
      submenu={<LessonSubmenu id={id!}/>}
    >
      <div className="row">
        <div className="col-12 col-lg-3">
          <LessonSidebar />
        </div>
        <div className="col-12 col-lg-9 position-relative">
          <Outlet />
        </div>
      </div>
    </PageContent>
  );
}

export default LessonPage;

